import CommandBasedRepository from "../commons/repositories/CommandBasedRepository";

export default class DocumentTypesRepository extends CommandBasedRepository {
  getDocumentTypes() {
    return super.send("ReadDocumentTypes", {});
  }

  addDocumentType(
    type,
    documentTypeName,
    expire,
    hasInForceDate,
    hasEndDate,
    hasExpirationCurrentMonth,
    expirationMonths,
    critical,
    comments,
    contractorComments,
    automaticType,
    tolerate
  ) {
    return super.send("AddDocumentType", {
      params: { type },
      body: {
        documentTypeName,
        expire,
        hasInForceDate,
        hasEndDate,
        hasExpirationCurrentMonth,
        expirationMonths,
        critical,
        comments,
        contractorComments,
        automaticType,
        tolerate,
      },
    });
  }

  updateDocumentType(
    id,
    documentTypeName,
    expire,
    hasInForceDate,
    hasEndDate,
    hasExpirationCurrentMonth,
    expirationMonths,
    critical,
    comments,
    contractorComments,
    automaticType,
    tolerate
  ) {
    return super.send("UpdateDocumentType", {
      params: { id },
      body: {
        documentTypeName,
        expire,
        hasInForceDate,
        hasEndDate,
        hasExpirationCurrentMonth,
        expirationMonths,
        critical,
        comments,
        contractorComments,
        automaticType,
        tolerate,
      },
    });
  }

  deleteDocumentType(id) {
    return super.send("DeleteDocumentType", {
      params: { id },
    });
  }

  deleteDocumentTypeModel(id) {
    return super.send("DeleteDocumentTypeModel", {
      params: { id },
    });
  }

  getRoleDocumentTypesAvailable(roleId, type) {
    return super.send("ReadAvailableRoleDocumentTypes", {
      params: { roleId, type },
    });
  }

  getFunctionDocumentTypesAvailable(functionId, type) {
    return super.send("ReadAvailableFunctionDocumentTypes", {
      params: { functionId, type },
    });
  }

  assignDocumentTypeToRole(roleId, documentTypeId) {
    return super.send("AssignRoleDocumentType", {
      params: { roleId, documentTypeId },
    });
  }

  assignDocumentTypeToFunction(functionId, documentTypeId) {
    return super.send("AssignFunctionDocumentType", {
      params: { functionId, documentTypeId },
    });
  }

  deleteDocumentTypeRole(roleId, documentTypeId) {
    return super.send("DeleteRoleDocumentType", {
      params: { roleId, documentTypeId },
    });
  }

  deleteDocumentTypeFunction(functionId, documentTypeId) {
    return super.send("DeleteFunctionDocumentType", {
      params: { functionId, documentTypeId },
    });
  }
}
