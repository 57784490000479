import CommandBasedRepository from "../commons/repositories/CommandBasedRepository";

export default class EstimatesRepository extends CommandBasedRepository {
  sendEstimate(
    companyName,
    email,
    contractorsNumber,
    workCentersNumber,
    workersNumber,
    machinesNumber,
    needCustomization,
    needConsultancy,
    fee,
    costPerResource
  ) {
    return super.send("SendEstimate", {
      params: {
        companyName,
        email,
        contractorsNumber,
        workCentersNumber,
        workersNumber,
        machinesNumber,
        needCustomization,
        needConsultancy,
        fee,
        costPerResource,
      },
    });
  }
}
