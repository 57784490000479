import React from "react";
import BiaComponent from "./BiaComponent";
import UsersRepository from "../repositories/UsersRepository";
import Toast from "./Toast";
import FormLabel from "./FormLabel";

export default class CompaniesAssignableDropDown extends BiaComponent {
  constructor(props) {
    super(props);

    this.state = {
      companyCif: null,
    };
  }

  addCompany() {
    if (!this.state.companyCif) {
      Toast.error(this.i18n.companies["Select"]);
      return;
    }

    new UsersRepository()
      .addCompany(this.props.user.id, this.state.companyCif)
      .then(() => {
        Toast.success(this.i18n.companies["Saved"]);
      });
  }

  deleteCompany() {
    new UsersRepository()
      .deleteCompany(this.props.user.id, this.state.companyCif)
      .then(() => Toast.success(this.i18n.companies["Deleted"]))
      .catch(Toast.error);
  }

  render() {
    return (
      <form>
        <span>
          <div className="field" style={{ display: "inline-block" }}>
            <FormLabel label="CIF:" size="3.2rem" />
            <input
              type="text"
              name="companyCif"
              value={this.state.companyCif}
              onChange={event =>
                this.setState({ companyCif: event.target.value })
              }
            />
          </div>
          <button
            className="button-success"
            onClick={event => {
              event.preventDefault();
              this.addCompany();
            }}
          >
            <i className="fa fa-plus"></i>{" "}
            <span>{this.i18n.buttons["Assign"]}</span>
          </button>
          <button
            className="button-success"
            onClick={event => {
              event.preventDefault();
              this.deleteCompany();
            }}
          >
            <i className="fa fa-minus"></i> <span>Desasignar</span>
          </button>
        </span>
      </form>
    );
  }
}
