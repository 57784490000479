import React from "react";
import FormLabel from "../FormLabel";

const ActionButton = ({ className, onClick, icon }) => {
  return (
    <button className={className} onClick={onClick}>
      <i className={`fa ${icon}`}></i>
    </button>
  );
};

const InputElement = ({
  id,
  name,
  value,
  label,
  size,
  required,
  canRemove,
  onAdd,
  onRemove,
  onChange,
}) => {
  let className = canRemove ? "button-error" : "button-success";
  let onClick = canRemove
    ? e => {
        e.preventDefault();
        onRemove(id);
      }
    : e => {
        e.preventDefault();
        onAdd();
      };
  let icon = canRemove ? "fa-minus" : "fa-plus";
  return (
    <div className="field">
      <FormLabel
        label={label}
        size={size}
        required={required}
        for="inForceDate"
      />
      <input
        type="text"
        name={name}
        value={value}
        onChange={e => {
          e.preventDefault();
          onChange(id, e.target.value);
        }}
      />
      <ActionButton className={className} onClick={onClick} icon={icon} />
    </div>
  );
};

InputElement.defaultProps = {
  canRemove: false,
  value: "",
  onRemove: () => {},
  required: false,
  id: 0,
};

const MultiInput = ({
  name,
  label,
  size,
  elements,
  addElement,
  updateElement,
  removeElement,
}) => {
  if (!elements || elements.length === 0)
    return (
      <InputElement
        onAdd={addElement}
        onChange={updateElement}
        size={size}
        label={label}
        name={name}
      />
    );

  let [firstElement, ...restElements] = elements;

  return (
    <div style={{ maxHeight: "15rem", overflow: "auto" }}>
      <InputElement
        onAdd={addElement}
        onChange={updateElement}
        value={firstElement}
        size={size}
        label={label}
        name={name}
      />
      {restElements.map((element, i) => (
        <InputElement
          id={i + 1}
          key={i + 1}
          value={element}
          onChange={updateElement}
          onRemove={removeElement}
          size={size}
          label={label}
          name={name}
          canRemove={true}
        />
      ))}
    </div>
  );
};

MultiInput.defaultProps = {
  size: "10rem",
};

export default MultiInput;
