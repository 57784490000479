import CommandBasedRepository from "./CommandBasedRepository";

export default class CompaniesRepository extends CommandBasedRepository {
  getCompanies(userId) {
    if (userId !== undefined && userId !== null) {
      return super.send("ReadCompaniesAvailableByUser", {
        params: { userId },
      });
    } else {
      return super.send("ReadAllContractorCompanies", {});
    }
  }

  getCompanyExistence(cif) {
    return super.send("ReadCompanyExistence", {
      params: { cif },
    });
  }

  getContractorsFriends() {
    return super.send("ReadContractorsFriends", {});
  }

  getPropertiesFriends() {
    return super.send("ReadPropertiesFriends", {});
  }

  getCompaniesNames() {
    return super.send("ReadAllCompaniesNames", {});
  }

  getCompaniesByCompany() {
    return super.send("ReadCompaniesByCompany", {});
  }

  delete(companyId) {
    return super.send("DeleteCompany", {
      params: { companyId },
    });
  }

  deleteFriendship(cif, client) {
    return super.send("DeleteFriendship", {
      params: { cif, client },
    });
  }

  getCompanyTypes() {
    return super.send("ReadCompanyTypes", {});
  }
}
