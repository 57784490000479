import React from "react";

let typeColor = {
  REVIEW: "#ffdf04",
  REVIEWOK: "#31b297",
  INCORRECT: "#d32e22",
  OK: "#31b297",
  TOLERATE: "#ff8400",
  EMPTY: "#5e5e5e",
  TYPE: "#5792ee",
};

export default class Tag extends React.Component {
  handleSelection(event) {
    if (this.props.handler) {
      event.preventDefault();
      this.props.handler(this.props.path);
    }
  }

  render() {
    if (
      this.props.showZero !== "true" &&
      (!this.props.value || parseInt(this.props.value, 10) === 0)
    ) {
      return <span />;
    }

    let styles = {
      border: "1px solid " + typeColor[this.props.type],
      color: typeColor[this.props.type],
      minWidth: this.props.width ? this.props.width : "6rem",
      textAlign: "center",
      marginBottom: "0.1rem",
    };

    return (
      <div id={`tag-${this.props.id}`} style={styles} className="tag">
        <span>{this.props.value}</span>
        {" " + this.props.label}
      </div>
    );
  }
}
