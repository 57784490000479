import React from "react";
import BiaComponent from "./BiaComponent";
import Window from "./Window";

export default class DocumentTypeModalForm extends BiaComponent {
  constructor(props) {
    super(props);

    this.state = {
      dtId: null,
      name: null,
      expire: false,
      expirationMonths: null,
      inForceDate: false,
      endDate: false,
      currentMonth: false,
      critical: false,
      comments: null,
      contractorComments: null,
      expireMode: null,
      automaticType: null,
      isAutomaticType: false,
      tolerate: null,
    };
  }

  componentDidMount() {
    if (this.props.data != null) {
      let expire =
        this.props.data.hasExpirationCurrentMonth ||
        this.props.data.hasEndDate ||
        this.props.data.expirationMonths != null;
      this.setState({
        dtId: this.props.data.documentTypeId,
        name: this.props.data.documentTypeName,
        expire: expire,
        expirationMonths: this.props.data.expirationMonths,
        inForceDate: this.props.data.hasInForceDate,
        endDate: this.props.data.hasEndDate,
        currentMonth: this.props.data.hasExpirationCurrentMonth,
        critical: this.props.data.documentTypeCritical,
        comments: this.props.data.documentTypeComments,
        contractorComments: this.props.data.documentTypeContractorComments,
        expireMode: expire
          ? this.props.data.hasExpirationCurrentMonth
            ? "encurso"
            : this.props.data.expirationMonths != null
            ? "natural"
            : "fecha"
          : "",
        automaticType: this.props.data.automaticType,
        isAutomaticType: this.props.data.automaticType !== "NONE",
        tolerate: this.props.data.tolerate,
      });
    } else {
      this.setState({
        dtId: null,
        name: null,
        expire: false,
        expirationMonths: null,
        inForceDate: false,
        endDate: false,
        currentMonth: false,
        critical: false,
        comments: null,
        contractorComments: null,
        expireMode: null,
        automaticType: null,
        isAutomaticType: false,
        tolerate: null,
      });
    }
  }

  retrieveData() {
    return this.state;
  }

  open() {
    this.componentDidMount();
    this.refs.window.open();
  }

  close() {
    this.refs.window.close();
  }

  setExpirationCurrentMonthState() {
    this.setState({
      expireMode: "encurso",
      currentMonth: true,
      expirationMonths: null,
      endDate: false,
    });
  }

  setExpirationNaturalMonthState() {
    this.setState({
      expireMode: "natural",
      currentMonth: false,
      expirationMonths: "1",
      endDate: false,
    });
  }

  setExpirationEndDateState() {
    this.setState({
      expireMode: "fecha",
      currentMonth: false,
      expirationMonths: null,
      endDate: true,
    });
  }

  changeCurrentMonth(event) {
    if (event.target.value === "natural") {
      this.setExpirationNaturalMonthState();
    } else if (
      event.target.value === "encurso" &&
      this.state.expireMode !== "encurso"
    ) {
      this.setExpirationCurrentMonthState();
    }
  }

  changeEndDate(event) {
    if (event.target.checked) {
      this.setExpirationEndDateState();
    } else {
      this.setExpirationCurrentMonthState();
    }
  }

  changeExpire(event) {
    if (event.target.checked) {
      this.setState({ expire: true });
      this.setExpirationCurrentMonthState();
    } else {
      this.setState({
        expire: false,
        expireMode: null,
        expirationMonths: null,
        inForceDate: false,
        currentMonth: false,
      });
    }
  }

  nameChange(event) {
    this.setState({ name: event.target.value });
  }

  tolerateChange(event) {
    this.setState({ tolerate: event.target.value });
  }

  criticalChange(event) {
    this.setState({ critical: event.target.checked });
  }

  inForceChange(event) {
    this.setState({ inForceDate: event.target.checked });
  }

  expirationMonthsChange(event) {
    this.setState({
      expirationMonths:
        event.target.value.trim() === "" ? null : event.target.value.trim(),
    });
  }

  commentsChange(event) {
    this.setState({ comments: event.target.value });
  }

  contractorCommentsChange(event) {
    this.setState({ contractorComments: event.target.value });
  }

  automaticTypeChange(event) {
    this.setState({ automaticType: event.target.value });
  }

  changeIsAutomaticType(event) {
    if (event.target.checked) {
      if (this.props.type === "COMPANY") {
        this.setState({ isAutomaticType: true, automaticType: "SS" });
      } else {
        this.setState({ isAutomaticType: true, automaticType: "ITA" });
      }
    } else {
      this.setState({ isAutomaticType: false, automaticType: "NONE" });
    }
  }

  render() {
    let autoSelector = (
      <select
        onChange={this.automaticTypeChange.bind(this)}
        value={this.state.automaticType}
      >
        <option value="NONE">{this.i18n.automaticType["none"]}</option>
      </select>
    );
    let autoFieldSet = "";
    if (this.props.type !== "MACHINE") {
      if (this.props.type === "WORKER") {
        autoSelector = (
          <select
            onChange={this.automaticTypeChange.bind(this)}
            value={this.state.automaticType}
          >
            <option value="ITA">{this.i18n.automaticType["ita"]}</option>
            <option value="RNT">{this.i18n.automaticType["rnt"]}</option>
          </select>
        );
      } else if (this.props.type === "COMPANY") {
        autoSelector = (
          <select
            onChange={this.automaticTypeChange.bind(this)}
            value={this.state.automaticType}
          >
            <option value="SS">{this.i18n.automaticType["ss"]}</option>
            <option value="AEAT">{this.i18n.automaticType["aeat"]}</option>
          </select>
        );
      }

      autoFieldSet = (
        <span>
          <div className="field">
            <label
              style={{
                fontWeight: "bold",
                textAlign: "left",
                width: "auto",
                whiteSpace: "nowrap",
              }}
            >
              {this.i18n.label["AutomaticType"]}
            </label>
            <input
              type="checkbox"
              onChange={this.changeIsAutomaticType.bind(this)}
              checked={this.state.isAutomaticType}
            />
          </div>
          <div
            className="field"
            style={{ display: this.state.isAutomaticType ? "" : "none" }}
          >
            <label style={{ width: "8rem", textAlign: "left" }}>
              {this.i18n.label["Type"]}
            </label>
            {autoSelector}
          </div>
        </span>
      );
    }

    let form = (
      <form>
        <div
          className="field required"
          style={{ display: this.props.showName === false ? "none" : "" }}
        >
          <label style={{ textAlign: "left" }}>{this.i18n.label["Name"]}</label>
          <input
            type="text"
            onChange={this.nameChange.bind(this)}
            value={this.state.name}
          />
        </div>
        <div className="field">
          <label
            style={{
              textAlign: "left",
              fontWeight: "bold",
              width: "auto",
              whiteSpace: "nowrap",
            }}
          >
            {this.i18n.label["Critical"]}
          </label>
          <input
            type="checkbox"
            onChange={this.criticalChange.bind(this)}
            checked={this.state.critical}
          />
        </div>

        <fieldset
          className="expiration-fields"
          style={{
            marginLeft: "0.55rem",
            marginRight: "0.55rem",
            border: "1px solid #ccc",
            padding: "0.1rem",
          }}
        >
          <legend>
            <input
              type="checkbox"
              style={{ marginLeft: "10px" }}
              onChange={this.changeExpire.bind(this)}
              checked={this.state.expire}
            />
            <label
              style={{
                width: "10rem",
                marginLeft: "5px",
                marginRight: "10px",
                fontSize: "1.1rem",
              }}
            >
              {this.i18n.label["CanExpire"]}
            </label>
          </legend>
          <div className="field">
            <label
              className="expiration-fields-label"
              style={{ float: "none", fontWeight: "bold" }}
            >
              {this.i18n.label["InForceDate"]}:
            </label>
            <input
              name="inForceDate"
              disabled={!this.state.expire}
              type="checkbox"
              onChange={this.inForceChange.bind(this)}
              checked={this.state.inForceDate}
            />
            <label
              style={{ width: "10rem", float: "none", fontWeight: "bold" }}
            >
              {this.i18n.label["EndDate"]}:
            </label>
            <input
              name="endDate"
              disabled={!this.state.expire}
              type="checkbox"
              onChange={this.changeEndDate.bind(this)}
              checked={this.state.expireMode === "fecha"}
            />
          </div>
          <div
            className="field expiration-additional-info"
            style={{
              display:
                this.state.expireMode === "encurso" ||
                this.state.expireMode === "natural"
                  ? ""
                  : "none",
            }}
          >
            <label
              className="expiration-fields-label"
              style={{ fontWeight: "bold" }}
            >
              {this.i18n.label["MonthlyExpiration"]}
            </label>
            <div className="radio-fieldset">
              <input
                type="radio"
                style={{ marginRight: "3px" }}
                disabled={!this.state.expire}
                onChange={this.changeCurrentMonth.bind(this)}
                id="encurso"
                value="encurso"
                checked={this.state.expireMode === "encurso"}
              />
              <label
                style={{ width: "9rem", padding: "0" }}
                className="radio"
                htmlFor="encurso"
              >
                {this.i18n.label["Day1EachMonth"]}
              </label>
              <input
                type="radio"
                style={{ marginRight: "3px" }}
                disabled={!this.state.expire}
                onChange={this.changeCurrentMonth.bind(this)}
                id="natural"
                value="natural"
                checked={this.state.expireMode === "natural"}
              />
              <label
                style={{ width: "11rem", padding: "0" }}
                className="radio"
                htmlFor="natural"
              >
                {this.i18n.label["FromDateIndicated"]}
              </label>
            </div>
          </div>
          <div
            className="field"
            style={{
              display: this.state.expireMode === "natural" ? "" : "none",
            }}
          >
            <label
              className="expiration-fields-label"
              style={{
                float: "none",
                fontWeight: "bold",
                marginRight: "5px",
              }}
            >
              {this.i18n.label["Expiration"]}
            </label>
            <input
              name="expirationMonths"
              disabled={
                !this.state.expire || this.state.expireMode !== "natural"
              }
              type="number"
              min="1"
              onChange={this.expirationMonthsChange.bind(this)}
              value={this.state.expirationMonths}
            />
            <label
              style={{ float: "none", textAlign: "left", marginLeft: "0.2rem" }}
            >
              {this.i18n.label["Months"]}
            </label>
          </div>
          <div className="field">
            <label
              className="expiration-fields-label"
              style={{ width: "6rem", float: "none" }}
            >
              {this.i18n.label["Tolerate"]}
            </label>
            <input
              type="number"
              min="1"
              onChange={this.tolerateChange.bind(this)}
              value={this.state.tolerate}
            />
            <label
              style={{ float: "none", textAlign: "left", marginLeft: "0.2rem" }}
            >
              {this.i18n.label["Days"]}
            </label>
          </div>
        </fieldset>

        {autoFieldSet}

        <fieldset
          style={{
            marginLeft: "0.55rem",
            marginRight: "0.55rem",
            border: "1px solid #ccc",
            padding: "0.7rem",
          }}
        >
          <legend
            style={{
              width: "17rem",
              marginLeft: "5px",
              marginRight: "10px",
              fontSize: "1.1rem",
            }}
          >
            {this.i18n.label["ReviewCriteria"]}
          </legend>
          <textarea
            style={{ width: "100%" }}
            rows="8"
            onChange={this.commentsChange.bind(this)}
            value={this.state.comments}
          />
        </fieldset>

        <fieldset
          style={{
            marginLeft: "0.55rem",
            marginRight: "0.55rem",
            border: "1px solid #ccc",
            padding: "0.7rem",
          }}
        >
          <legend
            style={{
              width: "17rem",
              marginLeft: "5px",
              marginRight: "10px",
              fontSize: "1.1rem",
            }}
          >
            {this.i18n.label["ContractorCriteria"]}
          </legend>
          <textarea
            style={{ width: "100%" }}
            rows="8"
            onChange={this.contractorCommentsChange.bind(this)}
            value={this.state.contractorComments}
          />
        </fieldset>
      </form>
    );

    return (
      <Window
        ref="window"
        title={this.props.title}
        acceptButtonText={this.props.acceptButtonText}
        onAccept={this.props.onAccept}
      >
        {form}
      </Window>
    );
  }
}
