import React from "react";
import List from "./List";
import BiaComponent from "./BiaComponent";

export default class AttributeList extends BiaComponent {
  deleteItem(id, e) {
    e.preventDefault();
    if (this.props.onDelete != null) {
      this.props.onDelete(id);
    }
  }

  
  render() {
    const BASE_URL = process.env.NODE_ENV === "production"?"https://app.bia360cae.com/api/document":"http://localhost:9082/api/document";
    let contentList = this.props.data.map(d => {
      let name = this.requirementNameTranslation(d.id, d.name);
      if (d.uuid) {
        let link = `${BASE_URL}/${d.uuid}`;
        name = <a href={link}>{name}</a>;
      }

      let icon = <i className="fa fa-caret-right"></i>;
      if (this.props.onDelete != null) {
        icon = (
          <a href="#/" onClick={this.deleteItem.bind(this, d.id)}>
            <i className="fa fa-times" />
          </a>
        );
      }
      return (
        <div key={d.id} className="document">
          <div>
            {icon} {name}
          </div>
        </div>
      );
    });

    let title = <span>{this.props.title}</span>;

    return <List title={title}>{contentList}</List>;
  }
}
