import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function checkUserCookie() {
  // Verifica si la cookie `user` existe
  return document.cookie.split('; ').some(cookie => cookie.startsWith('user='));
}

const ProtectedRoute = ({ element }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkUserCookie()) {
      console.log('Access denied. Redirecting to login page...');
      navigate('/login.html', { replace: true });
    }
  }, [navigate]);

  return checkUserCookie() ? element : null;
};

export default ProtectedRoute;