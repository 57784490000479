import React from "react";
import PropTypes from "prop-types";
import BiaComponent from "./BiaComponent";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    minWidth: "35rem",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default class Window extends BiaComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      extendedContent: <span />,
    };
  }

  afterOpenModal(e) {
    if (e && e.preventDefault) e.preventDefault();
  }

  open(extendedContent) {
    this.setState({ modalIsOpen: true, extendedContent });
  }

  close(e) {
    if (e) e.preventDefault();
    this.setState({ modalIsOpen: false });
    if (this.props.onCancel) this.props.onCancel();
  }

  accept() {
    if (this.props.onAccept) {
      this.props.onAccept();
    }
  }

  styles() {
    if (!this.props.zIndex) return customStyles;

    return {
      overlay: {
        zIndex: this.props.zIndex,
      },
      ...customStyles,
    };
  }

  render() {
    let actionAcceptText =
      this.props.acceptButtonText || this.i18n.buttons["OK"];
    let actionCancelText =
      this.props.cancelButtonText || this.i18n.buttons["Cancel"];

    let onAcceptButton = "";
    if (this.props.onAccept != null) {
      onAcceptButton = (
        <button className="button-success" onClick={this.accept.bind(this)}>
          <i className="fa fa-check"></i> {actionAcceptText}
        </button>
      );
    }

    let close = (
      <a href="#/" onClick={this.close.bind(this)}>
        {actionCancelText}
      </a>
    );
    if (this.props.block === "true") {
      close = "";
    }

    let modalActions = "";

    if (
      this.props.onAccept ||
      this.props.onCancel ||
      this.props.acceptButtonText ||
      this.props.cancelButtonText
    ) {
      modalActions = (
        <div className="modal-actions">
          {onAcceptButton}

          {close}
        </div>
      );
    }

    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        ariaHideApp={false}
        contentLabel=""
        onAfterOpen={this.afterOpenModal.bind(this)}
        onRequestClose={
          this.props.block === "true" ? null : this.close.bind(this)
        }
        style={this.styles()}
      >
        <div className="modal-title">
          <h1>{this.props.title}</h1>
        </div>

        <div>
          <span>{this.state.extendedContent}</span>
        </div>

        <div>{this.props.children}</div>

        {modalActions}
      </Modal>
    );
  }
}

Window.propTypes = {
  acceptButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  block: PropTypes.any,
  title: PropTypes.string,
  zIndex: PropTypes.number,
};
