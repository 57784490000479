import React from "react";
import BiaComponent from "./BiaComponent";
import FormLabel from "./FormLabel";

import Calendar from "rc-calendar";
import DatePicker from "rc-calendar/lib/Picker";
import "rc-calendar/assets/index.css";
import "rc-time-picker/assets/index.css";

import moment from "moment";
import "moment/locale/es";
import biaI18n from "../i18n/biaI18n";

export default class DateDropper extends BiaComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
    };
  }
  getSelectedValue() {
    return this.state.value ? this.state.value.format("DD/MM/YYYY") : "";
  }

  onChange(value) {
    this.setState({ value });
  }

  render() {
    const locale = biaI18n["rc-calendar"];
    const calendar = (
      <Calendar
        locale={locale}
        style={{ zIndex: 1000 }}
        dateInputPlaceholder={biaI18n.dates["select"]}
        formatter={"DD/MM/YYYY"}
        defaultValue={moment()}
        showDateInput={true}
        showOk={true}
        showClear={false}
      />
    );

    return (
      <div>
        <FormLabel
          label={this.props.label + ":"}
          size={this.props.size !== undefined ? this.props.size : "9rem"}
          required={this.props.required}
        />

        <DatePicker
          animation="slide-up"
          calendar={calendar}
          value={this.state.value}
          onChange={this.onChange.bind(this)}
        >
          {({ value }) => {
            return (
              <span tabIndex="0">
                <input
                  placeholder={biaI18n.dates["select"]}
                  style={{ width: 250 }}
                  value={
                    (value && value.format && value.format("DD/MM/YYYY")) || ""
                  }
                />
              </span>
            );
          }}
        </DatePicker>
      </div>
    );
  }
}
