import fetch from "isomorphic-fetch";

export default class CommandBasedRepository {
  send(command, { params, body: dataRecord = {}, extraPath = "" }) {
    let me = this;
    me.muestraLoadingIndicator();

    let BASE_URL = process.env.NODE_ENV === "production" ? "https://api-admin.bia360cae.com/api" : "http://localhost:9081/api";

    return new Promise((resolve, reject) => {
      fetch(`${BASE_URL}/data/${command}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          commandClass: command,
          params,
          dataRecord,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          if (response.status >= 400) {
            if (response.status === 401 || response.status === 502) {
              window.location.replace("/login.html");
              return;
            }
          }
          return response.text();
        })
        .then(function (responseText) {
          let payload;

          try {
            payload = JSON.parse(responseText);
            if (
              !payload ||
              !payload.hasOwnProperty("message") ||
              !payload.hasOwnProperty("success")
            ) {
              throw new Error("Bad response from server");
            }
          } catch (e) {
            reject("Bad response from server");
          }
          return payload;
        })
        .then(function (reponseData) {
          if (!reponseData.success) {
            reject(
              reponseData.message ||
                "Se ha producido un error al completar la operación"
            );
          }

          me.ocultaLoadingIndicator();
          resolve(reponseData);
        })
        .catch(error => {
          me.ocultaLoadingIndicator();
          reject(error);
        });
    });
  }

  ocultaLoadingIndicator() {
    let loadinIndicator = document.querySelector("#loading-indicator");

    if (loadinIndicator) loadinIndicator.style.display = "none";
  }

  muestraLoadingIndicator() {
    let loadinIndicator = document.querySelector("#loading-indicator");

    if (loadinIndicator) loadinIndicator.style.display = "block";
  }
}
