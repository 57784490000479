import React from "react";
import FeaturesRepository from "../../commons/repositories/FeaturesRepository";
import BiaComponent from "../../commons/components/BiaComponent";
import Window from "../../commons/components/Window";
import Toast from "../../commons/components/Toast";

class Feature extends BiaComponent {
  openEdit() {
    if (this.props.onEdit) this.props.onEdit(this.props.data);
  }

  openFeatureDeleteConfirm(event) {
    this.confirmDelete.open();
  }

  deleteFeature(featureId) {
    new FeaturesRepository().deleteFeature(featureId).then(() => {
      Toast.success("Funcionalidad eliminada");
      this.confirmDelete.close();
      if (this.props.onDelete) this.props.onDelete();
    });
  }

  render() {
    return (
      <div className="table-row highlightrow">
        <div className="table-row-title">
          <div className="name">{this.props.data.title}</div>
        </div>
        <button
          className="button-error"
          onClick={this.openFeatureDeleteConfirm.bind(this)}
          style={{ float: "right" }}
        >
          <i className="fa fa-trash"></i> {this.i18n.buttons["Delete"]}
        </button>
        <button
          className="button-upload"
          onClick={this.openEdit.bind(this)}
          style={{ float: "right" }}
        >
          <i className="fa fa-edit"></i> {this.i18n.buttons["Edit"]}
        </button>
        <Window
          ref={confirmDelete => (this.confirmDelete = confirmDelete)}
          title="Eliminar funcionalidad"
          acceptButtonText={this.i18n.buttons["Delete"]}
          onAccept={this.deleteFeature.bind(this, this.props.data.id)}
        >
          <p>¿Está seguro de eliminar esta funcionalidad?</p>
        </Window>
      </div>
    );
  }
}

export default class FeaturesPanel extends BiaComponent {
  constructor(props) {
    super(props);
    this.state = {
      features: [],
      featureId: "",
      title: "",
      youTubeId: "",
      description: "",
    };
  }

  componentDidMount() {
    this.loadFeatures();
  }

  loadFeatures() {
    let featuresRepository = new FeaturesRepository();

    featuresRepository.getFeatures().then(data => {
      this.setState({ features: data.records });
    });
  }

  openCreateWindow() {
    this.clearFeatureData();
    this.addWindow.open();
  }

  closeCreateWindow() {
    this.addWindow.close();
  }

  closeUpdateWindow() {
    this.updateWindow.close();
  }

  clearFeatureData() {
    this.setState({
      featureId: "",
      title: "",
      youTubeId: "",
      description: "",
    });
  }

  onEditFeature(data) {
    this.setState({
      featureId: data.id,
      title: data.title,
      youTubeId: data.youTubeId,
      description: data.description,
    });
    this.updateWindow.open();
  }

  onDeleteFeature() {
    this.loadFeatures();
  }

  createFeature() {
    if (this.isValidFeature()) {
      new FeaturesRepository()
        .addFeature(
          this.state.title,
          this.state.youTubeId,
          this.state.description
        )
        .then(() => {
          this.clearFeatureData();
          this.closeCreateWindow();
          this.loadFeatures();
        });
    } else {
      Toast.error("El título de la funcionalidad es obligatorio");
    }
  }

  updateFeature() {
    if (this.isValidFeature()) {
      new FeaturesRepository()
        .updateFeature(
          this.state.featureId,
          this.state.title,
          this.state.youTubeId,
          this.state.description
        )
        .then(() => {
          this.clearFeatureData();
          this.closeUpdateWindow();
          this.loadFeatures();
        });
    } else {
      Toast.error("El título de la funcionalidad es obligatorio");
    }
  }

  isValidFeature() {
    return this.state.title;
  }

  titleChange(event) {
    this.setState({ title: event.target.value });
  }

  youTubeIdChange(event) {
    this.setState({ youTubeId: event.target.value });
  }

  descriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  render() {
    let featureForm = (
      <form>
        <div className="field required">
          <label style={{ width: "6rem" }}>Título</label>
          <input
            type="text"
            name="title"
            onChange={this.titleChange.bind(this)}
            value={this.state.title}
          />
        </div>
        <div className="field">
          <label style={{ width: "6rem" }}>youTube ID</label>
          <input
            type="text"
            name="youTubeId"
            onChange={this.youTubeIdChange.bind(this)}
            value={this.state.youTubeId}
          />
        </div>
        <fieldset
          style={{
            marginLeft: "0.55rem",
            marginRight: "0.55rem",
            border: "1px solid #ccc",
            padding: "0.7rem",
          }}
        >
          <legend
            style={{
              width: "6rem",
              marginLeft: "5px",
              marginRight: "10px",
              fontSize: "1.1rem",
            }}
          >
            Descripción
          </legend>
          <textarea
            style={{ width: "100%" }}
            rows="8"
            onChange={this.descriptionChange.bind(this)}
            value={this.state.description}
          />
        </fieldset>
      </form>
    );
    return (
      <div className="work-panel">
        <div className="work-panel-header">
          <h1>Funcionalidades</h1>
        </div>
        <div className="buttons-menu">
          <button
            style={{ float: "right" }}
            className="button-success"
            onClick={this.openCreateWindow.bind(this)}
          >
            <i className="fa fa-plus"></i> <span>Añadir funcionalidad</span>
          </button>
        </div>
        <div>
          {this.state.features
            .filter(feature => feature.visible !== false)
            .map(feature => {
              return (
                <Feature
                  key={feature.id}
                  data={feature}
                  onEdit={this.onEditFeature.bind(this)}
                  onDelete={this.onDeleteFeature.bind(this)}
                />
              );
            })}
        </div>

        <Window
          ref={addWindow => {
            this.addWindow = addWindow;
          }}
          title="Añadir funcionalidad"
          acceptButtonText="Crear"
          onAccept={this.createFeature.bind(this)}
        >
          {featureForm}
        </Window>

        <Window
          ref={updateWindow => {
            this.updateWindow = updateWindow;
          }}
          title="Editar funcionalidad"
          acceptButtonText="Guardar"
          onAccept={this.updateFeature.bind(this)}
        >
          {featureForm}
        </Window>
      </div>
    );
  }
}
