import React from "react";
import BiaComponent from "./BiaComponent";
import LocalStoragePersistencField from "./localstorage/LocalStoragePersistenceField";

export default class SearchBox extends BiaComponent {
  render() {
    let placeholder = this.props.placeholder
      ? this.props.placeholder
      : this.i18n.label["SearchPlaceHolder"];

    return (
      <LocalStoragePersistencField
        {...this.props}
        render={({ value, onValueChange }) => {
          return (
            <div className="search-box">
              <input
                title="Búsqueda"
                type="search"
                placeholder={placeholder}
                value={value}
                onChange={e => onValueChange(e.target.value)}
              />
            </div>
          );
        }}
      />
    );
  }
}
