export default {
  automatic: {
    Title: "Procesamiento automático de requisitos documentales",
    Found: "Se encontraron los siguientes trabajadores en el documento:",
    Processed:
      "Se ha cubierto el requisito documental de los siguientes trabajadores:",
    NotProcessed:
      "Los siguientes trabajadores no se encuentran registrados o no tienen este requisito documental:",
    Add: {
      Title: "Inserción automática de trabajadores",
      Processed: "Se han insertado los siguientes trabajadores:",
      NotProcessed:
        "Los siguientes trabajadores ya existían o se ha encontrado errores al procesarlos:",
    },
    failedAutomaticDocWarningStart:
      "El documento no se ha podido validar autómaticamente y",
    failedAutomaticDocWarningReview: "se ha dejado en revisión",
    failedAutomaticDocWarningEnd:
      "Al no poderse validar autómaticamente, sólo se aplica para este requisito documental específico. Únicamente los documentos descargados directamente de la fuente original y sin ninguna manipulación podrán ser procesados automáticamente.",
    failedAutomaticDocWarningTitle:
      "Imposible validar el documento de manera autómatica",
  },
  assigned: {
    Workers: "Trabajadores asignados",
    Machines: "Equipos de trabajo asignados",
    Companies: "Contratos asignados",
  },
  access: {
    Access: "Control de acceso de trabajadores y equipos de trabajo",
    AccessTitle: "Control de accesos",
    AccessWorkCenter: "Centro de trabajo",
    TotalWorkers: "trabajadores cargados",
    CompaniesSearch: "Empresa",
    EntitiesSearch: "Trabajador/Equipo de trabajo",
    SwitchSearchLabel: "Búsqueda por",
    authorizedByHand: "Autorizado manualmente",
    worker: "Trabajador",
    Success: "Trabajador autorizado correctamente",
    Error: "Error al autorizar al trabajador",
    SelectWorkerFirst: "Seleccione primero un trabajador",
    ObligatoryInfo: "Todos los campos son obligatorios",
  },
  unassigned: {
    Workers: "Trabajadores sin asignar",
    Machines: "Equipos de trabajo sin asignar",
  },
  exceptions: {
    Title: "Error",
    "501": "El documento proporcionado no tiene formato ITA",
    "503": "No se ha encontrado el trabajador en el documento aportado",
    "506": "No se ha encontrado fecha de registro en el documento",
    "507":
      "El CIF del documento no se corresponde con la empresa que lo demanda",
    "508": "El documento ha expirado",
    "509":
      "El documento proporcionado no es un certificado de corriente de pago de la Seguridad Social",
    "510": "El documento está caducado según el tipo de caducidad",
    "511": "Las fechas introducidas están fuera del rango válido",
    "512":
      "El documento proporcionado no es un certificado de corriente de pago de sus obligaciones tributarias",
    "513": "El documento proporcionado no tiene formato TC2",
    "514": "El rango de fechas debe ser posterior o igual a la fecha actual",
  },
  users: {
    Users: "Usuarios",
    Saved: "Usuario añadido correctamente",
    ObligatoryInfo: "Introduce un e-mail y contraseña válidos",
    Add: "Añadir",
    AddIndustrialPropertyUser: "Añadir usuario de propiedad industrial",
    AddContractUser: "Añadir usuario de contrata",
    Edit: "Editar usuario",
    Deleted: "Usuario eliminado correctamente",
    SureDelete: "¿Está seguro de eliminar este usuario?",
    Delete: "Eliminar usuario",
    InvalidMail: "Correo electrónico no válido",
    UserCreationError:
      "No se ha podido crear el usuario. Comprueba si ya existía",
    IndustrialPropertyUsers: "Usuarios de propiedad industrial",
    ContractorUsers: "Usuarios de las contratas",
    LatestCredentialsSent: "Enviado el ",
    CredentialsSentSuccessfully: "Credenciales enviadas correctamente",
  },
  machines: {
    Saved: "Equipo de trabajo creado correctamente",
    ObligatoryInfo: "Introduce un nombre y referéncia",
    Machines: "Equipos de trabajo",
    Assign: "Asignar equipo de trabajo",
    Save: "Equipo de trabajo asignado correctamente",
    Select: "Selecciona un equipo de trabajo",
    Add: "Añadir equipo de trabajo",
    Delete: "Eliminar equipo de trabajo",
    SureDelete: "¿Está seguro de eliminar este equipo de trabajo?",
    Deleted: "Equipo de trabajo eliminado correctamente",
    Edit: "Editar equipo de trabajo",
    NoAvailable: "Ningún equipo de trabajo disponible",
  },
  score: {
    WorkCenters: "centros de trabajo",
    Contractors: "contratas",
    Documents: "documentos",
    Workers: "trabajadores",
    DisabledWorkers: "inactivos",
    DescriptionType: "Requisitos",
    WorkersType: "trabajador",
    CompaniesType: "empresa",
    MachinesType: "equipo",
    Company: "empresa",
    Machines: "equipos",
    Roles: "roles",
    Contracts: "contratos",
  },
  menu: {
    Dashboard: "Dashboard",
    Statistics: "Estadísticas",
    Contracts: "Contratos",
    Documentation: "Documentación",
    Review: "Revisión y validación",
    Templates: "Plantillas",
    DocumentsToRead: "Información a leer",
    Configuration: "Configuración",
    Workers: "Trabajadores",
    Users: "Usuarios",
    DocumentTypes: "Requisitos documentales",
    WorkCenters: "Centros de trabajo",
    Roles: "Roles de trabajador",
    Functions: "Roles de equipo",
    Contractors: "Contratas del contrato",
    Companies: "Empresas",
    ClientCompanies: "Empresas cliente",
    Machines: "Equipos de trabajo",
    Admin: "Administración",
    DocumentsToCompanies: "Información a contratistas",
    AccessesTitle: "Accesos",
    Access: "Control de acceso",
    Accesses: "Registro de accesos",
    InPlant: "Recursos en planta",
    TemporaryAuthorizations: "Autorizaciones temporales",
    NeedHelp: "¿Necesitas ayuda?",
    admin: {
      Roles: "Roles de trabajador",
      Functions: "Roles de equipo",
      Features: "Nuevas funcionalidades",
      Estimate: "Envío de presupuesto",
    },
    DocumentReview: "Revisión de documentos",
  },
  dashboard: {
    workers: {
      Workers: "trabajadores",
      InvalidWorkersNotFound:
        "No se han encontrado trabajadores con documentación inválida",
      WorkersInvalidDocuments: "Documentos no válidos de trabajador",
      WorkerDocuments: "Documentos de trabajador",
    },
    machines: {
      machines: "equipos de trabajo",
      InvalidMachinesNotFound:
        "No se han encontrado equipos de trabajo con documentación inválida",
      MachinesInvalidDocuments: "Documentos no válidos de equipos de trabajo",
      MachinesDocuments: "Documentos de equipos de trabajo",
    },
    company: {
      InvalidCompaniesNotFound:
        "No se han encontrado empresas con documentación inválida",
      CompanyInvalidDocuments: "Documentos no válidos de empresa",
      CompaniesDocuments: "Documentos de empresa",
    },
  },
  documents: {
    Uploaded: "Añadido el",
    UploadedBy: "Añadido por",
    InvalidatedBy: "Invalidado por:",
    Reason: "Motivo:",
    ExpiredOn: "Caducó el",
    ExpiresOn: "Caducará el",
    TolerateTo: "tolerancia hasta el",
    Upload: "Añadir",
    UploadModel: "Modelo",
    UploadDocumentTitle: "Satisfacer requisito documental",
    Approved: "Documento aprobado",
    Denied: "Documento denegado",
    DocumentDeniedReason: "Motivo de la denegación",
    Roles: "Roles",
    AnyRole: "",
    ProvideReason: "Especifica un motivo para la denegación",
    Saved: "Documento añadido correctamente",
    ErrorSaved: "Error añadiendo el documento",
    FileNotFound: "Debe indicar un archivo válido",
    ExtensionNotFound: "Debe indicar un archivo con extensión válida",
    InForceNotFound: "Debe indicar la fecha de vigor",
    EndDateNotFound: "Debe indicar la fecha de caducidad",
    InvalidCompanyDocuments: "Documentos no válidos de empresa",
    Versions: "Versiones",
    Files: "Archivos",
    RequiredFields: "El nombre y el fichero son obligatorios",
    Critical: "CRÍTICO",
    Dates: "Período de validez",
    RequirementLabel: "Requisito",
    CriticalDoc: "Documento crítico",
    Review: "Ir a la revisión",
    Dashboard: "Ir al dashboard",
    MaxSizeDocument: "El tamañado del documento es superior al permitido",
    StartDate: "Fecha de inicio",
    EndDate: "Fecha de fin",
    Origin: "Origen",
    OriginCOMPANY: "Plantilla general",
    OriginCONTRACTOR: "Plantilla de empresa",
    OriginWORK_CENTER: "Plantilla de centro de trabajo",
    OriginROLE: "Role de trabajador",
    OriginFUNCTION: "Role de equipo de trabajo",
    OriginCONTRACT: "Plantilla de contrato",
    ForceOverride: "Forzar invalidación",
    Watch: "Ver",
    viewer: {
      reduce: "reducir",
      increase: "aumentar",
      reset: "resetear zoom",
      rotateLeft: "rotar izquierda",
      rotateRight: "rotar derecha",
      adjust: "ajustar",
      download: "descargar",
      ItsNotPossiblePreviewThisFile:
        "No es posible generar la vista previa de este archivo.",
      YouMustDownloadTheFileToOpenIt:
        "Deberá descargar el archivo para abrirlo.",
    },
  },
  chat: {
    Chat: "Chat",
    Comments: "Comentarios",
    NewComment: "Escribe un nuevo comentario",
    Send: "Enviar",
  },
  companies: {
    Created: "Empresa creada correctamente",
    Updated: "Empresa actualizada",
    ObligatoryInfo: "El nombre y CIF son obligatorios",
    Companies: "Empresas",
    ClientCompanies: "Empresas cliente",
    Add: "Añadir empresa",
    Edit: "Editar empresa",
    Delete: "Eliminar empresa",
    DeletedError:
      "No se ha podido eliminar la empresa. Existe información relacionada",
    SureDelete: "¿Está seguro de eliminar esta empresa?",
    NoAvailable: "No existen empresas disponibles",
    Assign: "Asignar empresa",
    Saved: "Empresa asignada correctamente",
    Select: "Seleccione una empresa",
    Deleted: "Empresa eliminada correctamente",
    NoAssigned: "Ninguna empresa asignada",
    Assigned: "Empresas asignadas:",
    Token: "Envio de requisitos documentales",
    InvalidMailAddresses: "Direcciones de correo inválidas",
    InvalidMailAddressesText:
      "No se han podido enviar los requisitos documentales a las siguientes direcciones de correo:",
    SureToken:
      "¿Está seguro de querer enviar todos los requisitos documentales a la empresa cliente?",
    TokenSent: "Enlace de consulta de requisitos enviado correctamente",
    Recipients: "Destinatarios",
    InvalidMailAddress: "No se han podido enviar los requisitos documentales",
    RelatedCompanies: "Empresas vinculadas",
  },
  contractor: {
    Assign: "Asignar contrata",
    Contractors: "Contratas",
    Saved: "Contrata añadida al contrato correctamente",
    SelectCompany: "Selecciona una empresa",
    Delete: "Eliminar contrata",
    Deleted: "Contrata eliminada correctamente",
    noContractorsDefined:
      "No se han definido contratistas para el contrato actual en este centro de trabajo",
    ShowDeactivatedCompanies: "Mostrar empresas desactivadas",
    MoreOptions: "Más opciones",
    LockRoles: "Bloquear roles",
    Yes: "Sí",
    No: "No",
    DisableContract: "Deshabilitar contrata",
    SureDisableContract: "¿Está seguro de deshabilitar este contrato?",
    ContractEnabledCorrectly: "Contrata habilitada correctamente",
    ContractDisabledCorrectly: "Contrata deshabilitada correctamente",
    Contractor: {
      Contractors: "Subcontratas",
      Assign: "Asignar subcontrata",
      SureDelete:
        "¿Seguro que desea eliminar la asignación de la contrata y trabajadores al contrato activo?",
    },
  },
  contracts: {
    ContractsDisabledWarning:
      "¡¡Atención!! Algunos contratos están deshabilitados (revísalos al final de la página)",
    ContractsDisabled: "Contratos deshabilitados",
    Saved: "Contrato guardado correctamente",
    SelectWorkCenter: "Seleccione un centro de trabajo",
    SelectWorkCenterAndSubject: "Seleccione un asunte y un centro de trabajo",
    SelectSubject: "Seleccione un asunto",
    InForce: "Contratos en vigor",
    New: "Nuevo contrato",
    Edit: "Editar contrato",
    Delete: "Eliminar",
    AssignServices: "Asignar plantillas de contrato",
    SureDelete: "¿Está seguro de eliminar este contrato?",
    NoAvailable: "Ningún contrato disponible",
    Deleted: "Contrato eliminado correctamente",
    WorkCenters: {
      Assign: "Asignar un centro de trabajo",
      Saved: "Centro de trabajo asignado correctamente",
      SelectWorkCenter: "Selecciona un centro de trabajo",
      Deleted: "Centro de trabajo eliminado correctamente",
      Min:
        "Un contracto debe tener obligatoriamente un centro de trabajo, asgine uno previamente o elimine el contrato",
    },
    DocumentTypes: {
      Assign: "Añadir requisito documental predefinido",
      Add: "Añadir requisito documental",
      Edit: "Editar requisito documental",
      DocumentTypes: "Requisitos documentales",
      NoAvailable: "Ningún requisito documental disponible",
      both: "Ambos",
      contract_of: "Contratista",
      subcontract_of: "Subcontratista",
      Delete: "Eliminar tipo de documento",
      Restore: "Restaurar el tipo de documento",
      SureDelete: "¿Está seguro de eliminar este requisito documental?",
      SureRestore:
        "¿Está seguro de querer restaurar este requisito documental con las condiciones generales?",
      Worker: "Requisitos documentales de trabajador",
      Company: "Requisitos documentales de empresa",
      Machine: "Requisitos documentales de equipo de trabajo",
      Saved: "Requisito documental asignado correctamente",
      Assigned: "Requisitos documentales asignados:",
      Select: "Seleccione un requisito documental",
      Deleted: "Requisito documental eliminado correctamente",
    },
    Companies: {
      AssignSub: "Asignar subcontrata",
      AssignableSub: "Subcontratas asignables",
      Subcontractors: "Subcontratas:",
    },
    Workers: {
      Deleted: "Trabajador eliminado del contrato correctamente",
      FullyDisabledTitle: "Trabajador desactivado de manera global",
      FullyDisabledWarning:
        "Este trabajador está desactivado de manera global, no sólo en el contrato. Si deseas activarlo, se debe activar desde el listado de trabajadores",
      ShowDisabledWorkers: "Mostrar trabajadores desactivados",
      deactivate: "Desactivar",
      activate: "Activar",
    },
    Machines: {
      Deleted: "Equipo de trabajo eliminado del contrato correctamente",
    },
  },
  review: {
    Pending: "Revisión pendiente de documentos",
    WorkerDocuments: "Documentos de trabajadores",
    CompanyDocuments: "Documentos de empresa",
    MachineDocuments: "Documentos de equipos de trabajo",
    endDateBeforeInForceDate:
      "La fecha de fin es anterior a la fecha de inicio",
    MaxDocumentsShown:
      "Atención!! Se ha alcanzado el número máximo de documentos visibles. Sólo se mostrarán los 1000 primeros.",
  },
  read: {
    Add: "Añadir documento",
    Edit: "Editar documento",
    Documents: "Información a leer",
    Delete: "Eliminar documento",
    SureDelete: "¿Seguro que quiere eliminar este documento?",
    Uploaded: "Documento añadido correctamente",
    ErrorUpload: "Error añadiendo documento",
    Company: "Documentos de empresa",
    WorkCenter: "Documentos de centro de trabajo",
    WorkCenterRequired: "Es necesario indicar un centro de trabajo",
  },
  services: {
    Saved: "Plantilla asignada correctamente",
    NoSelection: "No se ha seleccionado ninguna plantilla",
    Deleted: "Plantilla eliminada correctamente",
    NoAvailable: "No hay plantillas disponibles para la asignación",
    NoAssigned: "No hay plantillas de contrato asignadas",
    Assigned: "Plantillas de contrato asignadas:",
  },
  workcenters: {
    Add: "Añadir centro de trabajo",
    Edit: "Editar centro de trabajo",
    Delete: "Eliminar centro de trabajo",
    WorkCenters: "Centros de trabajo",
    Saved: "Centro de trabajo creado correctamente",
    Updated: "Centro de trabajo actualizado correctamente",
    ObligatoryInfo:
      "Introduce una denominación, dirección, y código postal válidos",
    AssignTemplate: "Asignar plantilla al centro de trabajo",
    DeleteAssignSure:
      "¿Está serguro de eliminar esta asignación de centro de trabajo?",
    DeleteAssign: "Eliminar la asignación del centro de trabajo",
    Deleted: "Centro de trabajo eliminado correctamente",
    SureDelete: "¿Está seguro de eliminar este centro de trabajo?",
    NoSelect: "Ningún centro de trabajo seleccionado",
    NoAvailable: "Ningún centro de trabajo disponible",
    ContractAccess: "Acceder al contrato",
    WorkCentersClosed: "Centros de trabajo cerrados",
    WorkCenterClosedWarning:
      "¡¡Atención!! Algunos centros de trabajo están cerrados (revísalos al final de la página)",
    ClosedOn: "Cerrado el: ",
    CloseOn: "Cierra el: ",
  },
  workers: {
    Saved: "Trabajador creado correctamente",
    ObligatoryInfo: "El nombre, primer apellido, NIF y e-mail son obligatorios",
    Workers: "Trabajadores",
    WorkersDisabled: "Trabajadores deshabilitados",
    WorkersDisabledWarning:
      "¡¡Atención!! Algunos trabajadores están deshabilitados (revísalos al final de la página)",
    Assign: "Asignar trabajador",
    Save: "Trabajador asignado correctamente",
    Select: "Selecciona un trabajador",
    Add: "Añadir trabajador",
    AddFromITA: "Añadir trabajadores desde ITA",
    Delete: "Eliminar trabajador",
    SureDelete: "¿Está seguro de eliminar este trabajador?",
    Deleted: "Trabajador eliminado correctamente",
    Roles: "Roles",
    WorkerRoles: "Roles de trabajador",
    NoAvailable: "Ningún trabajador disponible",
  },
  templates: {
    Add: "Añadir plantilla",
    Assigned: "Plantillas asignadas:",
    AssignTemplates: "Asignar plantillas",
    NoAssigned: "No hay plantillas asginadas",
    Saved: "Plantilla asignada correctamente",
    Delete: "Eliminar plantilla",
    NoAvailable: "No hay plantillas disponibles para la asignación",
    Deleted: "Plantilla eliminada correctamente",
    SureDelete: "¿Está seguro de eliminar esta plantilla?",
    NoSelection: "No se ha seleccionado ninguna plantilla",
    Company: "Plantillas generales",
    WorkCenter: "Plantillas de centros de trabajo",
    Contract: "Plantillas de contrato",
    Contractor: "Plantillas de empresa",
    Edit: "Editar plantilla",
  },
  roles: {
    Add: "Añadir rol",
    Edit: "Editar rol",
    NewAssigned: "Rol asignado correctamente",
    Saved: "Rol creado correctamente",
    Updated: "Rol actualizado correctamente",
    Duplicated: "Rol duplicado correctamente",
    Select: "Seleccione un rol",
    Deleted: "Rol eliminado correctamente",
    NoAvailable: "Ningún rol disponible",
    Assigned: "Roles:",
    Delete: "Eliminar rol",
    SureDelete: "¿Está seguro de eliminar este rol?",
    ObligatoryInfo: "Introduce un nombre válido",
    Assign: "Añadir rol predefinido",
    RolesDefinedIndustrialProperty:
      "Roles definidos por la propiedad industrial",
    PredefinedRoles: "Roles predefinidos",
    WorkerNoAssignedRole: "Este trabajador no tiene ningún rol asignado",
    AtLeastOneRole: "Debe existir al menos un rol para este trabajador",
    documentTypes: {
      DocumentTypes: "Requisitos documentales",
      Assigned: "Requisitos documentales asignados:",
      NoAvailable: "No existen requisitos documentales",
      Available: "Requisitos documentales:",
      Saved: "Requisito documental asignado correctamente",
      Select: "Seleccione un requisito documental",
      Deleted: "Requisito documental eliminado correctamente",
    },
  },
  functions: {
    Add: "Añadir rol de equipo",
    Edit: "Editar rol de equipo",
    NewAssigned: "Rol de equipo asignado correctamente",
    Saved: "Rol de equipo creado correctamente",
    Updated: "Rol de equipo actualizado correctamente",
    Duplicated: "Rol de equipo duplicado correctamente",
    Select: "Seleccione un rol de equipo",
    Deleted: "Rol de equipo eliminado correctamente",
    NoAvailable: "Ningun rol de equipo disponible",
    Assigned: "Roles de equipo:",
    Delete: "Eliminar rol de equipo",
    SureDelete: "¿Está seguro de eliminar este rol de equipo?",
    ObligatoryInfo: "Introduce un nombre válido",
    Assign: "Añadir rol de equipo predefinido",
    MachineNoAssignedRole:
      "Este equipo de trabajo no tiene ningún rol asignado",
    documentTypes: {
      DocumentTypes: "Requisitos documentales",
      Assigned: "Requisitos documentales asignados:",
      NoAvailable: "No existen requisitos documentales",
      Available: "Requisitos documentales:",
      Saved: "Requisito documental asignado correctamente",
      Select: "Seleccione un requisito documental",
      Deleted: "Requisito documental eliminado correctamente",
    },
  },
  versions: {
    Versions: "Versiones",
  },
  label: {
    Actions: "Acciones",
    Subject: "Asunto:",
    WorkCenter: "Centro de trabajo",
    Worker: "Trabajador:",
    WorkerLower: "trabajador",
    WorkersLower: "trabajadores",
    Machine: "Equipo de trabajo:",
    MachineLower: "equipo de trabajo",
    MachinesLower: "equipos de trabajo",
    Company: "Empresa:",
    CompanyContractor: "Empresa cliente:",
    Name: "Nombre:",
    ValidDays: "Lectura obligatoria antes de",
    ValidDaysPost: " días",
    Type: "Tipo:",
    Destiny: "Destino:",
    Login: "Login:",
    Mail: "Mail:",
    Surname1: "Apellido 1:",
    Surname2: "Apellido 2:",
    CIF: "CIF:",
    ResponsableCAE: "Correo responsable CAE:",
    Contact: "Contacto:",
    NIF: "NIF/NIE",
    Password: "Contraseña:",
    InForceDate: "Fecha inicio",
    EndDate: "Fecha fin",
    Expire: "Caduca:",
    CanExpire: "¿Este requisito documental tiene caducidad?",
    Expiration: "Caducidad:",
    Months: "meses",
    Days: "días",
    ExpirationMode: "Modo:",
    Address: "Dirección:",
    Phone: "Teléfono:",
    SearchPlaceHolder: "Expresión de búsqueda",
    File: "Archivo:",
    Critical: "Crítico:",
    ZipCode: "C.P.:",
    Population: "Ciudad:",
    Province: "Provincia:",
    Close: "Cierre",
    Contract: "Contrato",
    Contracts: "Contratos:",
    Contractor: "Contrata",
    DocumentTypesAvailable: "Tipos de documentos a asignar:",
    RolesAvailable: "Roles a asignar:",
    ReviewCriteria: "Observaciones para el validador",
    ContractorCriteria: "Observaciones para la contrata",
    CompaniesAvailable: "Empresas a asignar:",
    ServicesAvailable: "Plantillas a asignar:",
    TemplatesAvailable: "Plantillas a asignar:",
    Applicant: "Solicitante:",
    Picture: "Foto:",
    Profile: "Perfiles disponibles:",
    ADMINISTRADOR_Description:
      "Permite acceder a todas las opciones de la propiedad industrial y del contratista",
    CONTRATISTA_Description:
      "Permite subsanar requisitos, gestionar contratos y definir trabajadores y equipos de trabajo",
    VALIDADOR_Description:
      "Permite revisar y validar la documentación aportada por las contratas",
    Logo: "Logo:",
    Disabled: "Deshabilitado",
    Roles: "Roles",
    Role: "Rol:",
    Bia360: "Cliente:",
    PI: "Empresa cliente:",
    AutomaticType: "Tipo automático:",
    Reference: "Referéncia:",
    ShowDisbledWorker: "Mostrar trabajadores deshabilitados",
    MultipleRecipients: "Subsanar en todos los solicitantes",
    Autonomo: "Autónomo:",
    Tolerate: "Tolerancia:",
    StillValidUntil: "Válido hasta:",
    MandatoryWorkerRoles: "Asignación obligatoria de roles a trabajadores",
    ExamineContracts:
      "Para examinar la lista de contratos asignados, es necesario hacer clic sobre cada uno de los elementos que aparecen colapsados a continuación:",
    ExamineWorkers:
      "Para examinar la lista de trabajadores asignados, es necesario hacer clic sobre cada uno de los elementos que aparecen colapsados a continuación:",
    ExamineMachines:
      "Para examinar la lista de equipos de trabajo asignados, es necesario hacer clic sobre cada uno de los elementos que aparecen colapsados a continuación:",
    Activate: "Activar",
    Deactivate: "Desactivar",
    MonthlyExpiration: "Caducidad mensual:",
    Day1EachMonth: "Día 1 de cada mes",
    FromDateIndicated: "A partir de la fecha indicada",
    Language: "Idioma:",
  },
  expireMode: {
    natural: "Mes natural",
    encurso: "Mes en curso",
    fecha: "Fecha fin",
  },
  automaticType: {
    none: "No es de tipo automático",
    ita: "ITA",
    tc2: "TC2",
    rnt: "RNT",
    ss: "Certificado corriente de pago SS",
    aeat: "Certificado corriente de pago AEAT",
  },
  buttons: {
    Add: "Añadir",
    Create: "Crear",
    Delete: "Eliminar",
    Restore: "Restaurar",
    Edit: "Editar",
    Assign: "Asignar",
    Deny: "Denegar",
    Approve: "Aprobar",
    Ok: "Aceptar",
    Cancel: "Cancelar",
    Save: "Guardar",
    Token: "Enviar requisitos documentales",
    Credentials: "Enviar credenciales acceso",
    Send: "Enviar",
    Duplicate: "Duplicar",
    Roles: "Roles",
    unlink: "Desvincular",
    ModeAction: {
      expanded: "Colapsar todo",
      collapsed: "Expandir todo",
    },
    Previous: "Anterior",
    Next: "Siguiente",
  },
  placeholders: {
    Name: "Nombre",
    SearchContractAndWorkCenter:
      "Búsqueda por nombre de contrato o centro de trabajo",
    SearchNameCIF: "Búsqueda por nombre de empresa o CIF",
    SearchNameSurnameDNI: "Búsqueda por trabajador o equipo de trabajo",
    SearchNameSurnameDNIRol: "Búsqueda por nombre, apellidos, DNI o rol",
    SearchMachineNameReference: "Búsqueda por nombre o referéncia",
    SearchName: "Búsqueda por nombre",
    SearchContractorsAndSubContractors:
      "Búsqueda por nombre de las contratas y subcontratas",
    SearchUserMailCompany: "Búsqueda por nombre de usuario, correo o empresa",
    SearchNameAndDocumentType:
      "Búsqueda por nombre del rol y tipo de documento",
    SearchNameAndDocumentTypeFunction:
      "Búsqueda por nombre del rol de equipo y tipo de documento",
    SearchNameReference: "Búsqueda por nombre de empresa o referencia",
    SearchWorkerCompanyNameCIF:
      "Búsqueda por nombre de trabajador, empresa o CIF",
    SearchWorkerMachineCompanyName:
      "Búsqueda por trabajador, equipo de trabajo o empresa",
    SearchCompanyContractWorkcenterContract:
      "Búsqueda por empresa, contrato o centro de trabajo",
  },
  status: {
    Invalid: "documentos no válidos",
    Allowed: "Permitido",
    NotAllowed: "No permitido",
  },
  pendingReading: {
    Warning: "Atención",
    title: "Tienes información pendiente de leer",
    description:
      "Para poder continuar gestionando tu documentación es necesario que leas y entiendas la siguiente información:",
    Download: "Descargar",
    confirmationTitle: "Confirmación de lectura del documento",
    confirmationButton: "Confirmo la lectura del documento",
    confirmationDescription:
      "¿Confirmas que has leído y aceptas el presente documento?",
    mandatoryReading: "Documentación de lectura obligatoria",
    mandatoryReadingAdvice:
      "Para poder continuar gestionando tu documentación es necesario que leas y entiendas la siguiente información",
    readingInNextDays: "Documentación a leer en próximas fechas",
    readingInNextDaysAdvice:
      "La lectura de la siguiente información no bloquea el acceso a Bia360 hasta que el plazo definido expire",
    deadline: "Plazo de lectura",
    days: "días",
    pendingReadingAdvice: "Atención: Tienes información pendiente de leer",
    delayReading: "Leer documentación más tarde",
    readingConfirmationOne: "Confirmación de lectura del documento",
    readingConfirmationByMe: "Confirmo la lectura del documento",
    readingConfirmationTwo:
      "¿Confirmas que has leído y aceptas el presente documento?",
  },
  documentTypes: {
    nameRequired: "El nombre es obligatorio",
    expiredRequired:
      "Si indica el modo de caducidad en curso debe indicar el número de meses (valor superior o igual a 1)",
    destinyRequired: "Debe marcar mínimamente un destino",
    models: "Modelos de ejemplo",
    Saved: "Tipo de documento guardado correctamente",
    Deleted: "Tipo de documento eliminado correctamente",
    Restored: "Se han restaurado los valores por defecto",
    assign: "Asignar",
    alreadyAssignedTitle: "Requisitos en más plantillas",
    alreadyAssignedWarning:
      "Los siguientes requisitos ya han sido asignados a otra plantilla. ¿Quieres asignarlos a esta de todas formas?:",
  },
  tag: {
    ok: "VÁLIDO",
    valid: "VÁLIDO",
    expired: "CADUCADO",
    incorrect: "RECHAZADO",
    invalid: "NO VÁLIDOS",
    read: "POR LEER",
    review: "EN REVISIÓN",
    reviewok: "EN REVISIÓN",
    empty: "SIN CONTENIDO",
    client: "EMPRESA CLIENTE",
    tolerate: "TOLERANCIA",
    selfEmployed: "Autónomo",
  },
  warnings: {
    deny:
      "¿Está seguro de denegar el requisito documental sin indicar el motivo?",
    denyFirstChunk: "¿Está seguro de",
    denyLastChunk: "el requisito documental sin indicar el motivo?",
  },
  dates: {
    select: "Introduzca fecha",
  },
  texts: {
    createdBy: "creada por",
    disassociateCompany: "Desvincular empresa",
    disassociate: "Desvincular",
    disassociateConfirm: "¿Está seguro de desvincular esta empresa?",
    noAffectedContracts:
      "Los contratos a los que esté asignada actualmente no se verán afectados",
    linked: "vinculada",
    pending: "pendiente",
    contractLinkAuthByMeChunk:
      "a las que has dado autorización para que te den de alta en sus contratos",
    companiesAlready360customers: "Empresas que ya son clientes de Bia360 y",
    contractLinkAuthToMeChunk:
      "que han dado su autorización para poder ser utilizadas en contratos",
    lastLog: "Último registro",
    fullAccessLog: "Registro completo de acceso",
    searchAccessAdvice:
      "Utilice el campo de búsqueda para consultar el acceso de trabajadores y equipos de trabajo.",
    documentDenied: "Documento rechazado",
    applicantLabel: "Solicitado por",
    applicantLabelLower: "solicitado por",
    documentVersions: "Versiones del requisito",
    uploadedBy: "Subido por",
    document: "documento",
    documents: "documentos",
    close: "Cerrar",
    updatedData: "Datos actualizados",
    machineFunction: "Tipo de equipo",
    showDisabledContracts: "Mostrar contratos desactivados",
    contractorMaxs: "Maximos por contrata",
    accessToRequirement: "Acceder al requisito",
    processedDocuments: "Documentos procesados",
    loading: "cargando ...",
    property: "Propiedad industrial",
    propertyAcronym: "PI",
    reviewAndValidation: "Revisión y validación de requisitos",
    hideRejected: "Ocultar los requisitos rechazados",
    haveProcessed: "Se han procesado",
    accept: "Aceptar",
    passwordPlaceHolder: "Sólo si el PDF subido requiere contraseña",
    warning: "Aviso",
    selectWorkCenter: "Seleccione un centro de trabajo",
    by: "por",
    from: "del",
    to: "al",
  },
  linked: {
    requestLinKChunk: "Solicitar vinculación de",
    propertyClient: "empresa cliente",
    contractor: "contrata",
    canCreateContractToMeChunk:
      "te podrá dar de alta en sus contratos y solicitarte así la documentación necesaria.",
    canCreateContractByMeChunk:
      "podrás darla de alta en tus contratos y requerirle documentación.",
    existentClientWarning:
      "¡¡Atención!!: La empresa que quieres dar de alta ya es cliente de Bia360",
    linkExplanationStarterChunk:
      "Puedes trabajar con ella enviándole una solicitud. En cuanto la empresa apruebe tu solicitud,",
    linkQuestion: "¿Deseas solicitar la vinculación con esta empresa?",
    agree: "De acuerdo",
  },
  profiles: {
    warning: "AVISO",
    defaultProfilesWarningLine1:
      "Si no se selecciona ningún perfil, el usuario tendrá acceso a la consulta del control de acceso",
    defaultProfilesWarningLine2: "y a la información a contratistas.",
    administrator: "ADMINISTRADOR",
    contractor: "CONTRATISTA",
    validator: "VALIDADOR",
  },
  icons: {
    accessControl: "Icono control de acceso",
    dashboard: "Icono dashboard",
    configuration: "Icono configuración",
    documentation: "Icono documentación",
    adminstration: "Icono administración",
    contracts: "Icono contratos",
    contractor: "Icono contratista",
    readings: "Icono documentación a leer",
    contact: "Icono contacto",
  },
  inPlant: {
    resources: "Recursos en planta",
    noResourcesInPlant:
      "Actualmente no hay ningún trabajador o equipo de trabajo en planta",
  },
  temporaryAuthorizations: {
    authorizations: "Autorizaciones temporales",
    noTemporaryAuthorizations:
      "Actualmente no hay ninguna autorización temporal",
    new: "Nueva autorización",
    newAuthorization: "Nueva autorización temporal",
    startDate: "Fecha de inicio",
    endDate: "Fecha de fin",
    reason: "Motivo de la autorización temporal",
    startAuthorization: "Inicio",
    endAuthorization: "Fin",
    autocomplete: "Introduzca nombre o CIF del trabajador",
    delete: "Eliminar autorización",
    sureDelete: "¿Está seguro de querer eliminar esta autorización?",
    deletedAuthorization: "Autorización eliminada",
    errorDeleting: "Error al eliminar la autorización",
  },
  accesses: {
    registerEntrance: "Registrar entrada",
    registerExit: "Registrat salida",
    registeredEntrance: "Entrada registrada",
    registerdExit: "Salida registrada",
    noLogsFound:
      "No se han encontrado registros de accesos de trabajadores y equipos de trabajo con los criterios actuales.",
    access: "Acceso",
    entity: "Trabajador/Equipo de trabajo",
    company: "Empresa",
    loggedBy: "Registrado por",
    dates: "Fechas",
    logTitle: "Registro de accesos de trabajadores y equipos de trabajo",
    entrance: "Entradas",
    exit: "Salidas",
    duplicateEntranceWarning:
      "Este trabajador consta como que ya ha entrado en las últimas 24h y no existe constancia de que haya salido, ¿Es correcto registrar su entrada de nuevo?",
    duplicatedEntrance: "Entrada duplicada",
    exportToExcel: "Exportar a Excel",
    onlyWithoutDocumentationPresented: "Entradas no OK",
    workerDocuments: "Documentos de trabajador",
    machineDocuments: "Documentos de equipo de trabajo",
  },
  contact: {
    helpQuestion: "¿Necesitas ayuda?",
    contactWithUs:
      "Contacta con nosotros y el equipo CAE de Bia360 resolverá todas tus dudas",
    contactInformation: "Esta es nuestra información de contacto",
    email: "Correo electrónico",
    phone: "Teléfono",
  },
  rolesblock: {
    blockExplanationFirstParagraf:
      "Por defecto, el contratista puede asignar roles a sus trabajadores.",
    blockExplanationSecondtParagraf:
      "Es posible bloquear esta funcionalidad y que los roles de los trabajadores de la contrata sólo puedan ser gestionados desde la propiedad industrial.",
    allowManagment: "Permitir gestión de roles del contratista",
  },
  assignment: {
    unassignWorker: "Desasignar trabajador",
    unassign: "Desasignar",
    confirmUnassigment:
      "¿Está seguro de desasignar este trabajador del contrato?",
    unassignmentAdviceFirstChunk: "Se desasignará el trabajor del contrato",
    unassignmentAdviceSecondChunk: "y se eliminará toda su documentación",
    unassignmentAdviceThirdChunk: "relativa a este contrato",
  },
  readings: {
    loading: "Cargando visualizaciones...",
    noCompanies: "No hay empresas que deban leer este documento",
    visualizations: "Visualizaciones",
    unread: "No leídos",
    read: "Leído",
    on: "el",
  },
  expirableDocuments: {
    filterAdvice:
      "Sólo estás viendo los requisitos de un tipo de documento concreto.",
    showAll: "Ver todos",
    loading: "Cargando documentos...",
    noExpirableDocuments: "No hay documentos próximos a caducar",
    title: "Documentos próximos a caducar",
  },
  newfeatures: {
    title: "Novedades",
    read: "Visto",
    advice: "¡Novedades!",
  },
  stats: {
    title: "Estadísticas de uso de Bia360",
    documentValidations: "Validaciones de documentos",
    validateOrRejected:
      "Documentos validados o rechazados durante el proceso de revisión",
    validatedByPI: "Validado por la propiedad industrial",
    validatedByBia360: "Validado por el equipo de Bia360",
  },
  auth: {
    passwordDontMatch: "Las contraseñas no coinciden",
    passwordChanged: "Contraseña cambiada correctamente",
    userData: "Datos del usuario",
    userName: "Nombre de usuario",
    email: "Correo electrónico",
    password: "Contraseña",
    changePassword: "Cambiar contraseña",
    passwordChange: "Cambio de contraseña",
    save: "Guardar",
    newPassword: "Nueva contraseña",
    repeatPassword: "Repite contraseña",
    logout: "Cerrar sesión",
    principalCompany: "Empresa principal",
    selectCompany: "Ninguna empresa seleccionada...",
  },
  token: {
    tokenExpiredAdvice:
      "La dirección de acceso facilitada ha caducado.Por favor, solicite a su contratista que genere una nueva.",
    requirementsGeneratedFor: "Requisitos documentales generados para",
    exportedDocumentFor: "Documento exportado por",
    withCIF: "con CIF",
    correctUntil: "Válido hasta el",
    activeCredentials: "ACTIVO",
    inactiveCredentials: "INACTIVO",
    sentOn: "Enviado el",
    numberOfRecipients: "Número de destinatarios",
    noShipmentDocumentation: "No se ha realizado ningún envío de documentación",
    noShipment: "No se ha realizado ningún envío",
  },
  assignedWorkers: {
    allContractsAssigned:
      "Todos los contratos disponibles han sido ya asignados para este trabajador",
    noContractAssigned: "No se ha asignado ningún contrato a este trabajador",
    blockedAdvice:
      "Este opción está limitada por la propiedad industrial, por favor, ponte en contacto con esta para cualquier duda",
    unassignWorker: "Desasignar trabajador",
    unassign: "Desasignar",
    correctAssignment: "Trabajador asignado correctamente",
    maxWorkersReached:
      "La asignación supera el límite de trabajadores en los siguientes contratos",
    assignToAllContracts: "Asignar a todos los contratos",
    unassignedOK: "Trabajador desasignado del contrato correctamente",
    warning:
      "¡¡Atención!! Algunos contratos han sido desactivados por la propiedad industrial y no permitirán la subida de documentos:",
    thereIsContractWithMandatoryRoles:
      "Para alguno de los contratos es obligatorio especificar un rol. No se asignará el trabajador a ningún contrato",
  },
  denyReasons: {
    specifyReasonFisrtChunk: "Especifica un motivo para",
    denyArticle: "la",
    denyName: "denegación",
    denyVerb: "denegar",
    invalidateArticle: "la",
    invalidateName: "invalidación",
    invalidateVerb: "invalidar",
  },
  "rc-calendar": {
    today: "Hoy",
    now: "Ahora",
    backToToday: "Volver a hoy",
    ok: "Aceptar",
    clear: "Limpiar",
    month: "Mes",
    year: "Año",
    timeSelect: "Seleccionar hora",
    dateSelect: "Seleccionar fecha",
    monthSelect: "Elegir un mes",
    yearSelect: "Elegir un año",
    decadeSelect: "Elegir una década",
    yearFormat: "YYYY",
    dateFormat: "DD/MM/YYYY",
    dayFormat: "DD",
    dateTimeFormat: "DD/MM/YYYY HH:mm:ss",
    monthFormat: "MMMM",
    monthBeforeYear: true,
    previousMonth: "Mes anterior (PageUp)",
    nextMonth: "Mes siguiente (PageDown)",
    previousYear: "Año anterior (Control + left)",
    nextYear: "Año siguiente (Control + right)",
    previousDecade: "Década anterior",
    nextDecade: "Década siguiente",
    previousCentury: "Siglo anterior",
    nextCentury: "Siglo siguiente",
  },
  dataagreement: {
    dataAccess:
      "Aceptación de condiciones Reglamento General de Protección de Datos (RGPD)",
    continue: "Continuar",
    explanation:
      "Con el fin de ejercer el papel de encargado de tratamiento de datos, Bia360 precisa de la siguiente información con el fin de generar el contrato de tratamiento.",
    agreementNeeded:
      "Su aceptación es necesaria para poder continuar con la prestación del servicio",
    representativeFullName: "Representante legal (nombre completo)",
    representativeDNI: "DNI del representante legal",
    companyAddress: "Dirección de la empresa",
    accept: "Acepto",
    pdfDownload: "Descargar PDF",
  },
  search: {
    areMoreResults: "Mostrando sólo 10 resultados",
  },
};
