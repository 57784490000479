export default {
  automatic: {
    Title: "Processamento automático de requisitos documentais",
    Found: "Os seguintes trabalhadores foram encontrados no documento:",
    Processed:
      "O requisito documental dos seguintes trabalhadores foi preenchido:",
    NotProcessed:
      "Os seguintes trabalhadores não estão registrados ou não têm este requisito documental:",
    Add: {
      Title: "Inserção automática de trabalhadores",
      Processed: "Foram inseridos os seguintes trabalhadores:",
      NotProcessed:
        "Os seguintes trabalhadores já existiam ou foram encontrados erros ao serem processados:",
    },
    failedAutomaticDocWarningStart:
      "O documento não pôde ser validado automaticamente e",
    failedAutomaticDocWarningReview: "foi deixado em revisão",
    failedAutomaticDocWarningEnd:
      "Como não pode ser validado automaticamente, aplica-se apenas a este requisito documental específico. Somente documentos baixados diretamente da fonte original e sem nenhuma manipulação podem ser processados automaticamente.",
    failedAutomaticDocWarningTitle:
      "Não foi possível validar o documento automaticamente",
  },
  assigned: {
    Workers: "Trabalhadores atribuídos",
    Machines: "Equipas de trabalho atribuídas",
    Companies: "Contratos atribuídos",
  },
  access: {
    Access: "Controlo de acesso de trabalhadores e equipas de trabalho",
    AccessTitle: "Controlo de acessos",
    AccessWorkCenter: "Centro de trabalho",
    TotalWorkers: "trabalhadores carregados",
    CompaniesSearch: "Empresa",
    EntitiesSearch: "Trabalhador / Equipe de Trabalho",
    SwitchSearchLabel: "Pesquisar por",
    authorizedByHand: "Autorizado manualmente",
    worker: "Trabalhador",
    Success: "Trabalhador autorizado corretamente",
    Error: "Erro ao autorizar o trabalhador",
    SelectWorkerFirst: "Selecione um trabalhador primeiro",
    ObligatoryInfo: "Todos os campos são obrigatórios",
  },
  unassigned: {
    Workers: "Trabalhadores não atribuídos",
    Machines: "Equipas de trabalho não atribuídas",
  },
  exceptions: {
    Title: "Erro",
    "501": "O documento disponibilizado não tem formato ITA",
    "503": "Não se encontrou o trabalhador no documento fornecido",
    "506": "No se encontrou a data de registo no documento",
    "507": "O NIF do documento não corresponde à empresa que o solicita",
    "508": "O documento expirou",
    "509":
      "O documento proporcionado não é um certificado de corrente de pagamento da Segurança Social",
    "510": "O documento expirou segundo o tipo de caducidade",
    "511": "As datas inseridas estão fora do intervalo válido",
    "512":
      "O documento proporcionado não é um certificado de corrente de pagamento das suas obrigações fiscais",
    "513": "O documento disponibilizado não tem formato TC2",
    "514": "O período deve ser posterior ou igual à data atual",
  },
  users: {
    Users: "Utilizadores",
    Saved: "Utilizador adicionado corretamente",
    ObligatoryInfo: "Insira um e-mail e password válidos",
    Add: "Adicionar",
    AddIndustrialPropertyUser: "Adicionar usuário da propriedade industrial",
    AddContractUser: "Adicionar usuário do contrato",
    Edit: "Editar utilizador",
    Deleted: "Utilizador eliminado com êxito",
    SureDelete: "Tem a certeza que quer eliminar este utilizador?",
    Delete: "Eliminar utilizador",
    InvalidMail: "E-mail inválido",
    UserCreationError:
      "Não foi possível criar o utilizador. Verifique se já existia",
    IndustrialPropertyUsers: "Usuários de propriedades industriais",
    ContractorUsers: "Usuários dos contratos",
    LatestCredentialsSent: "Enviada em ",
    CredentialsSentSuccessfully: "Credenciais enviadas com sucesso",
  },
  machines: {
    Saved: "Equipa de trabalho criada com êxito",
    ObligatoryInfo: "Insira um nome e referência",
    Machines: "Equipas de trabalho",
    Assign: "Atribuir equipa de trabalho",
    Save: "Equipa de trabalho atribuída com êxito",
    Select: "Selecione uma equipa de trabalho",
    Add: "Acrescentar equipa de trabalho",
    Delete: "Eliminar equipa de trabalho",
    SureDelete: "Tem a certeza que quer eliminar esta equipa de trabalho?",
    Deleted: "Equipa de trabalho eliminada com êxito",
    Edit: "Editar equipa de trabalho",
    NoAvailable: "Não há equipas de trabalho disponíveis",
  },
  score: {
    WorkCenters: "centros de trabalho",
    Contractors: "empreitadas",
    Documents: "documentos",
    Workers: "trabalhadores",
    DisabledWorkers: "inativos",
    DescriptionType: "Requisitos",
    WorkersType: "trabalhador",
    CompaniesType: "empresa",
    MachinesType: "equipa",
    Company: "empresa",
    Machines: "equipas de trabalho",
    Roles: "funções",
    Contracts: "contratos",
  },
  menu: {
    Dashboard: "Painel de controlo",
    Statistics: "Estatísticas",
    Contracts: "Contratos",
    Documentation: "Documentação",
    Review: "Revisão e validação",
    Templates: "Modelos",
    DocumentsToRead: "Informações a ler",
    Configuration: "Configuração",
    Workers: "Trabalhadores",
    Users: "Utilizadores",
    DocumentTypes: "Requisitos documentais",
    WorkCenters: "Centros de trabalho",
    Roles: "Funções do trabalhador",
    Functions: "Funções da equipa",
    Contractors: "Empreitadas do contrato",
    Companies: "Empresas",
    ClientCompanies: "Empresas cliente",
    Machines: "Equipas de trabalho",
    Admin: "Administração",
    DocumentsToCompanies: "Informações aos empreiteiros",
    AccessesTitle: "Acessos",
    Access: "Controlo de acessos",
    Accesses: "Registo de acessos",
    InPlant: "Recursos na instalação",
    TemporaryAuthorizations: "Autorizações temporárias",
    NeedHelp: "Necessita de ajuda?",
    admin: {
      Roles: "Funções do trabalhador",
      Functions: "Funções da equipa",
      Features: "Novas funcionalidades",
      Estimate: "Envio de orçamento",
    },
  },
  dashboard: {
    workers: {
      Workers: "trabalhadores",
      InvalidWorkersNotFound:
        "Não foram encontrados trabalhadores com documentação inválida",
      WorkersInvalidDocuments: "Documentos inválidos de trabalhador",
      WorkerDocuments: "Documentos de trabalhador",
    },
    machines: {
      machines: "equipas de trabalho",
      InvalidMachinesNotFound:
        "Não foram encontradas equipas com documentação inválida",
      MachinesInvalidDocuments: "Documentos inválidos de equipas de trabalho",
      MachinesDocuments: "Documentos de equipas de trabalho",
    },
    company: {
      InvalidCompaniesNotFound:
        "Não foram encontradas empresas com documentação inválida",
      CompanyInvalidDocuments: "Documentos inválidos da empresa",
      CompaniesDocuments: "Documentos da empresa",
    },
  },
  documents: {
    Uploaded: "Adicionado em",
    UploadedBy: "Adicionado por",
    InvalidatedBy: "invalidado por:",
    Reason: "Motivo:",
    ExpiredOn: "Expirou a",
    ExpiresOn: "Expirará a",
    TolerateTo: "tolerância até",
    Upload: "Adicionar",
    UploadModel: "Modelo",
    UploadDocumentTitle: "Cumprir requisito documental",
    Approved: "Documento aprovado",
    Denied: "Documento inferido",
    DocumentDeniedReason: "Motivo do indeferimento",
    Roles: "Funções",
    ProvideReason: "Especifique um motivo para o indeferimento",
    Saved: "Documento adicionado corretamente",
    ErrorSaved: "Erro ao ser adicionado o documento",
    FileNotFound: "Deve indicar um arquivo válido",
    ExtensionNotFound: "Deve indicar um arquivo com extensão válida",
    InForceNotFound: "Deve indicar a data em vigor",
    EndDateNotFound: "Deve indicar a data de caducidade",
    InvalidCompanyDocuments: "Documentos inválidos de empresa",
    Versions: "Versões",
    Files: "Arquivos",
    RequiredFields: "O nome e o ficheiro são obrigatórios",
    Critical: "CRÍTICO",
    Dates: "Prazo de validade",
    RequirementLabel: "Requisito",
    CriticalDoc: "Documento crítico",
    Review: "Ir para a revisão",
    Dashboard: "Ir para o painel de controlo",
    MaxSizeDocument: "O tamanho do documento é superior ao permitido",
    StartDate: "Data de início",
    EndDate: "Data de final",
    Origin: "Origem",
    OriginCOMPANY: "Modelo geral",
    OriginCONTRACTOR: "Modelo de empresa",
    OriginWORK_CENTER: "Modelo de centro de trabalho",
    OriginROLE: "Papel do trabalhador",
    OriginFUNCTION: "Papel da equipa de trabalho",
    OriginCONTRACT: "Modelo de contrato",
    ForceOverride: "Forçar invalidação",
    Watch: "Veja",
    viewer: {
      reduce: "reduzir",
      increase: "ampliar",
      reset: "redefinir o zoom",
      rotateLeft: "virar à esquerda",
      rotateRight: "virar à direita",
      adjust: "ajustar",
      download: "baixar",
      ItsNotPossiblePreviewThisFile: "Não é possível visualizar este arquivo.",
      YouMustDownloadTheFileToOpenIt:
        "Você deve baixar o arquivo para abri-lo.",
    },
  },
  chat: {
    Chat: "Chat",
    Comments: "Comentários",
    NewComment: "Escreva um novo comentário",
    Send: "Enviar",
  },
  companies: {
    Created: "Empresa criada corretamente",
    Updated: "Empresa atualizada",
    ObligatoryInfo: "O nome e NIF são obrigatórios",
    Companies: "Empresas",
    ClientCompanies: "Empresas cliente",
    Add: "Adicionar empresa",
    Edit: "Editar empresa",
    Delete: "Eliminar empresa",
    DeletedError:
      "Não foi possível eliminar a empresa. Existem informações relacionadas",
    SureDelete: "Tem a certeza que quer eliminar esta empresa?",
    NoAvailable: "Não existem empresas disponíveis",
    Assign: "Atribuir empresa",
    Saved: "Empresa atribuída corretamente",
    Select: "Selecione uma empresa",
    Deleted: "Empresa eliminada corretamente",
    NoAssigned: "Nenhuma empresa atribuída",
    Assigned: "Empresas atribuídas:",
    Token: "Envio de requisitos documentais",
    InvalidMailAddresses: "Endereços de email inválidos",
    InvalidMailAddressesText:
      "Os requisitos documentais não puderam ser enviados para os seguintes endereços de e-mail",
    SureToken:
      "Tem a certeza de que quer enviar todos os requisitos documentais para a empresa cliente?",
    TokenSent: "Link de consulta de requisitos enviado corretamente",
    Recipients: "Destinatários",
    InvalidMailAddress: "Os requisitos documentais não puderam ser enviados",
    RelatedCompanies: "Empresas coligadas",
  },
  contractor: {
    Assign: "Atribuir empreitada",
    Contractors: "Empreitadas",
    Saved: "Empreitada adicionada ao contrato corretamente",
    SelectCompany: "Selecione uma empresa",
    Delete: "Eliminar empreitada",
    Deleted: "Empreitada eliminada corretamente",
    noContractorsDefined:
      "Nenhum contratado foi definido para o contrato atual neste centro de trabalho",
    ShowDeactivatedCompanies: "Mostrar empresas desativadas",
    MoreOptions: "Mais opções",
    LockRoles: "Bloquear funções",
    Yes: "Sim",
    No: "Não",
    DisableContract: "Desativar contrato",
    SureDisableContract: "Tem certeza de que desabilitou este contrato?",
    ContractEnabledCorrectly: "Contrato ativado corretamente",
    ContractDisabledCorrectly: "Contrato desativado com sucesso",
    Contractor: {
      Contractors: "Subempreitadas",
      Assign: "Atribuir subempreitada",
      SureDelete:
        "Tem a certeza que deseja eliminar a atribuição da empreitada e os empregados ao contrato ativo?",
    },
  },
  contracts: {
    ContractsDisabledWarning:
      "Atenção!! Alguns contratos estão desativados (reveja-os na parte inferior da página)",
    ContractsDisabled: "Contratos desabilitados",
    Saved: "Contrato salvo com sucesso",
    SelectWorkCenter: "Selecione um centro de trabalho",
    SelectWorkCenterAndSubject: "Selecione um assunto e um centro de trabalho",
    SelectSubject: "Selecione um assunto",
    InForce: "Contratos em vigor",
    New: "Novo contrato",
    Edit: "Editar contrato",
    Delete: "Eliminar",
    AssignServices: "Atribuir modelos de contrato",
    SureDelete: "Tem a certeza que quer eliminar este contrato?",
    NoAvailable: "Nenhum contrato disponível",
    Deleted: "Contrato eliminado com êxito",
    WorkCenters: {
      Assign: "Atribua um centro de trabalho",
      Saved: "Centro de trabalho atribuído com êxito",
      SelectWorkCenter: "Selecione um centro de trabalho",
      Deleted: "Centro de trabalho eliminado com êxito",
      Min:
        "Um contrato deve ter obrigatoriamente um centro de trabalho, atribua um previamente ou elimine o contrato",
    },
    DocumentTypes: {
      Assign: "Adicionar requisito documental predefinido",
      Add: "Adicionar requisito documental",
      Edit: "Editar requisito documental",
      DocumentTypes: "Requisitos documentais",
      NoAvailable: "Não há nenhum requisito documental disponível",
      both: "Ambos",
      contract_of: "Empreiteiro",
      subcontract_of: "Subempreiteiro",
      Delete: "Eliminar o tipo de documento",
      Restore: "Restaurar o tipo de documento",
      SureDelete: "Tem a certeza que quer eliminar este requisito documental?",
      SureRestore:
        "Tem a certeza que quer restaurar este requisito documental com as condições gerais?",
      Worker: "Requisitos documentais de trabalhador",
      Company: "Requisitos documentais de empresa",
      Machine: "Requisitos documentais de equipa de trabalho",
      Saved: "Requisito documental atribuído corretamente",
      Assigned: "Requisitos documentais atribuídos",
      Select: "Selecione um requisito documental",
      Deleted: "Requisito documental eliminado corretamente",
    },
    Companies: {
      AssignSub: "Atribuir subempreitada",
      AssignableSub: "Subempreitadas atribuídas",
      Subcontractors: "Subempreitadas:",
    },
    Workers: {
      Deleted: "Trabalhador eliminado do contrato com êxito",
      FullyDisabledTitle: "Trabalhador desativado de forma global",
      FullyDisabledWarning:
        "Este trabalhador está desativado de forma global, não apenas no contrato. Se desejar ativá-lo, deverá ativá-lo a partir da lista de trabalhadores",
      ShowDisabledWorkers: "Mostrar trabalhadores desativados",
      deactivate: "Desativar",
      activate: "Ativar",
    },
    Machines: {
      Deleted: "Equipa de trabalho eliminada do contrato com êxito",
    },
  },
  review: {
    Pending: "Revisão pendente de documento",
    WorkerDocuments: "Documentos de trabalhadores",
    CompanyDocuments: "Documentos de empresa",
    MachineDocuments: "Documentos de equipas de trabalho",
    endDateBeforeInForceDate: "A data final é anterior à data de início",
    MaxDocumentsShown:
      "Atenção! O número máximo de documentos visíveis foi atingido. Apenas os primeiros 1000 serão exibidos.",
  },
  read: {
    Add: "Adicionar documento",
    Edit: "Editar documento",
    Documents: "Informações a ler",
    Delete: "Eliminar documento",
    SureDelete: "Tem a certeza que quer eliminar este documento?",
    Uploaded: "Documento adicionado corretamente",
    ErrorUpload: "Erro ao ser adicionado o documento",
    Company: "Documentos de empresa",
    WorkCenter: "Documentos de centro de trabalho",
    WorkCenterRequired: "É necessário indicar um centro de trabalho",
  },
  services: {
    Saved: "Modelo atribuído corretamente",
    NoSelection: "Nenhum modelo foi selecionado.",
    Deleted: "Modelo eliminado corretamente",
    NoAvailable: "Não há modelos disponíveis para a atribuição",
    NoAssigned: "Não há modelos de contrato atribuídos",
    Assigned: "Modelos de contrato atribuídos:",
  },
  workcenters: {
    Add: "Adicionar centro de trabalho",
    Edit: "Editar centro de trabalho",
    Delete: "Eliminar centro de trabalho",
    WorkCenters: "Centros de trabalho",
    Saved: "Centro de trabalho criado com êxito",
    Updated: "Centro de trabalho atualizado com êxito",
    ObligatoryInfo:
      "Introduza uma denominação, endereço e código postal válidos",
    AssignTemplate: "Atribuir modelo ao centro de trabalho",
    DeleteAssignSure:
      "Tem a certeza que quer eliminar esta atribuição de centro de trabalho?",
    DeleteAssign: "Eliminar a atribuição de centro de trabalho",
    Deleted: "Centro de trabalho eliminado com êxito",
    SureDelete: "Tem a certeza que quer eliminar este centro de trabalho?",
    NoSelect: "Nenhum centro de trabalho selecionado",
    NoAvailable: "Nenhum centro de trabalho disponível",
    ContractAccess: "Acesse o contrato",
    WorkCentersClosed: "Centros de trabalho fechados",
    WorkCenterClosedWarning:
      "Atenção! Alguns centros de trabalho estão fechados (verifique-os na parte inferior da página)",
    ClosedOn: "Fechado em: ",
    CloseOn: "Fecha em: ",
  },
  workers: {
    Saved: "Trabalhador criado com êxito",
    ObligatoryInfo: "O nome, primeiro apelido, NIF e e-mail são obrigatórios",
    Workers: "Trabalhadores",
    WorkersDisabled: "Trabalhadores desativados",
    WorkersDisabledWarning:
      "Atenção! Alguns trabalhadores estão desativados (reveja-os na parte inferior da página)",
    Assign: "Atribuir trabalhador",
    Save: "Trabalhador atribuído com êxito",
    Select: "Selecione um trabalhador",
    Add: "Atribuir trabalhador",
    AddFromITA: "Adicionar trabalhadores desde ITA",
    Delete: "Eliminar trabalhador",
    SureDelete: "Tem a certeza que quer eliminar este trabalhador?",
    Deleted: "Trabalhador eliminado com êxito",
    Roles: "Funções",
    WorkerRoles: "Funções do trabalhador",
    NoAvailable: "Nenhum trabalhador disponível",
  },
  templates: {
    Add: "Adicionar modelo",
    Assigned: "Modelos atribuídos:",
    AssignTemplates: "Atribuir modelos",
    NoAssigned: "Não há modelos atribuídos",
    Saved: "Modelo atribuído corretamente",
    Delete: "Eliminar modelo",
    NoAvailable: "Não há modelos disponíveis para a atribuição",
    Deleted: "Modelo eliminado corretamente",
    SureDelete: "Tem a certeza que quer eliminar este modelo?",
    NoSelection: "Nenhum modelo foi selecionado.",
    Company: "Modelos gerais",
    WorkCenter: "Modelos de centros de trabalho",
    Contract: "Modelos de contrato",
    Contractor: "Modelos de empresa",
    Edit: "Editar modelo",
  },
  roles: {
    Add: "Adicionar função",
    Edit: "Editar função",
    NewAssigned: "Função atribuída com êxito",
    Saved: "Função criada com êxito",
    Updated: "Função atualizada com êxito",
    Duplicated: "Função duplicada com êxito",
    Select: "Selecione uma função",
    Deleted: "Função eliminada com êxito",
    NoAvailable: "Nenhuma função disponível",
    Assigned: "Funções:",
    Delete: "Eliminar função",
    SureDelete: "Tem a certeza que quer eliminar esta função?",
    ObligatoryInfo: "Insira um nome válido",
    Assign: "Adicionar função predefinida",
    RolesDefinedIndustrialProperty:
      "Funções definidos pela propriedade industrial",
    PredefinedRoles: "Funções pré-definidas",
    WorkerNoAssignedRole: "Este trabalhador não tem função atribuída",
    AtLeastOneRole: "Deve haver pelo menos uma função para esse trabalhador",
    documentTypes: {
      DocumentTypes: "Requisitos documentais",
      Assigned: "Requisitos documentais atribuídos:",
      NoAvailable: "Não existem requisitos documentais",
      Available: "Requisitos documentais",
      Saved: "Requisito documental atribuído com êxito",
      Select: "Selecione um requisito documental",
      Deleted: "Requisito documental eliminado com êxito",
    },
  },
  functions: {
    Add: "Adicionar função de equipa",
    Edit: "Editar função de equipa",
    NewAssigned: "Função de equipa atribuída com êxito",
    Saved: "Função de equipa criada com êxito",
    Updated: "Função de equipa atualizada com êxito",
    Duplicated: "Função de equipa duplicada com êxito",
    Select: "Selecione uma função de equipa",
    Deleted: "Função de equipa eliminada com êxito",
    NoAvailable: "Nenhuma função de equipa disponível",
    Assigned: "Funções de equipa:",
    Delete: "Eliminar função de equipa",
    SureDelete: "Tem a certeza que quer eliminar esta função de equipa?",
    ObligatoryInfo: "Insira um nome válido",
    Assign: "Adicionar função de equipa predefinida",
    MachineNoAssignedRole: "Esta equipe de trabalho não tem função atribuída",
    documentTypes: {
      DocumentTypes: "Requisitos documentais",
      Assigned: "Requisitos documentais atribuídos:",
      NoAvailable: "Não existem requisitos documentais",
      Available: "Requisitos documentais",
      Saved: "Requisito documental atribuído com êxito",
      Select: "Selecione um requisito documental",
      Deleted: "Requisito documental eliminado com êxito",
    },
  },
  versions: {
    Versions: "Versões",
  },
  label: {
    Actions: "Ações",
    Subject: "Assunto:",
    WorkCenter: "Centro de trabalho",
    Worker: "Trabalhador:",
    WorkerLower: "trabalhador",
    WorkersLower: "trabalhadores",
    Machine: "Equipa de trabalho:",
    MachineLower: "equipa de trabalho",
    MachinesLower: "equipas de trabalho",
    Company: "Empresa:",
    CompanyContractor: "Empresa cliente:",
    Name: "Nome:",
    ValidDays: "Leitura obrigatória antes de",
    ValidDaysPost: " dias",
    Type: "Tipo:",
    Destiny: "Destino:",
    Login: "Login:",
    Mail: "E-mail:",
    Surname1: "Apelido 1:",
    Surname2: "Apelido 2:",
    CIF: "NIF:",
    ResponsableCAE: "E-mail responsável CAE:",
    Contact: "Contato:",
    NIF: "NIF/NIE",
    Password: "Password:",
    InForceDate: "Data de início",
    EndDate: "Data de término",
    Expire: "Caduca:",
    CanExpire: "Este requisito documental tem caducidade?",
    Expiration: "Caducidade:",
    Months: "meses",
    Days: "dias",
    ExpirationMode: "Modo:",
    Address: "Endereço:",
    Phone: "Telefone:",
    SearchPlaceHolder: "Expressão de pesquisa",
    File: "Arquivo:",
    Critical: "Crítico:",
    ZipCode: "C.P.:",
    Population: "Cidade:",
    Province: "Província:",
    Close: "Encerramento",
    Contract: "Contrato",
    Contracts: "Contratos:",
    Contractor: "Empreitada",
    DocumentTypesAvailable: "Tipos de documentos a atribuir:",
    RolesAvailable: "Funções a atribuir:",
    ReviewCriteria: "Observações para o validador",
    ContractorCriteria: "Observações para a empreitada",
    CompaniesAvailable: "Empresas a atribuir:",
    ServicesAvailable: "Modelos a atribuir:",
    TemplatesAvailable: "Modelos a atribuir:",
    Applicant: "Solicitante:",
    Picture: "Fotografia:",
    Profile: "Perfis disponíveis:",
    ADMINISTRADOR_Description:
      "Permite aceder a todas as opções da propriedade industrial e do empreiteiro",
    CONTRATISTA_Description:
      "Permite corrigir requisitos, gerir contratos e definir trabalhadores e equipas de trabalho",
    VALIDADOR_Description:
      "Permite rever e validar a documentação proporcionada pelas empreitadas",
    Logo: "Logo:",
    Disabled: "Desativado",
    Roles: "Funções",
    Role: "Função:",
    Bia360: "Cliente:",
    PI: "Empresa cliente:",
    AutomaticType: "Tipo automático:",
    Reference: "Referência:",
    ShowDisbledWorker: "Mostrar trabalhadores desativados",
    MultipleRecipients: "Corrigir em todos os candidatos",
    Autonomo: "Trabalhador independente:",
    Tolerate: "Tolerância:",
    StillValidUntil: "Válido até:",
    MandatoryWorkerRoles: "Atribuição obrigatória de funções aos trabalhadores",
    ExamineContracts:
      "Para examinar a lista de contratos atribuídos, é necessário clicar em cada um dos elementos que aparecem colapsados abaixo:",
    ExamineWorkers:
      "Para examinar a lista de trabalhadores designados, é necessário clicar em cada um dos elementos que aparecem colapsados abaixo:",
    ExamineMachines:
      "Para examinar a lista de equipes de trabalho atribuídas, é necessário clicar em cada um dos elementos que aparecem abaixo:",
    Activate: "Ativar",
    Deactivate: "Desativar",
    MonthlyExpiration: "Expiração mensal:",
    Day1EachMonth: "Dia 1 de cada mês",
    FromDateIndicated: "A partir da data indicada",
    Language: "Linguagem:",
  },
  expireMode: {
    natural: "Mês de calendário",
    encurso: "Mês em curso",
    fecha: "Data de término",
  },
  automaticType: {
    none: "Não é do tipo automático:",
    ita: "ITA",
    tc2: "TC2",
    rnt: "RNT",
    ss: "Certificado corrente de pagamento S.S.",
    aeat: "Certificado corrente de pagamento AEAT",
  },
  buttons: {
    Add: "Adicionar",
    Create: "Criar",
    Delete: "Eliminar",
    Restore: "Restaurar",
    Edit: "Editar",
    Assign: "Atribuir",
    Deny: "Indeferir",
    Approve: "Aprovar",
    Ok: "Aceitar",
    Cancel: "Cancelar",
    Save: "Salvar",
    Token: "Enviar requisitos documentais",
    Credentials: "Enviar credenciais acesso",
    Send: "Enviar",
    Duplicate: "Duplicar",
    Roles: "Funções",
    unlink: "Desvincular",
    ModeAction: {
      expanded: "Colapsar tudo",
      collapsed: "Expandir tudo",
    },
    Previous: "Anterior",
    Next: "Próximo",
  },
  placeholders: {
    Name: "Nome",
    SearchContractAndWorkCenter:
      "Pesquisa por nome de contrato ou centro de trabalho",
    SearchNameCIF: "Pesquisa por nome da empresa ou NIF",
    SearchNameSurnameDNI: "Pesquisa por trabalhador ou equipa de trabalho",
    SearchNameSurnameDNIRol: "Pesquisa por nome, apelidos, CC ou função",
    SearchMachineNameReference: "Pesquisa por nome ou referência",
    SearchName: "Pesquisa por nome",
    SearchContractorsAndSubContractors:
      "Pesquisa por nome das empreitadas e subempreitadas",
    SearchUserMailCompany: "Pesquise por nome de usuário, email ou empresa",
    SearchNameAndDocumentType:
      "Pesquisa por nome da função e tipo de documento",
    SearchNameAndDocumentTypeFunction:
      "Pesquisa por nome da função da equipa e tipo de documento",
    SearchNameReference: "Pesquisa por nome da empresa ou referência",
    SearchWorkerCompanyNameCIF:
      "Pesquisa por nome do trabalhador, empresa ou NIF",
    SearchWorkerMachineCompanyName:
      "Pesquisa por trabalhador, equipa de trabalho ou empresa",
    SearchCompanyContractWorkcenterContract:
      "Pesquisa por empresa, contrato ou centro de trabalho",
  },
  status: {
    Invalid: "documentos inválidos",
    Allowed: "Permitido",
    NotAllowed: "Não permitido",
  },
  pendingReading: {
    Warning: "Atenção",
    title: "Tem informação pendente de ler",
    description:
      "Para poder continuar a gerir a sua documentação é necessário que leia e perceba as seguintes informações:",
    Download: "Fazer o download",
    confirmationTitle: "Confirmação de leitura do documento",
    confirmationButton: "Confirmo a leitura do documento",
    confirmationDescription:
      "Confirma a leitura e aceitação do presente documento?",
    mandatoryReading: "Documentação de leitura obrigatória",
    mandatoryReadingAdvice:
      "Para poder continuar a gerir a sua documentação é necessário que leia e perceba as seguintes informações",
    readingInNextDays: "Documentação para ler nas próximas datas",
    readingInNextDaysAdvice:
      "A leitura das seguintes informações não bloqueia o acesso a Bia360 até que expire o prazo definido",
    deadline: "Prazo de leitura",
    days: "dias",
    pendingReadingAdvice: "Atenção: Tem informação pendente de ler",
    delayReading: "Ler documentação mais tarde",
    readingConfirmationOne: "Confirmação de leitura do documento",
    readingConfirmationByMe: "Confirmo a leitura do documento",
    readingConfirmationTwo:
      "Confirma a leitura e aceitação do presente documento?",
  },
  documentTypes: {
    nameRequired: "O nome é obrigatório",
    expiredRequired:
      "Caso indique a forma de caducidade em curso deve inserir o número de meses (valor superior ou igual a 1)",
    destinyRequired: "Deve marcar minimamente um destino",
    models: "Modelos de exemplo",
    Saved: "Tipo de documento guardado com êxito",
    Deleted: "Tipo de documento eliminado com êxito",
    Restored: "Os valores foram restaurados por defeito",
    assign: "Atribuir",
    alreadyAssignedTitle: "Requisitos em mais modelos",
    alreadyAssignedWarning:
      "Os seguintes requisitos já foram atribuídos a outro modelo. Deseja atribuí-los a isso de qualquer maneira?:",
  },
  tag: {
    ok: "VÁLIDO",
    valid: "VÁLIDO",
    expired: "EXPIRADO",
    incorrect: "REJEITADO",
    invalid: "INVÁLIDOS",
    read: "POR LER",
    review: "EM REVISÃO",
    reviewok: "EM REVISÃO",
    empty: "SEM CONTEÚDO",
    client: "EMPRESA CLIENTE",
    tolerate: "TOLERÂNCIA",
    selfEmployed: "Autônomo",
  },
  warnings: {
    deny:
      "Tem a certeza que quer indeferir o requisito documental sem indicar o motivo?",
    denyFirstChunk: "Tem a certeza que quer",
    denyLastChunk: "o requisito documental sem indicar o motivo?",
  },
  dates: {
    select: "Inserir data",
  },
  texts: {
    createdBy: "criado por",
    disassociateCompany: "Desvincular empresa",
    disassociate: "Desvincular",
    disassociateConfirm: "Tem a certeza que quer desvincular esta empresa?",
    noAffectedContracts:
      "Os contratos aos quais esteja atribuída atualmente não serão afetados",
    linked: "vinculada",
    pending: "pendente",
    contractLinkAuthByMeChunk:
      "às quais deu autorização para que sejam registadas nos seus contratos",
    companiesAlready360customers: "Empresas que são seus clientes de Bia360 e",
    contractLinkAuthToMeChunk:
      "que deram a sua permissão para poderem ser utilizadas em contratos",
    lastLog: "Último registo",
    fullAccessLog: "Registo completo de acesso",
    searchAccessAdvice:
      "Utilize o campo de pesquisa para consultar o acesso dos trabalhadores e equipas de trabalho.",
    documentDenied: "Documento rejeitado",
    applicantLabel: "Solicitado por",
    applicantLabelLower: "solicitado por",
    documentVersions: "Versões do requisito",
    uploadedBy: "Carregado por",
    document: "documento",
    documents: "documentos",
    close: "Fechar",
    updatedData: "Dados atualizados",
    machineFunction: "Tipo de equipa",
    showDisabledContracts: "Mostrar contratos desativados",
    contractorMaxs: "Máximos por empreitada",
    accessToRequirement: "Aceder ao requisito",
    processedDocuments: "Documentos processados",
    loading: "a carregar...",
    property: "Propriedade industrial",
    propertyAcronym: "PI",
    reviewAndValidation: "Revisão e validação de requisitos",
    hideRejected: "Ocultar os requisitos rejeitados",
    haveProcessed: "Foram processados",
    accept: "Aceitar",
    warning: "Aviso",
    passwordPlaceHolder: "Somente se o PDF carregado exigir uma senha",
    selectWorkCenter: "Selecione um centro de trabalho",
    by: "por",
    from: "do",
    to: "para",
  },
  linked: {
    requestLinKChunk: "Solicitar vinculação de",
    propertyClient: "empresa cliente",
    contractor: "empreitada",
    canCreateContractToMeChunk:
      "poderá registá-lo nos seus contratos e solicitar-lhe assim a documentação necessária.",
    canCreateContractByMeChunk:
      "poderá registá-la em seus contratos e requerer-lhe documentação.",
    existentClientWarning:
      "Atenção: A empresa que quer registar já é cliente Bia360",
    linkExplanationStarterChunk:
      "Pode trabalhar com esta, enviando-lhe um pedido. Assim que a empresa aprovar o seu pedido,",
    linkQuestion: "Deseja solicitar a vinculação com esta empresa?",
    agree: "Sim",
  },
  profiles: {
    warning: "AVISO",
    defaultProfilesWarningLine1:
      "Caso não se selecione algum perfil, o utilizador terá acesso à consulta do controlo de acesso",
    defaultProfilesWarningLine2: "e às informações para empreiteiros.",
    administrator: "ADMINISTRADOR",
    contractor: "CONTRATANTE",
    validator: "VALIDADOR",
  },
  icons: {
    accessControl: "Ícone controlo de acesso",
    dashboard: "Ícone painel de controlo",
    configuration: "Ícone configuração",
    documentation: "Ícone documentação",
    adminstration: "Ícone administração",
    contracts: "Ícone contratos",
    contractor: "Ícone empreiteiro",
    readings: "Ícone documentação a ler",
    contact: "Ícone contacto",
  },
  inPlant: {
    resources: "Recursos na instalação",
    noResourcesInPlant:
      "Atualmente não há nenhum trabalhador ou equipa de trabalho na instalação",
  },
  temporaryAuthorizations: {
    authorizations: "Autorizações temporárias",
    noTemporaryAuthorizations: "Atualmente não há autorização temporária",
    new: "Nova autorização",
    newAuthorization: "Nova autorização temporária",
    startDate: "Data de início",
    endDate: "Data final",
    reason: "Motivo da autorização temporária",
    startAuthorization: "Início",
    endAuthorization: "Final",
    autocomplete: "Digite o nome ou CIF do trabalhador",
    delete: "Excluir autorização",
    sureDelete: "Tem certeza de que deseja excluir esta autorização?",
    deletedAuthorization: "Autorização excluída",
    errorDeleting: "Erro ao excluir a autorização",
  },
  accesses: {
    registerEntrance: "Registar entrada",
    registerExit: "Registar saída",
    registeredEntrance: "Entrada registada",
    registerdExit: "Saída registada",
    noLogsFound:
      "Não foram encontrados registos de acessos de trabalhadores e equipas de trabalho com os atuais critérios.",
    access: "Acesso",
    entity: "Trabalhador/Equipa de trabalho",
    company: "Empresa",
    loggedBy: "Registado por",
    dates: "Datas",
    logTitle: "Registo de acessos de trabalhadores e equipas de trabalho",
    entrance: "Entradas",
    exit: "Saídas",
    duplicateEntranceWarning:
      "Este trabalhador mostra que ele já entrou nas últimas 24 horas e não há provas de que ele saiu. É correto registrar sua entrada novamente?",
    duplicatedEntrance: "Entrada duplicada",
    exportToExcel: "Exportar para Excel",
    onlyWithoutDocumentationPresented: "Entradas não ok",
    workerDocuments: "Documentos do trabalhador",
    machineDocuments: "Documentos da equipe de trabalho",
  },
  contact: {
    helpQuestion: "Necessita de ajuda?",
    contactWithUs:
      "Contacte-nos e a equipa CAE de Bia360 irá resolver todas as suas dúvidas",
    contactInformation: "Estes são os nossos dados de contato",
    email: "E-mail",
    phone: "Telefone",
  },
  rolesblock: {
    blockExplanationFirstParagraf:
      "Por defeito, o empreiteiro pode atribuir funções aos seus trabalhadores.",
    blockExplanationSecondtParagraf:
      "É possível bloquear esta funcionalidade e que as funções dos trabalhadores da empreitada só possam ser geridas a partir da propriedade industrial.",
    allowManagment: "Permitir a gestão das funções empreiteiro",
  },
  assignment: {
    unassignWorker: "Cancelar trabalhador",
    unassign: "Cancelar",
    confirmUnassigment:
      "Tem a certeza que quer retirar este trabalhador do contrato?",
    unassignmentAdviceFirstChunk: "Será retirado o trabalhador do contrato",
    unassignmentAdviceSecondChunk: "e a sua documentação será eliminada",
    unassignmentAdviceThirdChunk: "relativa a este contrato",
  },
  readings: {
    loading: "A carregar visualizações...",
    noCompanies: "Não há empresas que devam ler este documento",
    visualizations: "Visualizações",
    unread: "Não lido",
    read: "Leia",
    on: "em",
  },
  expirableDocuments: {
    filterAdvice:
      "Apenas está a ver os requisitos de um tipo de documento concreto.",
    showAll: "Ver todos",
    loading: "A carregar documentos...",
    noExpirableDocuments: "Não há documentos próximos a expirar",
    title: "Documentos próximos a expirar",
  },
  newfeatures: {
    title: "Novidades",
    read: "Visto",
    advice: "Novidades!",
  },
  stats: {
    title: "Estatísticas de utilização de Bia360",
    documentValidations: "Validações de documentos",
    validateOrRejected:
      "Documentos validados ou rejeitados durante o processo de revisão",
    validatedByPI: "Validado pela propriedade industrial",
    validatedByBia360: "Validado pela equipa de Bia360",
  },
  auth: {
    passwordDontMatch: "As passwords não coincidem",
    passwordChanged: "Password alterada com sucesso",
    userData: "Dados do utilizador",
    userName: "Nome de utilizador",
    email: "E-mail",
    password: "Password",
    changePassword: "Alterar password",
    passwordChange: "Alteração de password",
    save: "Guardar",
    newPassword: "Nova password",
    repeatPassword: "Repetir password",
    logout: "Desligar",
    principalCompany: "Empresa principal",
    selectCompany: "Nenhuma empresa selecionada ...",
  },
  token: {
    tokenExpiredAdvice:
      "O endereço de acesso fornecido expirou. Por favor, peça ao seu empreiteiro para gerar um novo.",
    requirementsGeneratedFor: "Requisitos documentais gerados para",
    exportedDocumentFor: "Documento exportado por",
    withCIF: "com NIF",
    correctUntil: "Válido até",
    activeCredentials: "ATIVO",
    inactiveCredentials: "INATIVO",
    sentOn: "Enviou o",
    numberOfRecipients: "Número de destinatários",
    noShipmentDocumentation: "Nenhum envio de documentação foi feito",
    noShipment: "Nenhuma remessa foi feita",
  },
  assignedWorkers: {
    allContractsAssigned:
      "Todos os contratos disponíveis já foram atribuídos para este trabalhador",
    noContractAssigned:
      "Não foi atribuído nenhum contrato para este trabalhador",
    blockedAdvice:
      "Esta opção está limitada pela propriedade industrial, por favor, entre em contato com esta em caso de dúvidas",
    unassignWorker: "Cancelar trabalhador",
    unassign: "Cancelar",
    correctAssignment: "Trabalhador atribuído com êxito",
    maxWorkersReached:
      "A atribuição supera o limite de trabalhadores nos seguintes contratos",
    assignToAllContracts: "Atribuir a todos os contratos",
    unassignedOK: "Trabalhador eliminado do contrato com êxito",
    warning:
      "Atenção! Alguns contratos foram desativados pela propriedade industrial e não permitem o upload de documentos:",
    thereIsContractWithMandatoryRoles:
      "Para qualquer um dos contratos, é obrigatório especificar uma função. O trabalhador não será atribuído a nenhum contrato",
  },
  denyReasons: {
    specifyReasonFisrtChunk: "Especifique um motivo para",
    denyArticle: "o",
    denyName: "indeferimento",
    denyVerb: "indeferir",
    invalidateArticle: "a",
    invalidateName: "invalidação",
    invalidateVerb: "invalidar",
  },
  "rc-calendar": {
    today: "Hoje",
    now: "Agora",
    backToToday: "Voltar para hoje",
    ok: "Aceitar",
    clear: "Limpar",
    month: "Mês",
    year: "Ano",
    timeSelect: "Selecionar hora",
    dateSelect: "Selecionar data",
    monthSelect: "Escolha um mês",
    yearSelect: "Escolha um ano",
    decadeSelect: "Escolha uma década",
    yearFormat: "YYYY",
    dateFormat: "DD/MM/YYYY",
    dayFormat: "DD",
    dateTimeFormat: "DD/MM/YYYY HH:mm:ss",
    monthFormat: "MMMM",
    monthBeforeYear: true,
    previousMonth: "Mês anterior (PageUp)",
    nextMonth: "Mês que vem (PageDown)",
    previousYear: "Ano anterior (Control + left)",
    nextYear: "Ano que vem (Control + right)",
    previousDecade: "Década anterior",
    nextDecade: "Próxima década",
    previousCentury: "Século anterior",
    nextCentury: "Século seguinte",
  },
  dataagreement: {
    dataAccess:
      "Aceitação de condições Regulamento Geral de Proteção de Dados (RGPD)",
    continue: "Continuar",
    explanation:
      "Para exercer o papel de responsável pelo processamento de dados, o Bia360 requer as seguintes informações para gerar o contrato de tratamento.",
    agreementNeeded:
      "Sua aceitação é necessária para continuar prestando o serviço.",
    representativeFullName: "Representante Legal (nome completo)",
    representativeDNI: "DNI do representante legal",
    companyAddress: "Direção da empresa",
    accept: "Eu aceito",
    pdfDownload: "Baixar PDF",
  },
  search: {
    areMoreResults: "Mostrando apenas 10 resultados",
  },
  requirement_names: {
    "1193": "Designação de Recurso Preventivo",
    "1197": "Ata constituição Serviço Prevenção Próprio",
    "1204": "Livro de instruções",
    "1205": "Documento automóvel",
    "2430": "Formação específica em Segurança Rodoviária",
    "2960": "Formação sobre riscos químicos no centro de trabalho",
    "4481": "Informação segundo RD396/2006 (exposição ao amianto)",
    "9700": "Plano de Segurança e Saúde (PSS)",
    "1198": "Certificado de Auditoria Regulamentar SPP",
    "12900": "Designação Coordenador de Segurança e Saúde",
    "4590774":
      "Formação 2o Ciclo. Instalador de elementos de carpintaria e móvel",
    "12905": "Certificado de formação/capacitação para trabalhos de soldadura",
    "4590776": "Formação em montagem de Redes de Segurança",
    "12909":
      "Informação sobre riscos e medidas identificados no Plano de Segurança e Saúde",
    "4482":
      "Plano de trabalho para operações de retirada ou manutenção de elementos como o amianto",
    "12910":
      "Formação sobre riscos identificados no Plano de Segurança e Saúde",
    "3312270": "Registo na Segurança Social (TA2)",
    "4484": "Formação como Técnico de Prevenção de Riscos Laborais",
    "4485": "Formação como Coordenador de Segurança e Saúde",
    "12903": "Carta operador de grua",
    "12906": "Formação montagem de andaimes",
    "12913": "Certificado de revisão obrigatória por parte da OCA",
    "12915": "Especificações técnicas equipamentos ATEX",
    "15606": "Certificado homologação cabine descontaminação fibrocimento",
    "1209": "Informe de Trabalhadores Registados (ITA)",
    "15594": "Formação Convênio 2o ciclo. Aço para betão armado",
    "4590777": "Formação manejo Bomba de Betão",
    "4483": "Inscrição no RERA (Registo de Empresas com Risco de Amianto)",
    "15607": "Ata de aprovação do Plano de Segurança e Saúde",
    "15608": "Abertura do Centro de trabalho",
    "15595": "Formação Convênio 2o ciclo. Cofragem",
    "3453202": "Formação Convênio Metal. Trabalhos de forja.",
    "15609": "Livro de incidências",
    "4349202": "Plano de resgate em espaços confinados",
    "15611": "Livro de Subcontratação",
    "15612": "Adesão ao Plano de Segurança e Saúde (PSS)",
    "15613": "Designação de Recurso Preventivo",
    "15614": "Autorização Gestores/Transportadores de resíduos",
    "15615": "Documento aceitação de resíduos",
    "15616":
      "Registo de empresas instaladoras/mantenedoras de sistemas de proteção contra incêndios (RECI)",
    "15617": "Registo de estabelecimentos e serviços biocidas (ROESB)",
    "15618": "Registo de estabelecimentos e serviços pesticidas (ROESP)",
    "15619": "Procedimento de trabalho específico",
    "15603": "Formação comandos intermédios 20h (Convênio)",
    "15604":
      "Formação responsáveis de obra e técnicos de execução 20h (Convênio)",
    "1098": "Certificado aptidão médica para o posto de trabalho desempenhado",
    "15592": "Formação Convênio 2o ciclo. Isolamento e impermeabilização",
    "15593":
      "Formação Convênio 2o ciclo. Cimentações especiais, sondagens e perfurações",
    "15597": "Formação Convênio 2o ciclo. Pintura",
    "15598": "Formação Convênio 2o ciclo. Demolição e reabilitação",
    "15599":
      "Formação Convênio 2o ciclo. Redes de abastecimento e saneamento, desentupimento",
    "15600": "Formação Convênio 2o ciclo. Operadores de equipamentos manuais",
    "15601": "Formação Convênio 2o ciclo. Montagem de pré-fabricados de betão",
    "15602":
      "Formação Convênio 2o ciclo. Colocação materiais de recobrimento (cobertas , etc)",
    "330800": "Cartão de transporte ADR",
    "330801": "Certificado de aprovação ARD do veículo",
    "330802": "Certificado de inspeção periódica do veículo ADR",
    "3453203":
      "Formação Convênio Metal. Operários de trabalhos de soldadura e oxicorte",
    "307830":
      "TA.2/S - Pedido de registo na Segurança Social do trabalhador assalariado ou assimilado (só ETT)",
    "3312271": "Formação 2o Ciclo. Ofícios",
    "12901": "Carta de condução",
    "3453208":
      "Formação Convênio Metal. Operários em atividades de pré-montagem, montagem, mudança de formato e montagem em fábricas",
    "3116640": "TC2/RNT (Relação Nominal Trabalhadores)",
    "4349200": "Certificado ISO 14001:2015 (Sistema de gestão ambiental)",
    "4349203": "Instruções de entrada em espaços confinados",
    "4480": "Formação em trabalhos com amianto/fibrocimento",
    "3453209": "Formação Convênio Metal. Trabalhos de carpintaria metálica",
    "3453470": "Formação Convênio Metal. Operários de trabalhos em joalheria.",
    "3453200":
      "Formação Convênio Metal. Trabalhos de fabricação, produção e transformação do ferro, do aço e dos materiais não férreos, e primeira transformação",
    "3453201":
      "Formação Convênio Metal. Trabalhos de construção, reparação e manutenção naval em estaleiros e cais",
    "3453471":
      "Formação Convênio Metal. Trabalhos de mecânica, manutenção e reparação de máquinas, equipamentos industriais e/ou equipamentos eletromecânicos.",
    "3453204":
      "Formação Convênio Metal. Operários de máquinas de mecanizado por arranque de cavaco.",
    "3453205":
      "Formação Convênio Metal. Operários de máquinas de mecanizado por abrasão.",
    "3453206":
      "Formação Convênio Metal. Operários de máquinas de mecanizado por deformação e corte do metal.",
    "3453207":
      "Formação Convênio Metal. Operários em atividades de tratamentos superficiais das peças do metal: desengordurado, limpeza, decapagem, cobertura, pintura.",
    "3453472":
      "Formação Convênio Metal. Trabalhos em oficinas de reparação de veículos.",
    "3453473":
      "Formação Convênio Metal. Trabalhos de instalação, manutenção e reparação de equipamentos informáticos, automatismos e sua programação, computadores e seus periféricos ou dispositivos auxiliares; equipamentos e tecnologias de telecomunicações e da informação, redes de informação e dados (TICs)",
    "3453474":
      "Formação Convênio Metal. Instaladores e reparadores de linhas e equipamentos elétricos.",
    "3453475":
      "Formação Convênio Metal. Trabalhos de canalização, instalações de calefação-climatização, instalações de água quente sanitária e instalações solares térmicas.",
    "3453476":
      "Formação Convênio Metal. Trabalhos de manutenção, reparação e instalação de elevadores.",
    "3453477":
      "Formação Convênio Metal. Trabalhos de isolamento e impermeabilização.",
    "3453478":
      "Formação Convênio Metal. Trabalhos de montagem de estruturas tubulares.",
    "12904": "Cartão de aplicador de pesticidas/biocidas",
    "3453479":
      "Formação Convênio Metal. Trabalhos de construção e manutenção de vias férreas.",
    "3453530":
      "Formação Convênio Metal. Trabalhos de instalações, manutenção e reparação de infraestruturas de telecomunicações (TIC e digitalização).",
    "3453531":
      "Formação Convênio Metal. Trabalhos em gasodutos e redes de distribuição de gases combustíveis.",
    "3453532":
      "Formação Convênio Metal. Trabalhos de outro tipo de instalações tais como instalações solares fotovoltaicas ou instalações eólicas.",
    "3453533":
      "Formação Convênio Metal. Trabalhos de recuperação e reciclagem de matérias-primas secundárias metálicas.",
    "3453534":
      "Formação Convênio Metal. Trabalhos de controle de qualidade, verificação e inspeção de matérias em curso de fabricação e em produtos terminados do setor.",
    "12897": "Certificado eletricista qualificado segundo RD614/2001",
    "1202": "Seguro de Responsabilidade Civil. Comprovativo de pagamento",
    "3480860": "CAP",
    "1211": "Formação Convênio 2o ciclo. Operador aparelhos elevadores",
    "1203": "Declaração de conformidade (Marcado CE) ou adequação à RD1215",
    "4590772":
      "Formação Convênio 2o ciclo. Estabilização planícies e ampliação de asfaltos",
    "3453535": "Formação Convênio Metal. Condutores/transportadores",
    "3453536": "Formação Convênio Metal. Condutores de empilhadoras",
    "3453537": "Formação Convênio Metal. Operadores de grua-ponte.",
    "3453538":
      "Formação Convênio Metal. Operadores de plataformas elevatórias.",
    "3453539":
      "Formação Convênio Metal. Operários de depósito e logística e abastecimento nos processos de fabricação (incluindo embalagem, preparação de produtos, reprocessamento, com a ajuda ou não de elementos mecânicos e outras tarefas de abastecimento e fornecimento de materiais e componentes).",
    "3453590":
      "Formação Convênio Metal. Condutores de gruas móveis autopropulsionadas",
    "15590": "Formação manejo maquinaria movimento de terras",
    "12907":
      "Formação em tratamentos higiênico-sanitários em instalações de risco frente à doenças dos legionários",
    "12898": "Formação manejo de empilhadora",
    "15596":
      "Formação Convênio 2o ciclo. Encanamento e instalações de climatização",
    "4349201": "Certificado revisão arnês",
    "4172690": "DURC. Documento único de regularidade contributiva",
    "12902": "Cartão ADR",
    "4590775": "Formação em Prevenção de Riscos Laborais",
    "4590771": "Cartão europeu de seguro doença",
    "4590773": "Cartão de manipulador de alimentos",
    "1201": "Seguro de Responsabilidade Civil. Apólice",
    "12908": "Formação manejo camião grua",
    "1191": "Formação Convênio 2o ciclo. Alvenaria",
    "1206": "Inspeção Técnica de Veículos (ITV ou ITGA)",
    "434350": "Informação de riscos que podem afetar terceiros",
    "4172691": "Certificado A1",
    "1192": "Formação Convênio. Nível Básico 60h-50h",
    "1096": "Formação específica prl para trabalhos em altura",
    "310060": "Formação Convênio 2o ciclo. Revestimentos em gesso.",
    "1208": "Declaração da Segurança Social (TGSS) da situação contributiva",
    "12896": "Certificado eletricista autorizado segundo RD614/2001",
    "1097": "Formação específica prl para trabalhos em espaços confinados",
    "3550":
      "Formação Convênio 2o ciclo. Instalações, montagens, estruturas metálicas",
    "12891": "Seguro Acidentes Convênio. Comprovativo de pagamento",
    "1093": "Documentos de Identidade (DNI, Nie, Passaporte)",
    "12911":
      "Seguro de Responsabilidade Civil do veículo. Comprovativo de pagamento.",
    "12893": "Documento de Associação ao Seguro",
    "12892": "Fichas de Segurança de substâncias químicas",
    "12914": "Ficha técnica",
    "1194":
      "Autorização uso de máquinas-ferramentas (Equipamentos de trabalho)",
    "1207": "Declaração Agência Tributária (AEAT) da situação contributiva",
    "1196": "Certificado Modalidade Preventiva adotada na empresa",
    "12895": "TC2/RNT (Relação Nominal de Trabalhadores)",
    "12912":
      "Certificado de revisão obrigatória do equipamento de trabalho segundo estabelecido pelo fabricante",
    "12899": "Formação manejo plataforma elevatórias (PEMP)",
    "15605":
      "RETA. Segurança Social Autônomos/ Independentes Comprovativo de pagamento. ",
    "1195":
      "Avaliação de Riscos dos trabalhos a executar e medidas preventivas",
    "12890": "Seguro Acidentes Convênio. Apólice",
    "15610":
      "Inscrição no REA (Registo de Empresas Acreditadas do Setor Construção)",
    "1199": "Contrato ou certificado de Serviço de Prevenção Alheio",
    "4590770": "Seguro veículo",
    "1190": "Formação Convênio 2o ciclo. Eletricidade",
    "1095":
      "Formação em prevenção de riscos laborais do posto de trabalho (art. 19 LPRL)",
    "1094": "Entrega de Equipamentos de Proteção Individual (EPIs)",
    "1200":
      "Comprovativo de situação de pagamento do Serviço de Proteção Alheia",
    "1099":
      "Informação sobre os riscos existentes no posto de trabalho (art. 18 LPRL)",
    "1090": "ITA. Informe de Trabalhadores Registados",
    "15591":
      "Formação Convênio 2o ciclo. Operadores de veículos e maquinaria de movimento de terras ",
    "11070":
      "TC1 / RLC (Recibo Liquidação de Pagamento de Quotas) + Comprovativo de pagamento",
  },
};
