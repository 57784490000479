import React from "react";

import BiaComponent from "../../commons/components/BiaComponent";

export default class HomePanel extends BiaComponent {
  state = {
  };



  render() {
    return (
        <div className="work-panel">
            <div className="work-panel-header">
                <h1>PANEL DE ADMINISTRACIÓN</h1>

                {/* <div className="toolbar" style={{ float: "right" }}>
                <SearchBox
                    onValueChange={this.onSearchBoxChange}
                    value={this.state.filterText}
                    handler={this.search.bind(this)}
                    placeholder={this.i18n.placeholders["SearchUserMailCompany"]}
                />
                </div> */}
            </div>
            <div className="buttons-menu">
                {/* <button
                style={{ float: "right" }}
                className="button-success"
                onClick={this.openWindow.bind(this)}
                >
                <i className="fa fa-plus"></i> <span>{this.i18n.users["Add"]}</span>
                </button> */}
            </div>

            <div>
                <p></p>
            </div>

        </div>
    );
  }
}
