import React from "react";
import PropTypes from "prop-types";

export default class FormLabel extends React.Component {
  render() {
    let classNames = this.props.required === "true" ? "required" : "";
    let style = this.props.style
      ? this.props.style
      : { width: this.props.size };

    return (
      <label className={classNames} style={style} htmlFor={this.props.for}>
        {this.props.label}
      </label>
    );
  }
}

FormLabel.propTypes = {
  required: PropTypes.any,
  style: PropTypes.any,
  size: PropTypes.any,
  for: PropTypes.any,
  label: PropTypes.any,
};
