import CommandBasedRepository from "./CommandBasedRepository";

export default class FeaturesRepository extends CommandBasedRepository {
  getFeatures() {
    return super.send("ReadFeatures", {});
  }

  addFeature(title, youTubeId, description) {
    return super.send("AddFeature", {
      params: { title, youTubeId, description },
    });
  }

  updateFeature(id, title, youTubeId, description) {
    return super.send("UpdateFeature", {
      params: { id, title, youTubeId, description },
    });
  }

  deleteFeature(id) {
    return super.send("DeleteFeature", {
      params: { id },
    });
  }

  getUnreadFeatures() {
    return super.send("ReadUnreadFeatures", {});
  }

  markFeaturesAsRead() {
    return super.send("MarkFeaturesAsRead", {});
  }
}
