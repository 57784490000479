function extractParams(query) {
  let vars = query.split("&");
  let queryString = {};
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    let key = decodeURIComponent(pair[0]);
    let value = decodeURIComponent(pair[1]);
    if (typeof queryString[key] === "undefined") {
      queryString[key] = decodeURIComponent(value);
    } else if (typeof queryString[key] === "string") {
      queryString[key] = [queryString[key], decodeURIComponent(value)];
    } else {
      queryString[key].push(decodeURIComponent(value));
    }
  }
  return queryString;
}

let params = extractParams(window.location.search.substring(1));

let lng = "es";
if (params && params.lng) {
  lng = params.lng;
} else if (window.localStorage && window.localStorage.getItem("bia360:lng")) {
  lng = window.localStorage.getItem("bia360:lng");
}

let biaI18n = require("../i18n/languageSelector").default(lng);
export default biaI18n;
