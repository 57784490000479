import CommandBasedRepository from "./CommandBasedRepository";

export default class TokenRepository extends CommandBasedRepository {
  sendToken(companyId) {
    return super.send("AddToken", {
      params: { companyId },
    });
  }

  validateToken(token) {
    return super.send("ReviewToken", {
      params: { token },
    });
  }

  sendUniqueToken(companyId, email) {
    return super.send("AddUniqueToken", {
      params: { companyId, email },
    });
  }

  readUniqueTokens(companyId, responsableCAE) {
    return super.send("ReadUniqueTokens", {
      params: { companyId, responsableCAE },
    });
  }
}
