import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";

class LocalStoragePersistencField extends React.Component {
  constructor(props) {
    super(props);

    this.componentKey = `bia360:${global.authenticatedUser}:search:filter:${this.props.persistenceId}`;
  }

  initialValue() {
    if (this.props.forcedValue) return this.props.forcedValue;
    let value = this.props.value;

    if (window.sessionStorage && this.props.persistenceId) {
      let storedValue = window.sessionStorage.getItem(this.componentKey);

      if (storedValue) {
        value = this.parseStoredValue(storedValue);
      }
    }

    return value;
  }

  componentDidMount() {
    if (this.props.debounced) {
      this.handleSearchDebounced = _.debounce(value => {
        this.props.handler(value);
      }, 1000);
    } else {
      this.handleSearchDebounced = value => this.props.handler(value);
    }

    if (this.props.persistenceId) {
      this.props.onValueChange(this.initialValue());
      this.props.handler(this.initialValue());
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.updateValue(this.props.value);
    }
  }

  parseStoredValue(storedValue) {
    if (!this.props.isArray) return this.parseSingleValue(storedValue);
    return storedValue.split(",").map(d => this.parseSingleValue(d));
  }

  parseSingleValue(value) {
    if (!this.props.isDate) return value;
    return moment(value);
  }

  serializeValue(value) {
    if (!this.props.isArray) return this.serializeSingleValue(value);
    return value.map(d => this.serializeSingleValue(d));
  }

  serializeSingleValue(value) {
    if (!this.props.isDate) return value;
    return value.format();
  }

  valueChange(value) {
    this.updateValue(value, () => {
      this.handleSearchDebounced(value);
    });
  }

  updateValue(value, callback = () => {}) {
    if (window.sessionStorage && this.props.persistenceId) {
      window.sessionStorage.setItem(
        this.componentKey,
        this.serializeValue(value)
      );
    }

    this.props.onValueChange(value);
    callback();
  }

  render() {
    let value = this.props.value;
    let onValueChange = this.valueChange.bind(this);
    return this.props.render
      ? this.props.render({ value, onValueChange })
      : null;
  }
}

LocalStoragePersistencField.propTypes = {
  value: PropTypes.any,
  forcedValue: PropTypes.any,
  render: PropTypes.func,
  persistenceId: PropTypes.string,
  handler: PropTypes.func.isRequired,
  isArray: PropTypes.bool,
  onValueChange: PropTypes.func,
};

LocalStoragePersistencField.defaultProps = {
  isArray: false,
  isDate: false,
  debounced: true,
  onValueChange: () => {},
};

export default LocalStoragePersistencField;
