import CommandBasedRepository from "../commons/repositories/CommandBasedRepository";

export default class FunctionsRepository extends CommandBasedRepository {
  add(name) {
    return super.send("AddFunction", {
      params: { name },
    });
  }

  update(id, name) {
    return super.send("UpdateFunction", {
      params: { id, name },
    });
  }

  delete(functionId) {
    return super.send("DeleteFunction", {
      params: { functionId },
    });
  }

  getAllFunctions() {
    return super.send("ReadAllFunctions", {});
  }
}
