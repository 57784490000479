import React from "react";
import BiaComponent from "./BiaComponent";

export default class List extends BiaComponent {
  constructor(props) {
    super(props);

    let initialCollapsedState = props.collapsed ? props.collapsed : false;

    if (!initialCollapsedState && props.onExpand)
      this.props.onExpand(initialCollapsedState);

    this.state = {
      collapsed: initialCollapsedState,
    };
  }

  list(e) {
    e.preventDefault();

    let newCollapsedStatus = !this.state.collapsed;
    this.setState({ collapsed: newCollapsedStatus });

    if (this.props.onExpand) this.props.onExpand(newCollapsedStatus);
  }

  expand() {
    this.setState({ collapsed: false });

    if (this.props.onExpand) this.props.onExpand(false);
  }

  render() {
    let collapsedDirection = this.state.collapsed ? "right" : "down";
    let contentDisplay = this.state.collapsed ? "collapsed" : "expanded";

    let titleClass = this.props.titleClass
      ? this.props.titleClass
      : this.props.titleStyle
      ? ""
      : "title";
    let titleStyle = this.props.titleStyle ? this.props.titleStyle : {};

    let titleIcon = this.props.titleIcon;
    let titleIconStyle = this.props.titleIconStyle;

    let arrowStyle = this.props.arrowStyle
      ? this.props.arrowStyle
      : { marginRight: "0.4rem" };
    let arrowClass = this.props.arrowClass
      ? this.props.arrowClass
      : "fa fa-caret-" + collapsedDirection;

    return (
      <div>
        <div ref="listTitle" className={titleClass} style={titleStyle}>
          <h3 className={this.props.className}>
            <a href="#/" onClick={this.list.bind(this)}>
              <i className={arrowClass} style={arrowStyle}></i>
              <i className={titleIcon} style={titleIconStyle}></i>
              {this.props.title}
            </a>
          </h3>
        </div>

        <div ref="list" className="body">
          <div className={"content indented " + contentDisplay}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
