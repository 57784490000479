import React from "react";
import BiaComponent from "../../commons/components/BiaComponent";
import LogDocumentReviewRepository from "../../repositories/LogDocumentReviewRepository";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import { addLocale } from 'primereact/api';
import primeES from "../../commons/i18n/prime-es.json";

export default class DocumentReviewPanel extends BiaComponent {
  constructor(props) {
    super(props);
    this.state = {
      documentReviewLogs: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadDocumentReviewLog();
  }

  loadDocumentReviewLog() {
    let repository = new LogDocumentReviewRepository();

    this.setState({ isLoading: true });

    repository.getLastMonthLog().then(
      data => {
        this.setState({ documentReviewLogs: data.records });
      }).catch(
        error => {
          console.error(error);
        }).finally(() => {
          this.setState({ isLoading: false });
        }
        );
  }

  formatDate(date) {
    // Format date to day-MM
    let formatDate = new Date(date);
    return formatDate.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",

    });

  }


  render() {
    addLocale('es', primeES);


    const revieDateTemplate = (rowData) => {
      return this.formatDate(rowData.reviewDate);
    };


    return (
      <div className="work-panel">
        <div className="work-panel-header">
          <h1>Revisión de documentos</h1>
        </div>

        <div>
          {this.state.isLoading ? (
            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="6" fill="var(--surface-ground)" animationDuration=".5s" color="#ae172b" aria-label="Loading" />
          ) : (
            <DataTable value={this.state.documentReviewLogs} tableStyle={{ minWidth: '100%', padding: "10px" }} paginator paginatorTemplate="FirstPageLink PrevPageLink PageLinks
            NextPageLink LastPageLink" rows={50} filterDisplay="row">
              <Column field="userLogin" header="Usuario" style={{ padding: "5px" }} filter filterPlaceholder="Busca por login"></Column>
              <Column field="reviewDate" body={revieDateTemplate} header="Fecha de revisión" style={{ padding: "5px", textAlign: "center", minWidth:"10rem" }} sortable></Column>
              <Column field="companyName" header="Empresa" style={{ padding: "5px" }} filter filterPlaceholder="Busca por contrata"></Column>
              <Column field="requestedByCompanyName" header="Solicitado por" style={{ padding: "5px", }} filter filterPlaceholder="Busca por PI"></Column>
              <Column field="workerCif" header="Nif trabajador" style={{ padding: "5px" }}></Column>
              <Column field="fullWorkerName" header="Nombre" style={{ padding: "5px", }}></Column>
              {/* <Column field="workerSurname" header="Apellido" style={{padding:"5px"}}></Column> */}
              <Column field="documentTypeName" header="Tipo documento" style={{ padding: "5px" }}></Column>
              <Column field="status" header="Estado" style={{ padding: "5px" }}></Column>
              <Column field="reason" header="Motivo" style={{ padding: "5px", maxWidth: '30rem' }}></Column>
              {/* <Column field="contratSubject" header="Contrato" style={{padding:"5px"}}></Column> */}
              {/* <Column field="workCenterName" header="Centro de trabajo" style={{padding:"5px"}}></Column> */}
            </DataTable>
          )}

        </div>

      </div>
    );
  }
}
