import React from "react";
import biaI18n from "../i18n/biaI18n";

const searchMappings = [
  { origin: /[a\xE1A\xC1]/, substitution: /[\xE1a]/ },
  { origin: /[e\xE9E\xC9]/, substitution: /[\xE9e]/ },
  { origin: /[i\xEDI\xCD]/, substitution: /[\xEDi]/ },
  { origin: /[o\xF3O\xD3]/, substitution: /[\xF3o]/ },
  { origin: /[u\xFAU\xDA]/, substitution: /[\xFAu]/ },
];

export default class BiaComponent extends React.Component {
  constructor(props) {
    super(props);
    this.i18n = biaI18n;
  }

  isNullOrEmpty(value) {
    return value === undefined || value === "";
  }

  fieldContainsText(text, textToFind) {
    if (!text || !textToFind) return false;

    let regexToFind = this.getRegExToFind(textToFind);
    return text.search(regexToFind) !== -1;
  }

  getRegExToFind(textToFind) {
    let regexSource = Array.from(textToFind)
      .map(ch => {
        for (let mapping of searchMappings) {
          if (ch.match(mapping.origin)) {
            return mapping.substitution.source;
          }
        }
        return ch;
      })
      .join("");

    return new RegExp(regexSource, "gi");
  }

  mapContainsText(mapa, field, text) {
    if (!mapa || !field || !text) return false;

    var resultado = false;

    mapa.forEach(item => {
      if (this.fieldContainsText(item[field], text)) {
        resultado = true;
      }
    });

    return resultado;
  }

  submapContainsText(mapa, propiedadDondeEstaElSubmapa, field, text) {
    if (!mapa || !field || !text || !propiedadDondeEstaElSubmapa) return false;

    var resultado = false;
    mapa.forEach(item => {
      if (
        this.mapContainsText(item[propiedadDondeEstaElSubmapa], field, text)
      ) {
        resultado = true;
      }
    });
    return resultado;
  }

  requirementNameTranslation(requirementId, requirementName) {
    if (
      !biaI18n.requirement_names ||
      !biaI18n.requirement_names[requirementId]
    ) {
      return requirementName;
    } else {
      return biaI18n.requirement_names[requirementId];
    }
  }
}
