import CommandBasedRepository from "./CommandBasedRepository";

export default class UsersRepository extends CommandBasedRepository {
  getUsers() {
    return super.send("ReadUsers", {});
  }

  addUser(login, mail, password, companyCif, profiles = []) {
    return super.send("AddUser", {
      params: {
        login,
        mail,
        password,
        companyCif,
        profiles: profiles.join(","),
      },
    });
  }

  updateUser(id, login, mail, password, profiles = []) {
    return super.send("UpdateUser", {
      params: { id, login, mail, password, profiles: profiles.join(",") },
    });
  }

  addCompany(userId, companyCif) {
    return super.send("AddCompanyUser", {
      params: { userId, companyCif },
    });
  }

  delete(userId) {
    return super.send("DeleteUser", {
      params: { userId },
    });
  }

  deleteCompany(userId, companyCif) {
    return super.send("DeleteCompanyUser", {
      params: { userId, companyCif },
    });
  }

  sendCredentials(userId, companyCif) {
    return super.send("AddUserCredentials", {
      params: { userId, companyCif },
    });
  }

  searchUsers(textToFind) {
    return super.send("SearchUsers", {
      params: { textToFind },
    });
  }
}
