import CommandBasedRepository from "../commons/repositories/CommandBasedRepository";

export default class RolesRepository extends CommandBasedRepository {
  add(name) {
    return super.send("AddRole", {
      params: { name },
    });
  }

  update(id, name) {
    return super.send("UpdateRole", {
      params: { id, name },
    });
  }

  delete(roleId) {
    return super.send("DeleteRole", {
      params: { roleId },
    });
  }

  getAllRoles() {
    return super.send("ReadAllRoles", {});
  }
}
