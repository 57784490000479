import React from "react";
import BiaComponent from "./BiaComponent";
import RowDetail from "./RowDetail";
import Window from "./Window";
import Toast from "./Toast";
import PropTypes from "prop-types";

import CompaniesRepository from "../repositories/CompaniesRepository";
import TokenRepository from "../repositories/TokenRepository";
import biaI18n from "../i18n/biaI18n";
import Tag from "./Tag";
import List from "../../commons/components/List";

const TokenValidityTag = ({ isTokenValid }) => {
  if (!isTokenValid)
    return (
      <Tag
        type="INCORRECT"
        showZero="true"
        label={biaI18n.token.inactiveCredentials}
      />
    );

  return (
    <Tag type="OK" showZero="true" label={biaI18n.token.activeCredentials} />
  );
};

const LastSendedToken = ({
  lastGeneratedTokenDate,
  isTokenValid,
  numberOfRecipients,
}) => {
  if (numberOfRecipients === 0) {
    return (
      <span style={{ marginRight: "0.5rem" }}>
        {biaI18n.token.noShipmentDocumentation}
      </span>
    );
  }

  return (
    <div
      style={{
        display: "inline-block",
        verticalAlign: "top",
        padding: 0,
      }}
    >
      <TokenValidityTag isTokenValid={isTokenValid} />
      <div
        style={{
          display: "inline-block",
          width: "14.5rem",
          verticalAlign: "top",
        }}
      >
        <div>
          <span style={{ margin: "0 0.9rem" }}>
            <span className="detail-label">{biaI18n.token.sentOn}</span>{" "}
            <span>{lastGeneratedTokenDate}</span>
          </span>
        </div>
        <div>
          <span style={{ margin: "0 0.9rem" }}>
            <span className="detail-label">
              {biaI18n.token.numberOfRecipients}
            </span>{" "}
            <span>{numberOfRecipients}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

const SendedTokenInfo = ({
  pi,
  admin,
  lastGeneratedTokenDate,
  isTokenValid,
  onSendToken,
  numberOfRecipients,
  responsible,
}) => {
  if (!pi || admin) return null;

  let buttonToken = (
    <button className="button-upload" onClick={onSendToken}>
      <i className="fa fa-link" /> {biaI18n.buttons["Token"]}
    </button>
  );

  return (
    <div style={{ marginTop: "1.6rem" }}>
      <LastSendedToken
        lastGeneratedTokenDate={lastGeneratedTokenDate}
        isTokenValid={isTokenValid}
        numberOfRecipients={numberOfRecipients}
      />

      {buttonToken}

      {responsible}
    </div>
  );
};

export default class Company extends BiaComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      invalidMails: [],
      modalIsOpen: false,
      tokens: new Map(),
      validTokens: new Map(),
    };
  }

  openWorkerDeleteConfirm(e) {
    e.preventDefault();
    this.refs.confirm.open();
  }

  openSendTokenConfirm(e) {
    e.preventDefault();
    this.refs.confirmToken.open();
  }

  deleteCompany(companyId) {
    new CompaniesRepository()
      .delete(companyId)
      .then(() => {
        this.refs.confirm.close();
        Toast.success(this.i18n.companies["Deleted"]);
        if (this.props.onDelete) this.props.onDelete();
      })
      .catch(message => {
        this.refs.confirm.close();
        Toast.error(this.i18n.companies["DeletedError"]);
      });
  }

  openEdit() {
    if (this.props.onEdit) this.props.onEdit(this.props.data);
  }

  sendToken() {
    let { onUpdate } = this.props;
    new TokenRepository()
      .sendToken(this.props.data.id)
      .then(({ records }) => {
        this.refs.confirmToken.close();

        if (records[0].mails.length > 0) {
          this.refs.invalidMails.open();
          this.setState({ invalidMails: records[0].mails });
        } else {
          Toast.success(this.i18n.companies["TokenSent"]);
        }

        if (onUpdate) onUpdate();
      })
      .catch(message => {
        this.refs.confirmToken.close();
        Toast.error(message);
      });
  }

  sendUniqueToken(mail) {
    new TokenRepository()
      .sendUniqueToken(this.props.data.id, mail)
      .then(({ records }) => {
        if (records[0].mails.length > 0) {
          Toast.error(this.i18n.companies["InvalidMailAddress"]);
        } else {
          Toast.success(this.i18n.companies["TokenSent"]);
          this.getUniqueTokens();
        }
      })
      .catch(message => {
        this.refs.confirmToken.close();
        Toast.error(message);
      });
  }

  getUniqueTokens = () => {
    let tokensMap = new Map();
    let validTokensMap = new Map();
    this.props.data.responsablesCAE.forEach(r => {
      new TokenRepository()
        .readUniqueTokens(this.props.data.id, r)
        .then(token => {
          tokensMap.set(r, token.records[0].generatedTokenDate);
          this.setState({ tokens: tokensMap });
          validTokensMap.set(r, token.records[0].isValidToken);
          this.setState({ validTokens: validTokensMap });
        });
    });
  };

  render() {
    const BASE_URL = process.env.NODE_ENV === "production"?"https://app.bia360cae.com/api/image":"http://localhost:9082/api/image";
    this.state.data = this.props.data;

    let {
      admin,
      data: { pi, lastGeneratedTokenDate, isTokenValid, recipients },
    } = this.props;

    let tag = "";
    if (this.props.renderTag) {
      tag = this.props.renderTag();
    }

    let picture = (
      <img
        alt="Company logo"
        className="picture"
        loading="lazy"
        src={`${BASE_URL}/company/${this.state.data.id}`}
      />
    );

    let realCif = this.state.data.cif;

    if (realCif) realCif = realCif.split("_")[0];

    let buttons = "";
    if (this.props.actionButtons) {
      buttons = this.props.actionButtons;
    } else if (this.state.data.editable) {
      buttons = (
        <span>
          <button
            className="button-error"
            onClick={this.openWorkerDeleteConfirm.bind(this)}
            style={{ float: "right" }}
          >
            <i className="fa fa-trash" /> {this.i18n.buttons["Delete"]}
          </button>

          <button
            className="button-upload"
            onClick={this.openEdit.bind(this)}
            style={{ float: "right" }}
          >
            <i className="fa fa-building" /> {this.i18n.buttons["Edit"]}
          </button>
        </span>
      );
    }

    let createdBy = "";

    if (this.props.createdBy) {
      createdBy =
        " (" + biaI18n.texts["createdBy"] + this.props.createdBy.name + ")";
    }

    let responsibleList = (
      <List
        title={biaI18n.companies["Recipients"]}
        collapsed={true}
        titleStyle={{
          fontSize: "1rem",
          marginTop: "0.4rem",
          marginLeft: "0.5rem",
        }}
        className="workers-contracts-assigned"
        onExpand={this.getUniqueTokens}
      >
        {this.props.data.responsablesCAE.sort().map(recipient => {
          return (
            <div>
              <div
                className="basic-row highlightrow"
                style={{
                  padding: "0.4rem",
                  margin: "0.5rem",
                  marginLeft: "auto",
                  float: "auto",
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    marginTop: "1rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  {recipient}
                  <div
                    style={{
                      width: "38rem",
                      verticalAlign: "top",
                      marginTop: "1rem",
                      fontSize: "14px",
                    }}
                  >
                    <div style={{ float: "left", marginRight: "1rem" }}>
                      <TokenValidityTag
                        isTokenValid={this.state.validTokens.get(recipient)}
                      />
                    </div>
                    {this.state.tokens.get(recipient) !== "" ? (
                      <span style={{ float: "left" }}>
                        <span className="detail-label">
                          {biaI18n.token.sentOn}
                        </span>{" "}
                        <span>{this.state.tokens.get(recipient)}</span>
                      </span>
                    ) : (
                      <span>{biaI18n.token.noShipment}</span>
                    )}
                  </div>
                </span>
                <div
                  style={{
                    marginLeft: "24rem",
                    position: "absolute",
                    marginTop: "1.3rem",
                  }}
                >
                  <button
                    className="button-upload"
                    value={recipient}
                    onClick={this.sendUniqueToken.bind(this, recipient)}
                  >
                    <i className="fa fa-link" /> {biaI18n.buttons["Token"]}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </List>
    );

    return (
      <div key={this.props.uniqueKey}>
        <div className="worker highlightrow" style={{ display: "flex" }}>
          <div>
            <RowDetail
              picture={picture}
              name={this.state.data.name + createdBy}
              cif={realCif}
              tags={tag}
            />
            <SendedTokenInfo
              pi={pi}
              admin={admin}
              lastGeneratedTokenDate={lastGeneratedTokenDate}
              isTokenValid={isTokenValid}
              numberOfRecipients={recipients == null ? 0 : recipients.length}
              onSendToken={this.openSendTokenConfirm.bind(this)}
              responsible={responsibleList}
            />
          </div>
          <div style={{ marginLeft: "auto", marginRight: "0" }}>{buttons}</div>

          <Window
            ref="confirm"
            title={this.i18n.companies["Delete"]}
            acceptButtonText={this.i18n.buttons["Delete"]}
            onAccept={this.deleteCompany.bind(this, this.state.data.id)}
          >
            <p>{this.i18n.companies["SureDelete"]}</p>
          </Window>

          <Window
            ref="confirmToken"
            title={this.i18n.companies["Token"]}
            acceptButtonText={this.i18n.buttons["Send"]}
            onAccept={this.sendToken.bind(this)}
          >
            <p>{this.i18n.companies["SureToken"]}</p>
          </Window>

          <Window
            ref="invalidMails"
            title={this.i18n.companies["InvalidMailAddresses"]}
            acceptButtonText={this.i18n.buttons["Ok"]}
            block="true"
            onAccept={() => this.refs.invalidMails.close()}
          >
            <div>{this.i18n.companies["InvalidMailAddressesText"]}</div>
            <ul style={{ marginLeft: "2rem", marginTop: "1rem" }}>
              {this.state.invalidMails.map(mail => (
                <li key={mail}>{mail}</li>
              ))}
            </ul>
          </Window>
        </div>
      </div>
    );
  }
}

Company.propTypes = {
  renderTag: PropTypes.func,
  actionButtons: PropTypes.node,
};
