import React from "react";
import BiaComponent from "../../commons/components/BiaComponent";
import DocumentTypesRepository from "../../repositories/DocumentTypesRepository";
import Toast from "../../commons/components/Toast";
import FormLabel from "../../commons/components/FormLabel";

export default class FunctionDocumentTypesDropDown extends BiaComponent {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      values: [],
    };
  }

  componentDidMount() {
    let values = [];
    let options = [];
    new DocumentTypesRepository()
      .getFunctionDocumentTypesAvailable(this.props.data.id, "MACHINE")
      .then(dtypes => {
        dtypes.records.forEach(function (dtype) {
          if (!dtype.assignable) {
            values.push({ value: dtype.id, label: dtype.name });
          } else {
            options.push({ value: dtype.id, label: dtype.name });
          }
        });

        this.setState({ options: options, values: values });
      });
  }

  addDocumentType() {
    let select = this.refs.select;
    if (select.value !== "") {
      new DocumentTypesRepository()
        .assignDocumentTypeToFunction(this.props.data.id, select.value)
        .then(dtypes => {
          Toast.success(this.i18n.functions.documentTypes["Saved"]);
          this.onUpdateDocumentType();
        });
    } else {
      Toast.error(this.i18n.functions.documentTypes["Select"]);
    }
  }

  deleteDocumentType(dtypeId, e) {
    e.preventDefault();

    new DocumentTypesRepository()
      .deleteDocumentTypeFunction(this.props.data.id, dtypeId)
      .then(dtypes => {
        Toast.success(this.i18n.functions.documentTypes["Deleted"]);
        this.onUpdateDocumentType();
      });
  }

  onUpdateDocumentType() {
    if (this.props.onUpdate) this.props.onUpdate();
    this.componentDidMount();
  }

  render() {
    let select = (
      <select ref="select">
        <option value="">
          {this.i18n.functions.documentTypes["NoAvailable"]}
        </option>
      </select>
    );

    if (this.state.options.length > 0) {
      select = (
        <select ref="select">
          {this.state.options.map(s => (
            <option key={s.value} value={s.value}>
              {s.label}
            </option>
          ))}
        </select>
      );
    }

    return (
      <form>
        <div className="field">
          <p>{this.i18n.functions.documentTypes["Assigned"]}</p>
          {this.state.values.map(s => (
            <div key={s.value}>
              <a
                href="#/"
                onClick={this.deleteDocumentType.bind(this, s.value)}
              >
                <i className="fa fa-times" />
              </a>
              <span>{s.label}</span>
            </div>
          ))}
        </div>
        <span>
          <div className="field" style={{ display: "inline-block" }}>
            <FormLabel
              label={this.i18n.functions.documentTypes["Available"]}
              size="11.8rem"
            />
            {select}
          </div>
          <button
            className="button-success"
            onClick={this.addDocumentType.bind(this)}
          >
            <i className="fa fa-plus"></i>{" "}
            <span>{this.i18n.buttons["Assign"]}</span>
          </button>
        </span>
      </form>
    );
  }
}
