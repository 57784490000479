import React from "react";
import BiaComponent from "./BiaComponent";
import Window from "./Window";
import UsersRepository from "../repositories/UsersRepository";
import CompaniesAssignableDropDown from "./CompaniesAssignableDropDown";
import Toast from "./Toast";
import moment from "moment";

export default class User extends BiaComponent {
  constructor(props) {
    super(props);

    this.state = {
      latestCredentialsSent: this.props.data.latestCredentialsSent,
      modalIsOpen: false,
    };
  }

  openWindow(e) {
    e.preventDefault();
    this.refs.window.open();
  }

  openUserDeleteConfirm(e) {
    e.preventDefault();
    this.refs.confirm.open();
  }

  sendCredentials(userId) {
    new UsersRepository()
      .sendCredentials(userId, global.authenticatedUser)
      .then(data => {
        this.setState({
          latestCredentialsSent: data.records[0].latestCredentialsSent,
        });
        Toast.success(this.i18n.users["CredentialsSentSuccessfully"]);
      });
  }

  deleteUser(userId) {
    new UsersRepository()
      .delete(userId)
      .then(() => {
        this.refs.confirm.close();
        Toast.success(this.i18n.users["Deleted"]);
        if (this.props.onDelete) this.props.onDelete();
      })
      .catch(Toast.error);
  }

  openEdit() {
    if (this.props.onEdit) this.props.onEdit(this.props.data);
  }

  render() {
    let buttonCompanies = (
      <button
        className="button-upload"
        onClick={this.openWindow.bind(this)}
        style={{ float: "right" }}
      >
        <i className="fa fa-building"></i> {this.i18n.menu["Companies"]}
      </button>
    );

    let latestCredentialsSent = this.state.latestCredentialsSent ? (
      <div style={{ fontSize: "90%" }}>
        {this.i18n.users["LatestCredentialsSent"]}
        {moment(this.state.latestCredentialsSent).format("DD/MM/YYYY HH:mm")}
      </div>
    ) : (
      ""
    );

    return (
      <div className="table-row highlightrow">
        <div className="table-row-title">
          <div className="name">{this.props.data.login}</div>
          <div
            style={{
              marginLeft: "1rem",
              color: "#777777",
              marginTop: "0.3rem",
            }}
          >
            {latestCredentialsSent}
          </div>
        </div>

        <button
          className="button-error"
          onClick={this.openUserDeleteConfirm.bind(this)}
          style={{ float: "right" }}
        >
          <i className="fa fa-trash"></i> {this.i18n.buttons["Delete"]}
        </button>

        <button
          className="button-upload"
          onClick={this.sendCredentials.bind(this, this.props.data.id)}
          style={{ float: "right" }}
        >
          <i className="fa fa-link"></i> {this.i18n.buttons["Credentials"]}
        </button>

        {buttonCompanies}

        <button
          className="button-upload"
          onClick={this.openEdit.bind(this)}
          style={{ float: "right" }}
        >
          <i className="fa fa-edit"></i> {this.i18n.buttons["Edit"]}
        </button>

        <Window ref="window" title={this.i18n.companies["Assign"]}>
          <CompaniesAssignableDropDown user={this.props.data} />
        </Window>

        <Window
          ref="confirm"
          title={this.i18n.users["Delete"]}
          acceptButtonText={this.i18n.buttons["Delete"]}
          onAccept={this.deleteUser.bind(this, this.props.data.id)}
        >
          <p>{this.i18n.users["SureDelete"]}</p>
        </Window>
      </div>
    );
  }
}
