import React from "react";
import BiaComponent from "../../commons/components/BiaComponent";
import CompaniesRepository from "../../commons/repositories/CompaniesRepository";
import FormLabel from "../../commons/components/FormLabel";

export default class CompaniesDropDown extends BiaComponent {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
    };
  }

  handleSelection(event) {
    if (this.props.handler) {
      event.preventDefault();
      this.props.handler(event.target.value);
    }
  }

  componentDidMount() {
    new CompaniesRepository().getCompaniesNames().then(companies => {
      if (this.props.handler && companies.records.length > 0) {
        this.props.handler(companies.records[0].cif);
      }

      this.setState({ companies: companies.records });
    });
  }

  render() {
    let noCompanies = (
      <select>
        <option>{this.i18n.companies["NoAvailable"]}</option>
      </select>
    );

    let companies = (
      <select onChange={this.handleSelection.bind(this)}>
        {this.state.companies.map(c => {
          return (
            <option key={c.cif} value={c.cif}>
              {c.name}
            </option>
          );
        })}
      </select>
    );

    return (
      <span>
        <FormLabel
          label={this.props.label}
          size={this.props.size}
          required={this.props.required}
        />

        {this.state.companies.length === 0 ? noCompanies : companies}
      </span>
    );
  }
}
