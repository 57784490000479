import React from "react";
import BiaComponent from "../../commons/components/BiaComponent";
import List from "../../commons/components/List";
import Window from "../../commons/components/Window";
import DocumentTypesRepository from "../../repositories/DocumentTypesRepository";
import Toast from "../../commons/components/Toast";
import FileUploadProgress from "../../commons/components/FileUploadProgress";
import DocumentTypeModalForm from "../../commons/components/DocumentTypeModalForm";

class DocumentType extends BiaComponent {
  showConfirm(e) {
    e.preventDefault();
    this.refs.confirm.open();
  }

  remove(e) {
    if (this.props.onRemove) {
      this.props.onRemove(parseInt(this.props.data.documentTypeId, 10));
    }
  }

  openEdit(e) {
    if (this.props.onEdit) this.props.onEdit(this.props.data);
  }

  openWindow() {
    this.refs.model.open();
  }

  upload() {
    this.refs.form.onSubmit();
  }

  onUploaded(data) {
    this.refs.model.close();
    if (this.props.onUploadedModel) this.props.onUploadedModel();
  }

  deleteModel(id) {
    if (this.props.onRemoveModel) {
      this.props.onRemoveModel(parseInt(id, 10));
    }
  }

  render() {
    let uploadUrl = process.env.NODE_ENV === "production"?"https://api-admin.bia360cae.com/api/upload/model":"http://localhost:9081/api/upload/model";
    return (
      <div>
        <div className="table-row">
          <div className="table-row-title">
            {this.props.data.documentTypeName}
          </div>

          <button
            className="button-error"
            onClick={this.showConfirm.bind(this)}
            style={{ float: "right" }}
          >
            <i className="fa fa-trash"></i> {this.i18n.buttons["Delete"]}
          </button>

          <button
            className="button-upload"
            onClick={this.openEdit.bind(this)}
            style={{ float: "right" }}
          >
            <i className="fa fa-building"></i> {this.i18n.buttons["Edit"]}
          </button>

          <button
            className="button-upload"
            onClick={this.openWindow.bind(this)}
            style={{ float: "right" }}
          >
            <i className="fa fa-arrow-up" />{" "}
            <span>{this.i18n.documents["UploadModel"]}</span>
          </button>
          <Window
            ref="confirm"
            title={this.i18n.contracts.DocumentTypes["Delete"]}
            acceptButtonText={this.i18n.buttons["Delete"]}
            onAccept={this.remove.bind(this)}
          >
            <p>{this.i18n.contracts.DocumentTypes["SureDelete"]}</p>
          </Window>
          <Window
            ref="model"
            title={this.i18n.documents["UploadDocumentTitle"]}
            acceptButtonText={this.i18n.documents["Upload"]}
            onAccept={this.upload.bind(this)}
          >
            <FileUploadProgress
              ref="form"
              key={this.props.data.dtId}
              data={this.props.data}
              showButtonSubmit={false}
              onFinish={this.onUploaded.bind(this)}
              url={uploadUrl}
              style={{ float: "right" }}
            />
          </Window>
        </div>
      </div>
    );
  }
}

class DocumentTypes extends BiaComponent {
  addDocumentType() {
    let data = this.refs[this.props.type].retrieveData();

    let repository = new DocumentTypesRepository();
    if (!this.isNullOrEmpty(data.name)) {
      if (
        data.expire &&
        data.expireMode === "encurso" &&
        (data.expirationMonths === null || data.expirationMonths <= 0)
      ) {
        Toast.error(this.i18n.documentTypes.expiredRequired);
      } else {
        repository
          .addDocumentType(
            this.props.type,
            data.name,
            data.expire,
            data.inForceDate,
            data.endDate,
            data.currentMonth,
            data.expirationMonths,
            data.critical,
            data.comments,
            data.contractorComments,
            data.isAutomaticType ? data.automaticType : "NONE",
            data.tolerate
          )
          .then(() => {
            this.refs[this.props.type].close();
            Toast.success(this.i18n.documentTypes["Saved"]);
            if (this.props.onAdd) this.props.onAdd();
          });
      }
    } else {
      Toast.error(this.i18n.documentTypes.nameRequired);
    }
  }

  updateDocumentType(documentTypeId) {
    let data = this.refs["edit" + documentTypeId].retrieveData();

    let repository = new DocumentTypesRepository();
    if (!this.isNullOrEmpty(data.dtId) && !this.isNullOrEmpty(data.name)) {
      if (
        data.expire &&
        data.expireMode === "encurso" &&
        (data.expirationMonths === null || data.expirationMonths <= 0)
      ) {
        Toast.error(this.i18n.documentTypes.expiredRequired);
      } else {
        repository
          .updateDocumentType(
            data.dtId,
            data.name,
            data.expire,
            data.inForceDate,
            data.endDate,
            data.currentMonth,
            data.expirationMonths,
            data.critical,
            data.comments,
            data.contractorComments,
            data.isAutomaticType ? data.automaticType : "NONE",
            data.tolerate
          )
          .then(() => {
            this.refs["edit" + documentTypeId].close();
            Toast.success(this.i18n.documentTypes["Saved"]);
            if (this.props.onAdd) this.props.onAdd();
          });
      }
    } else {
      Toast.error(this.i18n.documentTypes.nameRequired);
    }
  }

  deleteDocumentType(id) {
    let repository = new DocumentTypesRepository();
    repository.deleteDocumentType(id).then(() => {
      this.refs[this.props.type].close();
      Toast.success(this.i18n.documentTypes["Deleted"]);
      if (this.props.onAdd) this.props.onAdd();
    });
  }

  onUploadedModel() {
    if (this.props.onAdd) this.props.onAdd();
  }

  deleteDocumentTypeModel(id) {
    let repository = new DocumentTypesRepository();
    repository.deleteDocumentTypeModel(id).then(() => {
      if (this.props.onAdd) this.props.onAdd();
    });
  }

  openWindow() {
    this.refs[this.props.type].open();
  }

  onEditDocumentType(data) {
    this.refs["edit" + data.documentTypeId].open();
  }

  render() {
    let button = (
      <button className="button-success" onClick={this.openWindow.bind(this)}>
        <i className="fa fa-plus"></i>{" "}
        {this.i18n.contracts.DocumentTypes["Add"]}
      </button>
    );

    let title = <span>{this.props.name}</span>;

    return (
      <div className="templates" style={{ marginBottom: "2rem" }}>
        {button}
        <DocumentTypeModalForm
          ref={this.props.type}
          title={this.i18n.contracts.DocumentTypes["Add"]}
          type={this.props.type}
          acceptButtonText={this.i18n.buttons["Create"]}
          onAccept={this.addDocumentType.bind(this)}
        />

        <List title={title}>
          <div style={{ marginTop: "2rem" }}>
            {this.props.data.map(dt => (
              <span key={dt.documentTypeId}>
                <DocumentType
                  data={dt}
                  onRemove={this.deleteDocumentType.bind(this)}
                  onEdit={this.onEditDocumentType.bind(this)}
                  onRemoveModel={this.deleteDocumentTypeModel.bind(this)}
                  onUploadedModel={this.onUploadedModel.bind(this)}
                />

                <DocumentTypeModalForm
                  key={"edit" + dt.documentTypeId}
                  ref={"edit" + dt.documentTypeId}
                  title={this.i18n.contracts.DocumentTypes["Edit"]}
                  type={this.props.type}
                  acceptButtonText={this.i18n.buttons["Save"]}
                  onAccept={this.updateDocumentType.bind(
                    this,
                    dt.documentTypeId
                  )}
                  data={dt}
                />
              </span>
            ))}
          </div>
        </List>
      </div>
    );
  }
}

export default class DocumentTypesPanel extends BiaComponent {
  constructor(props) {
    super(props);

    this.state = {
      documentTypes: {
        worker: [],
        company: [],
        machine: [],
      },
    };
  }

  componentDidMount() {
    let documentTypesRepository = new DocumentTypesRepository();

    documentTypesRepository.getDocumentTypes().then(data => {
      this.setState({ documentTypes: data.records[0] });
    });
  }

  onAdd() {
    this.componentDidMount();
  }

  render() {
    if (this.props.children) {
      return (
        <div>
          <div>{this.props.children}</div>
        </div>
      );
    }

    return (
      <div className="work-panel">
        <div className="work-panel-header">
          <h1>{this.i18n.menu["DocumentTypes"]}</h1>
        </div>

        <DocumentTypes
          type="WORKER"
          name={this.i18n.contracts.DocumentTypes["Worker"]}
          data={this.state.documentTypes.worker}
          onAdd={this.onAdd.bind(this)}
        />

        <DocumentTypes
          type="COMPANY"
          name={this.i18n.contracts.DocumentTypes["Company"]}
          data={this.state.documentTypes.company}
          onAdd={this.onAdd.bind(this)}
        />

        <DocumentTypes
          type="MACHINE"
          name={this.i18n.contracts.DocumentTypes["Machine"]}
          data={this.state.documentTypes.machine}
          onAdd={this.onAdd.bind(this)}
        />
      </div>
    );
  }
}
