import React from "react";
import BiaComponent from "../../commons/components/BiaComponent";
import FormLabel from "../../commons/components/FormLabel";
import Toast from "../../commons/components/Toast";
import EstimatesRepository from "../../repositories/EstimatesRepository";

const DEFAULT_INVOICING_FEE = 54.0;
const DEFAULT_INVOICING_COST_PER_RESOURCE = 0.3;

export default class EstimateForm extends BiaComponent {
  constructor(props) {
    super(props);

    this.state = this.defaultState();
  }

  reset() {
    this.setState(this.defaultState());
  }

  defaultState() {
    return {
      companyName: "",
      email: "",
      contractorsNumber: "",
      workCentersNumber: "",
      workersNumber: "",
      machinesNumber: "",
      needCustomization: null,
      needConsultancy: null,
      fee: DEFAULT_INVOICING_FEE,
      costPerResource: DEFAULT_INVOICING_COST_PER_RESOURCE,
    };
  }

  companyNameChange(event) {
    this.setState({ companyName: event.target.value });
  }

  emailChange(event) {
    this.setState({ email: event.target.value });
  }

  contractorsNumberChange(event) {
    this.setState({ contractorsNumber: event.target.value });
  }

  workCentersNumberChange(event) {
    this.setState({ workCentersNumber: event.target.value });
  }

  workersNumberChange(event) {
    this.setState({ workersNumber: event.target.value });
  }

  machinesNumberChange(event) {
    this.setState({ machinesNumber: event.target.value });
  }

  feeChange(event) {
    this.setState({ fee: event.target.value });
  }

  costPerResourceChange(event) {
    this.setState({ costPerResource: event.target.value });
  }

  needCustomizationChange(needCustomization) {
    this.setState({ needCustomization });
  }

  needConsultancyChange(needConsultancy) {
    this.setState({ needConsultancy });
  }

  sendEstimate(event) {
    event.preventDefault();
    if (this.isValid()) {
      Toast.error("Todos los campos con obligatorios");
    } else {
      new EstimatesRepository()
        .sendEstimate(
          this.state.companyName,
          this.state.email,
          this.state.contractorsNumber,
          this.state.workCentersNumber,
          this.state.workersNumber,
          this.state.machinesNumber,
          this.state.needCustomization,
          this.state.needConsultancy,
          this.state.fee,
          this.state.costPerResource
        )
        .then(() => {
          this.reset();
          Toast.success("Presupuesto enviado");
        })
        .catch(message => {
          Toast.error(message);
        });
    }
  }

  isValid() {
    return (
      this.isNullOrEmpty(this.state.companyName) ||
      this.isNullOrEmpty(this.state.email) ||
      this.isNullOrEmpty(this.state.contractorsNumber) ||
      this.isNullOrEmpty(this.state.workCentersNumber) ||
      this.isNullOrEmpty(this.state.workersNumber) ||
      this.isNullOrEmpty(this.state.machinesNumber) ||
      this.state.needCustomization == null ||
      this.state.needConsultancy == null
    );
  }

  render() {
    return (
      <div className="form-responsive">
        <h2>Envío de presupuesto</h2>
        <form>
          <div className="row">
            <div className="col-25">
              <FormLabel
                label="Empresa"
                required="true"
                for="companyName"
                size="10.5rem"
              />
            </div>
            <div className="col-75">
              <input
                name="companyName"
                type="text"
                value={this.state.companyName}
                onChange={this.companyNameChange.bind(this)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <FormLabel
                label="Correo de contacto"
                required="true"
                for="email"
                size="10.5rem"
              />
            </div>
            <div className="col-75">
              <input
                name="email"
                type="text"
                value={this.state.email}
                onChange={this.emailChange.bind(this)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <FormLabel
                label="Nº contratas"
                required="true"
                for="contractorsNumber"
                size="10.5rem"
              />
            </div>
            <div className="col-75">
              <input
                name="contractorsNumber"
                type="number"
                value={this.state.contractorsNumber}
                onChange={this.contractorsNumberChange.bind(this)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <FormLabel
                label="Nº centros de trabajo"
                required="true"
                for="workCenterNumber"
                size="10.5rem"
              />
            </div>
            <div className="col-75">
              <input
                name="workCentersNumber"
                type="number"
                value={this.state.workCentersNumber}
                onChange={this.workCentersNumberChange.bind(this)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <FormLabel
                label="Nº trabajadores"
                required="true"
                for="workersNumber"
              />
            </div>
            <div className="col-75">
              <input
                name="workersNumber"
                type="number"
                value={this.state.workersNumber}
                onChange={this.workersNumberChange.bind(this)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <FormLabel
                label="Número de equipos de trabajo"
                required="true"
                for="machinesNumber"
              />
            </div>
            <div className="col-75">
              <input
                name="machinesNumber"
                type="number"
                value={this.state.machinesNumber}
                onChange={this.machinesNumberChange.bind(this)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <FormLabel label="Cuota mensual" required="true" for="fee" />
            </div>
            <div className="col-75">
              <input
                name="fee"
                type="number"
                value={this.state.fee}
                onChange={this.feeChange.bind(this)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <FormLabel label="Coste por recurso" required="true" for="fee" />
            </div>
            <div className="col-75">
              <input
                name="costPerResource"
                type="number"
                value={this.state.costPerResource}
                onChange={this.costPerResourceChange.bind(this)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <FormLabel label="¿Necesita parametrización?" required="true" />
            </div>
            <div className="col-75">
              <span style={{ marginRight: "3rem" }}>
                <input
                  name="needCustomization"
                  type="radio"
                  checked={this.state.needCustomization}
                  onChange={this.needCustomizationChange.bind(this, true)}
                />
                <FormLabel label="Sí" />
              </span>
              <span>
                <input
                  name="needCustomization"
                  type="radio"
                  checked={
                    this.state.needCustomization != null &&
                    !this.state.needCustomization
                  }
                  onChange={this.needCustomizationChange.bind(this, false)}
                />
                <FormLabel label="No" />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <FormLabel label="¿Necesita consultoría?" required="true" />
            </div>
            <div className="col-75">
              <span style={{ marginRight: "3rem" }}>
                <input
                  name="needConsultancy"
                  type="radio"
                  checked={this.state.needConsultancy}
                  onChange={this.needConsultancyChange.bind(this, true)}
                />
                <FormLabel label="Sí" />
              </span>
              <span>
                <input
                  name="needConsultancy"
                  type="radio"
                  checked={
                    this.state.needConsultancy != null &&
                    !this.state.needConsultancy
                  }
                  onChange={this.needConsultancyChange.bind(this, false)}
                />
                <FormLabel label="No" />
              </span>
            </div>
          </div>
          <div className="row">
            <button
              className="button-success"
              onClick={this.sendEstimate.bind(this)}
            >
              Enviar presupuesto
            </button>
          </div>
        </form>
      </div>
    );
  }
}
