import React from 'react';
import BiaComponent from './BiaComponent';
import Tag from './Tag';

export default class RowDetail extends BiaComponent {
   render() {
      let detail = '';
      if (this.props.detail) {
         detail = <span className="row-detail">(<span className="content">{this.props.detail}</span>)</span>;
      }

      let tags = '';
      if (this.props.tags) {
         tags = <span>{this.props.tags}</span>;
      }

      let picture = '';
      if (this.props.picture) {
         picture = (
            <div className="picture-block">
               {this.props.picture}
            </div>
         );
      }

      let autonomo = '';
      if (this.props.autonomo) {
         autonomo = (
            <Tag
               id="autonomo"
               type="TYPE"
               showZero="true"
               label={this.i18n.tag['selfEmployed']}
            />
         );
      }

      let roles = '';
      if (this.props.roles) {
         roles = this.props.roles.map((role) => (
            <Tag
               id={role.name}
               type="TYPE"
               showZero="true"
               label={role.name}
            />
         ));
      }

      let ett = '';
      if (this.props.ett) {
         ett = (
            <Tag
               id="ett"
               type="TYPE"
               showZero="true"
               label="ETT"
               width="3rem"
            />
         );
      }
      return (
         <div className="details">
            {picture}
            <div className="identification">
               <div>
                  <span className="name">{this.props.name}</span>
                  {detail}
               </div>

               <div>
                  <span className="fontfixed" style={{marginRight: '0.4rem'}}>{this.props.cif}</span>
                  {autonomo}
                  {ett}
                  {roles}
                  {tags}
               </div>
            </div>
         </div>
      );
   }
}

