import React from "react";

import BiaComponent from "../../commons/components/BiaComponent";
import Company from "../../commons/components/Company";
import SearchBox from "../../commons/components/SearchBox";
import Toast from "../../commons/components/Toast";
import Window from "../../commons/components/Window";

import MultiInput from "../../commons/components/company/MultiInput";
import FileUploadProgress from "../../commons/components/FileUploadProgress";
import FormLabel from "../../commons/components/FormLabel";
import Tag from "../../commons/components/Tag";
import languages from "../../commons/i18n/languages";
import CompaniesRepository from "../../commons/repositories/CompaniesRepository";
import PrescribersRepository from "../../repositories/PrescribersRepository";
import { ProgressSpinner } from "primereact/progressspinner";

const DEFAULT_INVOICING_FEE = 54;
const DEFAULT_INVOICING_COST_PER_RESOURCE = 0.3;

const DEFAULT_COMPANY_TYPE = "GENERAL";

const INITIAL_DEFAULT_LANGUAGE = "es";

export default class AdminCompaniesPanel extends BiaComponent {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      prescribers: [],
      prescriberId: null,
      companyId: null,
      name: null,
      cif: null,
      bia360: false,
      trial: false,
      companyType: DEFAULT_COMPANY_TYPE,
      responsableCAE: null,
      responsablesCAE: [""],
      address: null,
      contact: null,
      phone: null,
      web: this.props.web !== undefined ? this.props.web : true,
      companyTypes: [],
      defaultLanguage: INITIAL_DEFAULT_LANGUAGE,
      filterText: "",
      invoiceClient: null,
      invoiceCif: null,
      invoiceEmail: null,
      invoiceAddress: null,
      invoicePostalCode: null,
      invoiceCity: null,
      invoiceState: null,
      invoiceCountry: null,
      invoicePayOrder: null,
      invoiceIban: null,
      invoiceFee: DEFAULT_INVOICING_FEE,
      invoiceResource: DEFAULT_INVOICING_COST_PER_RESOURCE,
      invoiceAccessCheck: null,
      invoiceApiCall: null,
      invoiceTaskOfflineAccessControl: null,
      invoiceDocumentValidation: null,
      invoiceManageContractor: null,
      invoiceManageClient: null,
      invoiceServices: false,
      invoiceFeeServices: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    let companiesRepository = new CompaniesRepository();
    let prescribersRepository = new PrescribersRepository();

    this.loadCompanyTypes();
    companiesRepository.getCompaniesNames().then(data => {
      this.setState({ companies: data.records });
    });

    prescribersRepository
      .getPrescribers()
      .then(data => {
        this.setState({ prescribers: data.records });
      })
      .catch(message => {
        Toast.error(message);
      });
  }

  loadCompanyTypes() {
    this.setState({ isLoading: true });
    new CompaniesRepository().getCompanyTypes()
      .then(data => {
        this.setState({ companyTypes: data.records });
      })
      .catch(message => {
        Toast.error(message);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  openWindow() {
    this.setState({
      companyId: null,
      prescriberId: null,
      name: null,
      cif: null,
      bia360: false,
      trial: false,
      responsableCAE: null,
      responsablesCAE: [""],
      address: null,
      contact: null,
      phone: null,
      companyType: DEFAULT_COMPANY_TYPE,
      defaultLanguage: INITIAL_DEFAULT_LANGUAGE,
      invoiceClient: null,
      invoiceCif: null,
      invoiceEmail: null,
      invoiceAddress: null,
      invoicePostalCode: null,
      invoiceCity: null,
      invoiceState: null,
      invoiceCountry: null,
      invoicePayOrder: null,
      invoiceIban: null,
      invoiceFee: DEFAULT_INVOICING_FEE,
      invoiceResource: DEFAULT_INVOICING_COST_PER_RESOURCE,
      invoiceAccessCheck: null,
      invoiceApiCall: null,
      invoiceTaskOfflineAccessControl: null,
      invoiceDocumentValidation: null,
      invoiceManageContractor: null,
      invoiceManageClient: null,
      invoiceServices: null,
      invoiceFeeServices: null,
    });

    this.window.open();
  }

  createCompany() {
    if (
      !this.isNullOrEmpty(this.state.name) &&
      !this.isNullOrEmpty(this.state.cif)
    ) {
      this.form.onSubmit();
    } else {
      Toast.error(this.i18n.companies["ObligatoryInfo"]);
    }
  }

  onCreatedCompany() {
    this.window.close();
    Toast.success(this.i18n.companies["Created"]);

    this.componentDidMount();
  }

  updateCompany() {
    if (
      !this.isNullOrEmpty(this.state.companyId) &&
      !this.isNullOrEmpty(this.state.name) &&
      !this.isNullOrEmpty(this.state.cif)
    ) {
      this.editform.onSubmit();
    } else {
      Toast.error(this.i18n.companies["ObligatoryInfo"]);
    }
  }

  onUpdatedCompany() {
    this.edit.close();
    Toast.success("Empresa actualizada correctamente");

    this.componentDidMount();
  }

  onDeleteCompany() {
    this.componentDidMount();
  }

  onEditCompany(data) {
    // Create a string array of rtesponsablesCAE using only mail property
    let responsablesCAEMailArray = data.responsablesCAE.map(responsable => responsable.mail);

    this.setState({
      companyId: data.id,
      name: data.name,
      cif: data.cif,
      bia360: data.bia360,
      trial: data.trial,
      responsableCAE: data.responsableCAE,
      responsablesCAE: responsablesCAEMailArray,
      address: data.address,
      contact: data.contact,
      phone: data.phone,
      companyType: data.type ? data.type : DEFAULT_COMPANY_TYPE,
      prescriberId: data.prescriber ? data.prescriber.id : null,
      defaultLanguage: data.defaultLanguage || INITIAL_DEFAULT_LANGUAGE,
      invoiceClient: data.invoiceClient,
      invoiceCif: data.invoiceCif,
      invoiceEmail: data.invoiceEmail,
      invoiceAddress: data.invoiceAddress,
      invoicePostalCode: data.invoicePostalCode,
      invoiceCity: data.invoiceCity,
      invoiceState: data.invoiceState,
      invoiceCountry: data.invoiceCountry,
      invoicePayOrder: data.invoicePayOrder,
      invoiceIban: data.invoiceIban,
      invoiceFee: data.invoiceFee,
      invoiceResource: data.invoiceResource,
      invoiceAccessCheck: data.invoiceAccessCheck,
      invoiceApiCall: data.invoiceApiCall,
      invoiceTaskOfflineAccessControl: data.invoiceTaskOfflineAccessControl,
      invoiceDocumentValidation: data.invoiceDocumentValidation,
      invoiceManageContractor: data.invoiceManageContractor,
      invoiceManageClient: data.invoiceManageClient,
      invoiceServices: data.invoiceServices,
      invoiceFeeServices: data.invoiceFeeServices,
    });
    this.edit.open();
  }

  nameChange(event) {
    this.setState({ name: event.target.value });
  }

  cifChange(event) {
    this.setState({ cif: event.target.value.replace(/\s/g, "") });
  }

  responsableCAEChange(event) {
    this.setState({ responsableCAE: event.target.value });
  }

  addressChange(event) {
    this.setState({ address: event.target.value });
  }

  contactChange(event) {
    this.setState({ contact: event.target.value });
  }

  phoneChange(event) {
    this.setState({ phone: event.target.value });
  }

  bia360Change(event) {
    this.setState({ bia360: event.target.checked });
  }

  trialChange(event) {
    this.setState({ trial: event.target.checked });
  }

  invoiceFeeChange(event) {
    this.setState({ invoiceFee: event.target.value });
  }

  invoiceResourceChange(event) {
    this.setState({ invoiceResource: event.target.value });
  }

  companyTypeChange(event) {
    this.setState({ companyType: event.target.value });
  }

  invoiceClientChange(event) {
    this.setState({ invoiceClient: event.target.value });
  }

  invoiceCifChange(event) {
    this.setState({ invoiceCif: event.target.value });
  }

  invoiceEmailChange(event) {
    this.setState({ invoiceEmail: event.target.value });
  }

  invoiceAddressChange(event) {
    this.setState({ invoiceAddress: event.target.value });
  }

  invoicePostalCodeChange(event) {
    this.setState({ invoicePostalCode: event.target.value });
  }

  invoiceCityChange(event) {
    this.setState({ invoiceCity: event.target.value });
  }

  invoiceStateChange(event) {
    this.setState({ invoiceState: event.target.value });
  }

  invoiceCountryChange(event) {
    this.setState({ invoiceCountry: event.target.value });
  }

  invoicePayOrderChange(event) {
    this.setState({ invoicePayOrder: event.target.value });
  }

  invoiceIbanChange(event) {
    this.setState({ invoiceIban: event.target.value });
  }

  invoiceAccessCheckChange(event) {
    this.setState({ invoiceAccessCheck: event.target.value });
  }

  invoiceApiCallChange(event) {
    this.setState({ invoiceApiCall: event.target.value });
  }

  invoiceTaskOfflineAccessControlChange(event) {
    this.setState({ invoiceTaskOfflineAccessControl: event.target.value });
  }

  invoiceDocumentValidationChange(event) {
    this.setState({ invoiceDocumentValidation: event.target.value });
  }

  invoiceManageContractorChange(event) {
    this.setState({ invoiceManageContractor: event.target.value });
  }

  invoiceManageClientChange(event) {
    this.setState({ invoiceManageClient: event.target.value });
  }

  invoiceServicesChange(event) {
    this.setState({ invoiceServices: event.target.checked });
  }

  invoiceFeeServicesChange(event) {
    this.setState({ invoiceFeeServices: event.target.value });
  }

  defaultLanguageChange(event) {
    this.setState({ defaultLanguage: event.target.value });
  }

  prescriberChange(event) {
    this.setState({ prescriberId: event.target.value });
  }

  getPrescriberOption(prescriber) {
    return (
      <option key={prescriber.id} value={prescriber.id}>
        {prescriber.name}
      </option>
    );
  }

  search(searchText) {
    if (this.state.companies) {
      this.state.companies.forEach(company => {
        if (
          searchText === undefined ||
          searchText.trim() === "" ||
          this.fieldContainsText(company.cif, searchText) ||
          this.fieldContainsText(company.name, searchText)
        ) {
          company.visible = true;
        } else {
          company.visible = false;
        }
      });
      this.setState({ companies: this.state.companies });
    }
  }

  onSearchBoxChange = filterText => {
    this.setState({ filterText });
  };

  renderCompanyTag(company) {
    return company.pi ? (
      <Tag type="INCORRECT" showZero="true" label={this.i18n.tag["client"]} />
    ) : (
      ""
    );
  }

  addResponsableCAE = (value = "") => {
    this.setState(prevState => ({
      responsablesCAE: [...prevState.responsablesCAE, value],
    }));
  };

  updateResponsableCAE = (index, value) => {
    this.setState(prevState => ({
      responsablesCAE: prevState.responsablesCAE.map((e, i) =>
        i === index ? value : e
      ),
    }));
  };

  removeResponsableCAE = index => {
    this.setState(prevState => ({
      responsablesCAE: prevState.responsablesCAE.filter((e, i) => i !== index),
    }));
  };

  render() {
    // generate the list of responsablesCAE using only the value of the mail property
    let { responsablesCAE } = this.state;

    let uploadUrl = process.env.NODE_ENV === "production" ? "https://api-admin.bia360cae.com/api/upload/company" : "http://localhost:9081/api/upload/company";

    let labelSize = "12rem";
    let realCif = this.state.cif;

    if (realCif) {
      realCif = realCif.split("_")[0];
    }

    let noCompanies = (
      <select>
        <option>{this.i18n.companies["NoAvailable"]}</option>
      </select>
    );

    let companiesSelect = (
      <select
        name="prescriberId"
        value={this.state.prescriberId ? this.state.prescriberId : ""}
        onChange={this.prescriberChange.bind(this)}
      >
        <option value="">No selecccionado</option>
        {this.state.prescribers.map(d => {
          return this.getPrescriberOption(d);
        })}
      </select>
    );

    let companyForm = (
      <form>
        <div className="field required">
          <label style={{ width: labelSize }}>{this.i18n.label["Name"]}</label>
          <input
            type="text"
            name="name"
            onChange={this.nameChange.bind(this)}
            value={this.state.name}
          />
        </div>
        <div className="field required">
          <label style={{ width: labelSize }}>{this.i18n.label["CIF"]}</label>
          <input
            type="text"
            name="cif"
            onChange={this.cifChange.bind(this)}
            value={realCif}
          />
        </div>
        <MultiInput
          name="responsablesCAE"
          label={this.i18n.label["ResponsableCAE"]}
          size={labelSize}
          elements={responsablesCAE}
          addElement={this.addResponsableCAE}
          updateElement={this.updateResponsableCAE}
          removeElement={this.removeResponsableCAE}
        />
        <div className="field">
          <label style={{ width: labelSize }}>
            {this.i18n.label["Address"]}
          </label>
          <input
            type="text"
            name="address"
            onChange={this.addressChange.bind(this)}
            value={this.state.address}
          />
        </div>
        <div className="field">
          <label style={{ width: labelSize }}>
            {this.i18n.label["Contact"]}
          </label>
          <input
            type="text"
            name="contact"
            onChange={this.contactChange.bind(this)}
            value={this.state.contact}
          />
        </div>
        <div className="field">
          <label style={{ width: labelSize }}>{this.i18n.label["Phone"]}</label>
          <input
            type="text"
            name="phone"
            onChange={this.phoneChange.bind(this)}
            value={this.state.phone}
          />
        </div>
        <div className="field">
          <label style={{ width: labelSize }}>
            {this.i18n.label["Bia360"]}
          </label>
          <input
            type="checkbox"
            name="bia360"
            onChange={this.bia360Change.bind(this)}
            checked={this.state.bia360}
          />
        </div>
        <div className="field">
          <label style={{ width: labelSize }}>Período de prueba:</label>
          <input
            type="checkbox"
            name="trial"
            onChange={this.trialChange.bind(this)}
            checked={this.state.trial}
          />
        </div>
        <div className="field">
          <label style={{ width: labelSize }}>Tipo:</label>
          <select
            name="companyType"
            onChange={this.companyTypeChange.bind(this)}
            value={this.state.companyType}
          >
            {this.state.companyTypes.map(type => {
              return (
                <option key={type.key} value={type.key}>
                  {type.description}
                </option>
              );
            })}
          </select>
        </div>
        <div className="field">
          <label style={{ width: labelSize }}>Idioma:</label>
          <select
            name="defaultLanguage"
            onChange={this.defaultLanguageChange.bind(this)}
            value={this.state.defaultLanguage}
          >
            {Object.keys(languages).map(language => {
              return (
                <option key={language} value={language}>
                  {languages[language]}
                </option>
              );
            })}
          </select>
        </div>
        <div className="field">
          <FormLabel label="Prescriptor;" size={labelSize} required="false" />
          {this.state.prescribers.length === 0 ? noCompanies : companiesSelect}
        </div>
      </form>
    );

    let companyInvoicingDetail = (
      <form>
        <div className="field">
          <label style={{ width: labelSize, fontWeight: "bold" }}>
            Información de facturación:
          </label>
        </div>
        <div
          style={{
            height: "150px",
            overflow: "auto",
            clear: "both",
            border: "1px solid #aaa",
          }}
        >
          <div className="field">
            <label style={{ width: labelSize }}>Razón social:</label>
            <input
              type="text"
              name="invoiceClient"
              onChange={this.invoiceClientChange.bind(this)}
              value={this.state.invoiceClient}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>CIF:</label>
            <input
              type="text"
              name="invoiceCif"
              onChange={this.invoiceCifChange.bind(this)}
              value={this.state.invoiceCif}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Correo:</label>
            <input
              type="text"
              name="invoiceEmail"
              onChange={this.invoiceEmailChange.bind(this)}
              value={this.state.invoiceEmail}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Dirección:</label>
            <input
              type="text"
              name="invoiceAddress"
              onChange={this.invoiceAddressChange.bind(this)}
              value={this.state.invoiceAddress}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Código postal:</label>
            <input
              type="text"
              name="invoicePostalCode"
              onChange={this.invoicePostalCodeChange.bind(this)}
              value={this.state.invoicePostalCode}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Ciudad:</label>
            <input
              type="text"
              name="invoiceCity"
              onChange={this.invoiceCityChange.bind(this)}
              value={this.state.invoiceCity}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Provincia:</label>
            <input
              type="text"
              name="invoiceState"
              onChange={this.invoiceStateChange.bind(this)}
              value={this.state.invoiceState}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Pais:</label>
            <input
              type="text"
              name="invoiceCountry"
              onChange={this.invoiceCountryChange.bind(this)}
              value={this.state.invoiceCountry}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Orden de pago:</label>
            <input
              type="text"
              name="invoicePayOrder"
              onChange={this.invoicePayOrderChange.bind(this)}
              value={this.state.invoicePayOrder}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>IBAN:</label>
            <input
              type="text"
              name="invoiceIban"
              onChange={this.invoiceIbanChange.bind(this)}
              value={this.state.invoiceIban}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Cuota fija:</label>
            <input
              type="text"
              name="invoiceFee"
              onChange={this.invoiceFeeChange.bind(this)}
              value={this.state.invoiceFee}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Coste por recurso:</label>
            <input
              type="text"
              name="invoiceResource"
              onChange={this.invoiceResourceChange.bind(this)}
              value={this.state.invoiceResource}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Coste control acceso:</label>
            <input
              type="text"
              name="invoiceAccessCheck"
              onChange={this.invoiceAccessCheckChange.bind(this)}
              value={this.state.invoiceAccessCheck}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Coste llamada API:</label>
            <input
              type="text"
              name="invoiceApiCall"
              onChange={this.invoiceApiCallChange.bind(this)}
              value={this.state.invoiceApiCall}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Coste excel offline:</label>
            <input
              type="text"
              name="invoiceTaskOfflineAccessControl"
              onChange={this.invoiceTaskOfflineAccessControlChange.bind(this)}
              value={this.state.invoiceTaskOfflineAccessControl}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Coste validación:</label>
            <input
              type="text"
              name="invoiceDocumentValidation"
              onChange={this.invoiceDocumentValidationChange.bind(this)}
              value={this.state.invoiceDocumentValidation}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Contrata servicios:</label>
            <input
              type="checkbox"
              name="invoiceServices"
              onChange={this.invoiceServicesChange.bind(this)}
              checked={this.state.invoiceServices}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Cuota fija servicios:</label>
            <input
              type="text"
              name="invoiceFeeServices"
              onChange={this.invoiceFeeServicesChange.bind(this)}
              value={this.state.invoiceFeeServices}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Coste por contrata:</label>
            <input
              type="text"
              name="invoiceManageContractor"
              onChange={this.invoiceManageContractorChange.bind(this)}
              value={this.state.invoiceManageContractor}
            />
          </div>
          <div className="field">
            <label style={{ width: labelSize }}>Coste por cliente:</label>
            <input
              type="text"
              name="invoiceManageClient"
              onChange={this.invoiceManageClientChange.bind(this)}
              value={this.state.invoiceManageClient}
            />
          </div>
        </div>
      </form>
    );

    return (
      <div className="work-panel">
        <div className="work-panel-header">
          <h1>
            {this.props.client
              ? this.i18n.companies["ClientCompanies"]
              : this.i18n.companies["Companies"]}
          </h1>

          <div className="toolbar" style={{ float: "right" }}>
            <SearchBox
              handler={this.search.bind(this)}
              value={this.state.filterText}
              onValueChange={this.onSearchBoxChange}
              placeholder={this.i18n.placeholders["SearchNameCIF"]}
            />
          </div>
        </div>
        <div className="buttons-menu">
          <button
            style={{ float: "right" }}
            className="button-success"
            onClick={this.openWindow.bind(this)}
          >
            <i className="fa fa-plus" />{" "}
            <span>{this.i18n.companies["Add"]}</span>
          </button>
        </div>

        <div>

          {this.state.isLoading ? (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="6"
              fill="var(--surface-ground)"
              animationDuration=".5s"
              color="#ae172b"
              aria-label="Loading"
            />
          ) : (

            this.state.companies
              .filter(company => company.visible !== false)
              .map((company, index) => (
                <Company
                  key={`${index}-${company.cif}`}
                  uniqueKey={`${index}-${company.cif}`}
                  admin={true}
                  data={company}
                  onDelete={this.onDeleteCompany.bind(this)}
                  onEdit={this.onEditCompany.bind(this)}
                  onUpdate={this.componentDidMount.bind(this)}
                  renderTag={this.renderCompanyTag.bind(this, company)}
                />
              ))
          )}
        </div>

        <Window
          ref={window => (this.window = window)}
          title={this.i18n.companies["Add"]}
          acceptButtonText={this.i18n.buttons["Create"]}
          onAccept={this.createCompany.bind(this)}
        >
          {companyForm}

          <FileUploadProgress
            ref={form => (this.form = form)}
            key={this.state.companyId}
            data={this.state}
            showButtonSubmit={false}
            onFinish={this.onCreatedCompany.bind(this)}
            required="false"
            showNotification="false"
            size={labelSize}
            url={uploadUrl}
            extensions={["jpg", "png"]}
            label={this.i18n.label["Logo"]}
          />

          {companyInvoicingDetail}
        </Window>

        <Window
          ref={edit => (this.edit = edit)}
          title={this.i18n.companies["Edit"]}
          acceptButtonText={this.i18n.buttons["Save"]}
          onAccept={this.updateCompany.bind(this)}
        >
          {companyForm}

          <FileUploadProgress
            ref={editform => (this.editform = editform)}
            key={this.state.companyId}
            data={this.state}
            showButtonSubmit={false}
            onFinish={this.onUpdatedCompany.bind(this)}
            required="false"
            showNotification="false"
            size={labelSize}
            url={uploadUrl}
            extensions={["jpg", "png"]}
            label={this.i18n.label["Logo"]}
          />

          {companyInvoicingDetail}
        </Window>
      </div>
    );
  }
}
