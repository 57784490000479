import React from "react";
import BiaComponent from "./BiaComponent";
import ReactDOM from "react-dom";
import { EventEmitter } from "events";
import objectAssign from "object-assign";
import Toast from "./Toast";
import FormLabel from "./FormLabel";
import DateDropper from "./DateDropper";
import biaI18n from "../i18n/biaI18n";

const url = "https://app.bia360cae.com/api/upload";

const styles = {
  progressWrapper: {
    height: "10px",
    marginTop: "10px",
    width: "400px",
    float: "left",
    overflow: "hidden",
    backgroundColor: "#f5f5f5",
    borderRadius: "4px",
    WebkitBoxShadow: "inset 0 1px 2px rgba(0,0,0,.1)",
    boxShadow: "inset 0 1px 2px rgba(0,0,0,.1)",
  },
  progressBar: {
    float: "left",
    width: "0",
    height: "100%",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#fff",
    textAlign: "center",
    backgroundColor: "#337ab7",
    WebkitBoxShadow: "inset 0 -1px 0 rgba(0,0,0,.15)",
    boxShadow: "inset 0 -1px 0 rgba(0,0,0,.15)",
    WebkitTransition: "width .6s ease",
    Otransition: "width .6s ease",
    transition: "width .6s ease",
  },
  submitButton: {
    padding: "0.5rem",
    margin: "0.1rem",
    backgroundColor: "rgb(41,127,184)",
    borderRadius: "5px",
    textShadow: "0px 1px 0px rgba(0,0,0,0.5)",
    boxShadow: "0px 2px 2px rgba(0,0,0,0.2)",
    color: "white",
  },
  cancelButton: {
    marginTop: "5px",
    WebkitAppearance: "none",
    padding: 0,
    cursor: "pointer",
    background: "0 0",
    border: 0,
    float: "left",
    fontSize: "21px",
    fontWeight: 700,
    lineHeight: 1,
    color: "#000",
    textShadow: "0 1px 0 #fff",
    filter: "alpha(opacity=20)",
    opacity: ".2",
  },
};

const PasswordField = ({ visible }) => {
  if (!visible) return null;
  return (
    <div className="field">
      <FormLabel
        label={biaI18n.label["Password"]}
        for="password"
        style={{ fontWeight: "bold", width: "10rem" }}
      />
      <input
        type="password"
        name="password"
        placeholder={biaI18n.texts.passwordPlaceHolder}
        style={{ width: "16rem" }}
      />
    </div>
  );
};

class FileUploadProgress extends BiaComponent {
  constructor(props) {
    super(props);
    this.proxy = new EventEmitter();
    this.issuedOnFinish = false;
    this.state = {
      progress: -1,
      hasError: false,
      inputs: [],
    };
  }

  finish(request) {
    if (this.props.onFinish && this.issuedOnFinish) {
      setTimeout(() => {
        this.props.onFinish(request.response);
      }, 1000);
    }
  }

  onProgress(e, request, progress) {
    if (progress >= 100 && !this.issuedOnFinish) {
      this.issuedOnFinish = true;
    }
  }

  onLoad(e, request) {
    if (this.props.showNotification !== "false") {
      Toast.success(this.i18n.documents["Saved"]);
    }

    this.finish(request);
  }

  onError(e, request, errorKey) {
    if (this.props.showNotification !== "false") {
      let errorMessage = this.i18n.documents["ErrorSaved"];

      if (errorKey) {
        errorMessage = this.i18n.documents[errorKey];
      } else {
        let responseData = JSON.parse(request.responseText);

        if (responseData && responseData.message) {
          errorMessage = responseData.message;
        }
      }

      Toast.error(errorMessage, 8000);
    } else if (this.props.onError) {
      let responseData = JSON.parse(request.responseText);

      if (responseData && responseData.message) {
        this.props.onError(responseData.message);
      }
    }

    if (!this.issuedOnFinish) {
      this.issuedOnFinish = true;
      this.finish(request);
    }
  }

  onAbort(e, request) {}

  cancelUpload() {
    this.proxy.emit("abort");
    this.setState({
      progress: -1,
      hasError: false,
    });
  }

  validate() {
    if (this.props.required !== "false") {
      let files = this.refs.form["file"];
      if (
        files.length === undefined &&
        (files.value === "" || files.value === null)
      ) {
        Toast.error(this.i18n.documents["FileNotFound"]);
        return false;
      } else {
        for (let i = 0; i < files.length; i++) {
          var fileValue = files[i].value;
          if (fileValue === null || fileValue === "") {
            Toast.error(this.i18n.documents["FileNotFound"]);
            return false;
          }
        }
      }
    }

    if (
      this.props.extensions !== null &&
      fileValue &&
      fileValue !== null &&
      fileValue !== ""
    ) {
      let ext = fileValue.split(".").pop();
      if (this.props.extensions.indexOf(ext) < 0) {
        Toast.error(
          this.i18n.documents["ExtensionNotFound"] +
            " (" +
            this.props.extensions +
            ")"
        );
        return false;
      }
    }

    let hasInForceDate = this.props.data["hasInForceDate"];
    if (hasInForceDate && this.props.requiredInForceDate !== "false") {
      let inForceDateValue = this.refs.form["inForceDate"].value;
      if (inForceDateValue === null || inForceDateValue === "") {
        Toast.error(this.i18n.documents["InForceNotFound"]);
        return false;
      }
    }

    let hasEndDate = this.props.data["hasEndDate"];
    if (hasEndDate) {
      let endDateValue = this.refs.form["endDate"].value;
      if (endDateValue === null || endDateValue === "") {
        Toast.error(this.i18n.documents["EndDateNotFound"]);
        return false;
      }
    }

    let valid = true;
    let data = this.props.data;
    Object.keys(data).forEach(key => {
      if (
        data[key] &&
        data[key] !== null &&
        data[key].required !== undefined &&
        data[key].required === "true"
      ) {
        let value = this.refs.form[key].value;
        if (value === null || value === "") {
          valid = false;
        }
      }
    });

    if (!valid) {
      Toast.error(this.i18n.documents["RequiredFields"]);
      return false;
    } else {
      return true;
    }
  }

  onSubmit(e) {
    if (e) e.preventDefault();

    if (this.refs.endDate !== undefined) {
      this.refs.endDateHidden.value = this.refs.endDate.getSelectedValue();
    }

    if (this.refs.inForceDate !== undefined) {
      this.refs.inForceDateHidden.value = this.refs.inForceDate.getSelectedValue();
    }

    if (this.validate()) {
      this.setState(
        {
          progress: 0,
          hasError: false,
        },
        this._doUpload
      );
    }
  }

  render() {
    let formElement = this.props.formRenderer(
      this,
      this.props.data,
      this.onSubmit.bind(this),
      this.props.requiredInForceDate,
      this.props.showButtonSubmit
    );
    let progessElement = this.props.progressRenderer(
      this.state.progress,
      this.state.hasError,
      this.cancelUpload.bind(this)
    );

    return (
      <div>
        {formElement}
        {progessElement}
      </div>
    );
  }

  _getFormData() {
    if (this.props.formGetter) {
      return this.props.formGetter();
    }
    return new FormData(ReactDOM.findDOMNode(this.refs.form));
  }

  _doUpload() {
    let form = this._getFormData();
    let req = new XMLHttpRequest();
    req.open("POST", this.props.url != null ? this.props.url : url);
    
    // Configurar withCredentials para enviar cookies
    req.withCredentials = true;

    let me = this;

    req.addEventListener(
      "load",
      e => {
        me.proxy.removeAllListeners(["abort"]);
        let newState = { progress: 100 };

        if (req.status !== 200) {
          newState.hasError = true;

          let defaultErrorKey;

          if (req.status === 413) {
            defaultErrorKey = "MaxSizeDocument";
          }

          me.onError(e, req, defaultErrorKey);
        } else {
          me.onLoad(e, req);
        }
      },
      false
    );

    req.addEventListener(
      "error",
      e => {
        this.setState(
          {
            hasError: true,
          },
          () => {
            this.onError(e, req);
          }
        );
      },
      false
    );

    req.upload.addEventListener(
      "progress",
      e => {
        let progress = 0;
        if (e.total !== 0) {
          progress = parseInt((e.loaded / e.total) * 100);
        }
        this.setState(
          {
            progress: progress,
          },
          () => {
            this.onProgress(e, req, progress);
          }
        );
      },
      false
    );

    req.addEventListener(
      "abort",
      e => {
        this.setState(
          {
            progress: -1,
          },
          () => {
            this.onAbort(e, req);
          }
        );
      },
      false
    );

    this.proxy.once("abort", () => {
      req.abort();
    });

    req.send(form);
  }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  }

  addInput(e) {
    e.preventDefault();
    let inputs = this.state.inputs.slice();
    inputs.push(this.guid());

    this.setState({ inputs: inputs });
  }

  removeInput(uuid, e) {
    e.preventDefault();
    let inputs = this.state.inputs.slice();
    let index = inputs.indexOf(uuid);
    if (index >= 0) {
      inputs.splice(index, 1);
      this.setState({ inputs: inputs });
    }
  }
}

FileUploadProgress.defaultProps = {
  formRenderer: (
    self,
    data,
    onSubmit,
    requiredInForceDate,
    showSubmitButton = true
  ) => {
    let buttonSubmit = "";
    let defaultWidth = self.props.width ? self.props.width : "10rem";

    if (showSubmitButton) {
      buttonSubmit = (
        <button className="button-success" type="submit">
          <i className="fa fa-arrow-up" />{" "}
          <span>{self.i18n.documents["Upload"]}</span>
        </button>
      );
    }

    let inForceDate = "";
    if (data["hasInForceDate"]) {
      inForceDate = (
        <div className="field">
          <DateDropper
            ref="inForceDate"
            id="inForceDate"
            label={self.i18n.label["InForceDate"]}
            required={requiredInForceDate}
          />
          <input ref="inForceDateHidden" type="hidden" name="inForceDate" />
        </div>
      );
    }

    let endDate = "";
    if (data["hasEndDate"]) {
      endDate = (
        <div className="field">
          <DateDropper
            ref="endDate"
            id="endDate"
            label={self.i18n.label["EndDate"]}
            required="true"
          />
          <input ref="endDateHidden" type="hidden" name="endDate" />
        </div>
      );
    }

    let formFields = [];
    Object.keys(data).forEach(key => {
      if (data[key] !== null && data[key] && data[key].type !== undefined) {
        formFields.push(
          <div className="field">
            <FormLabel
              label={self.i18n.label[key]}
              size={data[key].width ? data[key].width : defaultWidth}
              required={data[key].required}
              for={key}
            />
            <input
              type={data[key].type}
              min={data[key].min}
              key={key}
              name={key}
            />
            <span>{self.i18n.label[key + "Post"]}</span>
          </div>
        );
      } else {
        formFields.push(
          <input type="hidden" key={key} name={key} value={data[key]} />
        );
      }
    });

    let multipleRecipients = "";

    if (self.props.multipleRecipients === "true") {
      multipleRecipients = (
        <div className="field">
          <FormLabel
            label={self.i18n.label["Destiny"]}
            size={defaultWidth}
            required="true"
          />
          <input
            type="checkbox"
            id="multipleRecipients"
            key="multipleRecipients"
            name="multipleRecipients"
            style={{ marginRight: "0.3rem" }}
          />
          <span>{self.i18n.label["MultipleRecipients"]}</span>
        </div>
      );
    }

    let inputFile = (
      <div>
        <div className="field">
          <FormLabel
            label={
              self.props.label ? self.props.label : self.i18n.label["File"]
            }
            size={self.props.size ? self.props.size : defaultWidth}
            required={
              self.props.required !== "false" ? "true" : self.props.required
            }
            for="inForceDate"
          />
          <input type="file" name="file" />
        </div>
        <PasswordField visible={self.props.passwordSupported} />
      </div>
    );

    if (self.props.multiple === "true") {
      inputFile = (
        <div>
          <div className="field">
            <FormLabel
              label={
                self.props.label ? self.props.label : self.i18n.label["File"]
              }
              size={self.props.size ? self.props.size : defaultWidth}
              required={
                self.props.required !== "false" ? "true" : self.props.required
              }
              for="inForceDate"
            />
            <input type="file" name="file" />
            <button
              className="button-success"
              onClick={self.addInput.bind(self)}
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>
      );
    }

    return (
      <form
        className="_react_fileupload_form_content"
        ref="form"
        method="post"
        onSubmit={onSubmit}
      >
        {formFields.map(item => item)}
        {inForceDate}
        {endDate}
        {inputFile}
        {self.state.inputs.map(i => (
          <div key={i} className="field">
            <FormLabel
              label={
                self.props.label ? self.props.label : self.i18n.label["File"]
              }
              size={self.props.size ? self.props.size : defaultWidth}
              required={
                self.props.required !== "false" ? "true" : self.props.required
              }
              for="inForceDate"
            />
            <input key={i} type="file" name="file" />
            <button
              className="button-error"
              onClick={self.removeInput.bind(self, i)}
            >
              <i className="fa fa-minus"></i>
            </button>
          </div>
        ))}
        {multipleRecipients}
        {buttonSubmit}
      </form>
    );
  },
  progressRenderer: (progress, hasError, cancelHandler) => {
    if (hasError || progress > -1) {
      let barStyle = objectAssign({}, styles.progressBar);
      barStyle.width = progress + "%";
      barStyle.backgroundColor = hasError
        ? "#d9534f"
        : styles.progressBar.backgroundColor;

      return (
        <div className="_react_fileupload_progress_content">
          <div style={styles.progressWrapper}>
            <div
              className="_react_fileupload_progress_bar"
              style={barStyle}
            ></div>
          </div>
          <button
            className="_react_fileupload_progress_cancel"
            style={styles.cancelButton}
            onClick={cancelHandler}
          >
            <span>&times;</span>
          </button>
          <div style={{ clear: "left" }} />
        </div>
      );
    }
  },
  passwordSupported: false,
  requiredInForceDate: true,
};

export default FileUploadProgress;
