import React from "react";
import generator from "generate-password-browser";

import BiaComponent from "../../commons/components/BiaComponent";
import SearchBox from "../../commons/components/SearchBox";
import Window from "../../commons/components/Window";
import User from "../../commons/components/User";
import UsersRepository from "../../commons/repositories/UsersRepository";
import CompaniesDropDown from "../commons/CompaniesDropDown";
import Toast from "../../commons/components/Toast";
import Profiles from "../../commons/components/Profiles";

function generatePassword() {
  return generator.generate({
    length: 10,
    numbers: true,
    symbols: true,
    lowercase: true,
    uppercase: true,
    strict: true,
  });
}

export default class UsersPanel extends BiaComponent {
  state = {
    users: [],
    login: null,
    mail: null,
    password: generatePassword(),
    companyCif: null,
    profiles: this.initProfiles(),
    filterText: "",
  };

  initProfiles() {
    let profilesSelection = {};

    Profiles.enumeration().forEach(profile => {
      profilesSelection[profile] = false;
    });

    return profilesSelection;
  }

  openWindow() {
    this.setState({
      userId: null,
      login: null,
      mail: null,
      password: generatePassword(),
      profiles: this.initProfiles(),
    });

    this.refs.window.open();
  }

  isMailValid(mail) {
    var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(mail);
  }

  createUser() {
    if (
      !this.isNullOrEmpty(this.state.login) &&
      !this.isNullOrEmpty(this.state.mail) &&
      !this.isNullOrEmpty(this.state.password) &&
      !this.isNullOrEmpty(this.state.companyCif)
    ) {
      if (this.isMailValid(this.state.mail)) {
        let profiles = Object.keys(this.state.profiles).filter(
          profile => this.state.profiles[profile]
        );

        new UsersRepository()
          .addUser(
            this.state.login,
            this.state.mail,
            this.state.password,
            this.state.companyCif,
            profiles
          )
          .then(() => {
            this.setState({
              login: null,
              mail: null,
              password: null,
              companyCif: null,
              filterText: this.state.mail,
            });

            this.refs.window.close();

            Toast.success(this.i18n.users["Saved"]);

            this.fillOrSearchUsers();
          })
          .catch(e => {
            Toast.error(this.i18n.users["UserCreationError"]);
          });
      } else {
        Toast.error(this.i18n.users["InvalidMail"]);
      }
    } else {
      Toast.error(this.i18n.users["ObligatoryInfo"]);
    }
  }

  updateUser() {
    if (
      !this.isNullOrEmpty(this.state.login) &&
      !this.isNullOrEmpty(this.state.mail) &&
      !this.isNullOrEmpty(this.state.password) &&
      !this.isNullOrEmpty(this.state.userId)
    ) {
      if (this.isMailValid(this.state.mail)) {
        let profiles = Object.keys(this.state.profiles).filter(
          profile => this.state.profiles[profile]
        );

        new UsersRepository()
          .updateUser(
            this.state.userId,
            this.state.login,
            this.state.mail,
            this.state.password,
            profiles
          )
          .then(() => {
            this.setState({
              userId: null,
              login: null,
              mail: null,
              password: null,
              companyCif: null,
              filterText: this.state.mail,
            });

            this.refs.edit.close();
            Toast.success(this.i18n.users["Saved"]);

            this.fillOrSearchUsers();
          });
      } else {
        Toast.error(this.i18n.users["InvalidMail"]);
      }
    } else {
      Toast.error(this.i18n.users["ObligatoryInfo"]);
    }
  }

  onDeleteUser() {
    this.fillOrSearchUsers();
  }

  onEditUser(data) {
    let asignedProfiles = this.initProfiles();

    (data.profiles || []).forEach(profile => {
      asignedProfiles[profile] = true;
    });

    this.setState({
      userId: data.id,
      login: data.login,
      mail: data.mail,
      password: data.password,
      profiles: asignedProfiles,
      filterText: data.mail,
    });

    this.refs.edit.open();
    this.fillOrSearchUsers();
  }

  loginChange(event) {
    this.setState({ login: event.target.value });
  }

  mailChange(event) {
    this.setState({ mail: event.target.value });
  }

  passwordChange(event) {
    this.setState({ password: event.target.value });
  }

  profileChange(event) {
    let profile = event.target.value;

    let newAssignedProfiles = {
      ...this.state.profiles,
      [profile]: !this.state.profiles[profile],
    };
    this.setState({ profiles: newAssignedProfiles });
  }

  selectCompany(companyCif) {
    this.setState({ companyCif: companyCif });
  }

  search(searchText) {
    this.setState({ filterText: searchText }, this.searchUsers.bind(this));
  }

  searchUsers() {
    if (this.state.filterText !== "") {
      new UsersRepository().searchUsers(this.state.filterText).then(data => {
        this.setState({ users: data.records });
      });
    } else {
      this.setState({ users: [] });
    }
  }

  onSearchBoxChange = filterText => {
    this.setState({ filterText });
  };

  fillOrSearchUsers() {
    if (this.state.filterText === "") {
      this.setState({ users: [] });
    } else {
      this.search(this.state.filterText);
    }
  }

  copyPasswordToClipboard = event => {
    this.inputPassword.select();
    document.execCommand("copy");
    Toast.success("Password copiado con éxito");
  };

  regeneratePassword = event => {
    const password = generatePassword();
    this.setState({ password });
  };

  render() {
    let handler = this.selectCompany.bind(this);

    let userFields = (
      <div>
        <div className="field required">
          <label style={{ width: "6rem" }}>{this.i18n.label["Login"]}</label>
          <input
            type="text"
            name="login"
            autoFocus={true}
            onChange={this.loginChange.bind(this)}
            value={this.state.login || ""}
          />
        </div>
        <div className="field required">
          <label style={{ width: "6rem" }}>{this.i18n.label["Mail"]}</label>
          <input
            type="text"
            name="mail"
            onChange={this.mailChange.bind(this)}
            value={this.state.mail || ""}
          />
        </div>
        <div className="field required">
          <label style={{ width: "6rem" }}>{this.i18n.label["Password"]}</label>
          <input
            ref={inputPassword => (this.inputPassword = inputPassword)}
            type="text"
            name="password"
            onChange={this.passwordChange.bind(this)}
            value={this.state.password || ""}
          />
          {document.queryCommandSupported("copy") && (
            <span>
              <a
                href="/"
                onClick={this.regeneratePassword}
                style={{ marginLeft: "0.3rem" }}
              >
                <i className="fa fa-refresh"></i>
              </a>
              <a
                href="/"
                onClick={this.copyPasswordToClipboard}
                style={{ marginLeft: "0.3rem" }}
              >
                <i className="fa fa-copy"></i>
              </a>
            </span>
          )}
        </div>
      </div>
    );

    let profiles = (
      <div style={{ marginTop: "1rem", paddingLeft: "0.8rem" }}>
        <label
          style={{
            width: "6rem",
            fontWeight: "bold",
          }}
        >
          {this.i18n.label["Profile"]}
        </label>

        <div style={{ paddingLeft: "1.5rem", marginTop: "0.6rem" }}>
          {Object.keys(this.state.profiles).map((profile, index) => {
            return (
              <div key={profile + "_" + index} style={{ marginTop: "0.4rem" }}>
                <input
                  type="checkbox"
                  name="profile"
                  checked={this.state.profiles[profile]}
                  onChange={this.profileChange.bind(this)}
                  value={profile}
                />

                <label style={{ fontWeight: "bold", color: "#777" }}>
                  {" " + profile}
                </label>
                <br />

                <label style={{ marginLeft: "1.0rem" }}>
                  {this.i18n.label[profile + "_Description"]}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );

    return (
      <div className="work-panel">
        <div className="work-panel-header">
          <h1>{this.i18n.users["Users"]}</h1>

          <div className="toolbar" style={{ float: "right" }}>
            <SearchBox
              onValueChange={this.onSearchBoxChange}
              value={this.state.filterText}
              handler={this.search.bind(this)}
              placeholder={this.i18n.placeholders["SearchUserMailCompany"]}
            />
          </div>
        </div>
        <div className="buttons-menu">
          <button
            style={{ float: "right" }}
            className="button-success"
            onClick={this.openWindow.bind(this)}
          >
            <i className="fa fa-plus"></i> <span>{this.i18n.users["Add"]}</span>
          </button>
        </div>

        <div>
          {this.state.users.length === 0 && (
            <span>
              <strong>ATENCIÓN</strong>: Debes realizar una búsqueda para poder
              gestionar los usuarios
            </span>
          )}
          {this.state.users.map(user => {
            return (
              <User
                key={user.id}
                data={user}
                onDelete={this.onDeleteUser.bind(this)}
                onEdit={this.onEditUser.bind(this)}
              />
            );
          })}
        </div>

        <Window
          ref="window"
          title={this.i18n.users["Add"]}
          acceptButtonText={this.i18n.buttons["Create"]}
          onAccept={this.createUser.bind(this)}
        >
          <form>
            {userFields}
            <div className="field required">
              <CompaniesDropDown
                label={this.i18n.label["Company"]}
                size="6rem"
                required="true"
                handler={handler}
              />
            </div>
            {profiles}
          </form>
        </Window>

        <Window
          ref="edit"
          title={this.i18n.users["Edit"]}
          acceptButtonText={this.i18n.buttons["Save"]}
          onAccept={this.updateUser.bind(this)}
        >
          <form>
            {userFields}
            {profiles}
          </form>
        </Window>
      </div>
    );
  }
}
