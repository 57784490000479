import React from "react";
import BiaComponent from "../../commons/components/BiaComponent";
import SearchBox from "../../commons/components/SearchBox";
import Window from "../../commons/components/Window";
import RolesRepository from "../../repositories/RolesRepository";
import RoleDocumentTypesDropDown from "../commons/RoleDocumentTypesDropDown";
import Toast from "../../commons/components/Toast";
import AttributeList from "../../commons/components/AttributeList";

class Role extends BiaComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      name: null,
    };
  }

  openWindow(e) {
    e.preventDefault();
    this.refs.window.open();
  }

  openRoleConfirm(e) {
    e.preventDefault();
    this.refs.confirm.open();
  }

  deleteRole(roleId) {
    new RolesRepository().delete(roleId).then(() => {
      this.refs.confirm.close();
      Toast.success(this.i18n.functions["Deleted"]);
      if (this.props.onDelete) this.props.onDelete();
    });
  }

  onUpdateRole() {
    if (this.props.onUpdate) this.props.onUpdate();
  }

  openEdit(e) {
    if (this.props.onEdit) this.props.onEdit(this.props.data);
  }

  render() {
    return (
      <div>
        <div className="worker">
          <div className="details">
            <div className="identification">
              <AttributeList
                title={this.props.data.name}
                data={this.props.data.documentTypes}
              />
            </div>
          </div>

          <button
            className="button-upload"
            onClick={this.openWindow.bind(this)}
            style={{ float: "right" }}
          >
            <i className="fa fa-building"></i>{" "}
            {this.i18n.functions.documentTypes["DocumentTypes"]}
          </button>

          <button
            className="button-error"
            onClick={this.openRoleConfirm.bind(this)}
            style={{ float: "right" }}
          >
            <i className="fa fa-trash"></i> {this.i18n.buttons["Delete"]}
          </button>

          <button
            className="button-upload"
            onClick={this.openEdit.bind(this)}
            style={{ float: "right" }}
          >
            <i className="fa fa-building"></i> {this.i18n.buttons["Edit"]}
          </button>

          <Window
            ref="window"
            title={this.i18n.contracts.DocumentTypes["DocumentTypes"]}
          >
            <RoleDocumentTypesDropDown
              onUpdate={this.onUpdateRole.bind(this)}
              data={this.props.data}
            />
          </Window>

          <Window
            ref="confirm"
            title={this.i18n.functions["Delete"]}
            acceptButtonText={this.i18n.buttons["Delete"]}
            onAccept={this.deleteRole.bind(this, this.props.data.id)}
          >
            <p>{this.i18n.functions["SureDelete"]}</p>
          </Window>
        </div>
      </div>
    );
  }
}

export default class RolesPanel extends BiaComponent {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      name: null,
      roleId: null,
      filterText: "",
    };
  }

  componentDidMount() {
    let rolesRepository = new RolesRepository();

    rolesRepository.getAllRoles().then(data => {
      this.setState({ roles: data.records });
    });
  }

  openWindow() {
    this.setState({ roleId: null, name: null });

    this.refs.window.open();
  }

  createRole() {
    if (!this.isNullOrEmpty(this.state.name)) {
      new RolesRepository().add(this.state.name).then(() => {
        this.state.name = null;

        this.refs.window.close();
        Toast.success(this.i18n.functions["Saved"]);

        this.componentDidMount();
      });
    } else {
      Toast.error(this.i18n.functions["ObligatoryInfo"]);
    }
  }

  updateRole() {
    if (
      !this.isNullOrEmpty(this.state.name) &&
      !this.isNullOrEmpty(this.state.roleId)
    ) {
      new RolesRepository()
        .update(this.state.roleId, this.state.name)
        .then(() => {
          this.state.roleId = null;
          this.state.name = null;

          this.refs.edit.close();
          Toast.success(this.i18n.functions["Updated"]);

          this.componentDidMount();
        });
    } else {
      Toast.error(this.i18n.functions["ObligatoryInfo"]);
    }
  }

  onEditRole(data) {
    this.setState({ roleId: data.id, name: data.name });

    this.refs.edit.open();
  }

  onDeleteRole() {
    this.componentDidMount();
  }

  onUpdateRole() {
    this.componentDidMount();
  }

  nameChange(event) {
    this.setState({ name: event.target.value });
  }

  search(searchText) {
    if (this.state.roles && this.state.roles.map) {
      this.state.roles.forEach(role => {
        if (
          searchText === undefined ||
          searchText.trim() === "" ||
          this.fieldContainsText(role.name, searchText) ||
          this.mapContainsText(role.documentTypes, "name", searchText)
        ) {
          role.visible = true;
        } else {
          role.visible = false;
        }
      });
      this.setState({ roles: this.state.roles });
    }
  }

  onSearchBoxChange = filterText => {
    this.setState({ filterText });
  };

  render() {
    let roleForm = (
      <form>
        <div className="field required">
          <label style={{ width: "6rem" }}>{this.i18n.label["Name"]}</label>
          <input
            type="text"
            name="name"
            onChange={this.nameChange.bind(this)}
            value={this.state.name}
          />
        </div>
      </form>
    );

    return (
      <div className="work-panel">
        <div className="work-panel-header">
          <h1>{this.i18n.menu.admin["Roles"]}</h1>

          <div className="toolbar" style={{ float: "right" }}>
            <SearchBox
              onValueChange={this.onSearchBoxChange}
              value={this.state.filterText}
              handler={this.search.bind(this)}
              placeholder={this.i18n.placeholders["SearchNameAndDocumentType"]}
            />
          </div>
        </div>
        <div className="buttons-menu">
          <button
            style={{ float: "right" }}
            className="button-success"
            onClick={this.openWindow.bind(this)}
          >
            <i className="fa fa-plus"></i>{" "}
            <span>{this.i18n.functions["Add"]}</span>
          </button>
        </div>

        <div style={{ marginTop: "1rem" }}>
          {this.state.roles
            .filter(role => typeof role.visible === "undefined" || role.visible)
            .map(role => (
              <Role
                key={role.id}
                data={role}
                onDelete={this.onDeleteRole.bind(this)}
                onUpdate={this.onUpdateRole.bind(this)}
                onEdit={this.onEditRole.bind(this)}
              />
            ))}
        </div>

        <Window
          ref="window"
          title={this.i18n.functions["Add"]}
          acceptButtonText={this.i18n.buttons["Create"]}
          onAccept={this.createRole.bind(this)}
        >
          {roleForm}
        </Window>

        <Window
          ref="edit"
          title={this.i18n.functions["Edit"]}
          acceptButtonText={this.i18n.buttons["Save"]}
          onAccept={this.updateRole.bind(this)}
        >
          {roleForm}
        </Window>
      </div>
    );
  }
}
