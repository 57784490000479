import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 3000,
  draggable: false,
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_RIGHT,
  pauseOnHover: true,
});

export default {
  info(message) {
    toast.info(`ℹ️ ${message}`);
  },
  success(message) {
    toast.success(message);
  },
  warning(message) {
    toast.warn(`⚠️ ${message}`);
  },
  error(message, timeOut = 6000) {
    toast.error(`⚠️ ${message}`, {
      autoClose: timeOut,
    });
  },
};
